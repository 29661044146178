import { useState, createContext, useContext } from 'react'

import Team from './components/team'
import ConfirmDelete from './components/confirmDelete'

const initialValues = {
  openTeamModal: () => {},
  openConfirmToDeleteModal: () => {}
}

const ModalsContext = createContext(initialValues)

const ModalsProvider = ({ children }) => {
  const [openedModal, setOpenedModal] = useState('')
  const closeModal = () => setOpenedModal('')

  const [confirmDeleteConfirm, setConfirmDeleteConfirm] = useState(() => {})
  const [confirmDeleteCancel, setConfirmDeleteCancel] = useState(() => {})
  const [confirmDeleteItems, setConfirmDeleteItems] = useState([])
  const [confirmDeleteLabel, setConfirmDeleteLabel] = useState('')
  const [confirmDeleteCustomMessage, setConfirmDeleteCustomMessage] = useState('')
  const [customPostMessage, setCustomPostMessage] = useState(null)

  const [teamTeam, setTeamTeam] = useState()
  const [teamCallback, setTeamCallback] = useState(() => {})
  const [teamCallbackDelete, setTeamCallbackDelete] = useState(() => {})

  const openConfirmToDeleteModal = ({ label, items, confirm: callConfirm, cancel: callCancel, customMessage = null, customPostMessage = null }) => {
    setConfirmDeleteLabel(label ?? '')
    setConfirmDeleteItems(items ?? [])
    setConfirmDeleteConfirm(() => callConfirm)
    setConfirmDeleteCustomMessage(customMessage ?? '')
    setCustomPostMessage(customPostMessage)
    if (callCancel) { setConfirmDeleteCancel(() => callCancel) }
    setOpenedModal('confirmDelete')
  }

  const openTeamModal = ({ team, callback, callbackDelete }) => {
    setTeamTeam(team ?? '')
    setTeamCallback(callback ? () => callback : () => {})
    setTeamCallbackDelete(callbackDelete ? () => callbackDelete : () => {})
    setOpenedModal('team')
  }

  return (
    <ModalsContext.Provider value={{
      openTeamModal,
      openConfirmToDeleteModal,
      setConfirmDeleteConfirm,
      setCustomPostMessage
    }}>
      {children}
      <Team open={openedModal === 'team'} onClose={closeModal} team={teamTeam} callback={teamCallback} callbackDelete={teamCallbackDelete} />
      <ConfirmDelete
        open={openedModal === 'confirmDelete'}
        onClose={closeModal}
        confirm={confirmDeleteConfirm}
        cancel={confirmDeleteCancel || closeModal}
        items={confirmDeleteItems}
        label={confirmDeleteLabel}
        customMessage={confirmDeleteCustomMessage}
        customPostMessage={customPostMessage}
      />
    </ModalsContext.Provider>
  )
}

const useModals = () => {
  return useContext(ModalsContext)
}

export { ModalsProvider, useModals }
