import { /*, useRef, useEffect, */memo, useEffect, useMemo, useState } from 'react'

import moment from 'moment'

import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import frLocale from '@fullcalendar/core/locales/fr'
import interaction from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import { Button, Box, Text, Avatar, HStack, Checkbox, CheckboxGroup, Tooltip } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { MdDragIndicator } from 'react-icons/md'
import { RiTeamLine } from 'react-icons/ri'

import { useModals, useDatas } from 'contexts'
import { useFilters } from '../../contexts/filters'

import ReactGA from 'react-ga4'

const SlotLabelContent = ({ arg }) => {
  const {
    filters: {
      fcDuration,
      endDate
    }
  } = useFilters()

  const {
    datas: {
      hideWeekEnd
    }
  } = useDatas()

  return <HStack height='70px' justify='center' align='center' flexDirection='column' className={
    ([24].includes(fcDuration.duration.weeks) && (moment(arg.date).month() !== moment(arg.date).add(1, 'week').month())) ||
    ([12].includes(fcDuration.duration.weeks) && (moment(arg.date).month() !== moment(arg.date).add(1, 'day').month())) ||
    (![24, 12].includes(fcDuration.duration.weeks) && (moment(arg.date).isoWeekday() === (hideWeekEnd ? 5 : 7) && !moment(arg.date).isSame(moment(endDate).add(-1, 'day'))))
      ? 'mbborder'
      : ''
  }>
    {[24].includes(fcDuration.duration.weeks) && [3, 7, 11, 15, 19, 24, 28, 33, 37, 42, 46, 50].includes(moment(arg.date).isoWeek())
      ? <Text ml='0!' fontWeight='light' fontSize='12' width='100%' height='24px' align='center' transform={[7, 11, 15, 19, 24, 28, 33, 37, 46, 50].includes(moment(arg.date).isoWeek()) ? 'translateX(75%)' : ''}>{moment(arg.date).format('MMMM')}</Text>
      : [12].includes(fcDuration.duration.weeks) && moment(arg.date).date() === 15
          ? <Text ml='0!' fontWeight='light' fontSize='12' height='24px'>{moment(arg.date).format('MMM')}</Text>
          : [4, 2].includes(fcDuration.duration.weeks) && moment(arg.date).isoWeekday() === 4
              ? <Text ml='0!' fontWeight='light' fontSize='12' height='24px'>S.{moment(arg.date).isoWeek()}</Text>
              : [1].includes(fcDuration.duration.weeks)
                  ? ''
                  : <Text height='24px'/>
    }
    {fcDuration?.columnFormat?.map(f => <Text ml='0!' key={f}>{ moment(arg.date).format(f) }</Text>)}
  </HStack>
}

const ResourceAreaHeaderContent = ({ arg }) => {
  const {
    actions: {
      updateDate
    }
  } = useFilters()

  return <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingTop: '10px' }} px='2'>
    <Button data-variant='outline' onClick={() => updateDate(-1)} style={{ padding: 0, borderRadius: '4px' }}>
      <ChevronLeftIcon size="22" />
    </Button>
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} color='#8186aa'>{ arg.view.title }</Box>
    <Button data-variant='outline' onClick={() => updateDate(1)} style={{ padding: 0, borderRadius: '4px' }}>
      <ChevronRightIcon size="22" />
    </Button>
  </Box>
}

const ResourceGroupLabelContent = ({ arg }) => {
  return <div style={{ display: 'inline-flex', width: '100%', justifyContent: 'space-between', paddingRight: '33px', marginTop: '-1' }} className='tim-resources-group' data-group-item={arg.groupValue}>
    <Text style={{ position: 'relative', top: '-2px' }} fontSize='12px' fontWeight='bold' color='#8186aa'>{ arg.groupValue }</Text>
    <div className="line"></div>
  </div>
}

const ResourceLabelContent = ({ arg, setEmployeeModal }) => {
  return <HStack key={arg.resource?.extendedProps?.employee?.id} px='2' pb='1' mt='-1' onClick={() => setEmployeeModal(arg.resource.extendedProps.employee)} style={{ cursor: 'pointer' }}>
    <Avatar boxSize='8' size='xs' name={arg.fieldValue} src={arg.resource.extendedProps.employee?.picture ? arg.resource.extendedProps.employee.picture.uri : null} />
    <Box>
      <Text mt='0.5' fontSize='13px'>{arg.fieldValue}</Text>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Text mt='-1' fontSize='11px'>{arg.resource?.extendedProps?.employee.employeePosition?.name ?? '-'}</Text>
        <span style={{ width: '8px', height: '8px', background: arg.resource?.extendedProps?.employee?.isAvailable, borderRadius: '50%', marginLeft: '5px' }} />
      </Box>
    </Box>
  </HStack>
}

const EventContent = ({ arg, openTimesheetModal, openAbsenceModal, deleteEvent }) => {
  const {
    // filters: {
    //   startDate,
    //   endDate
    // },
    actions: {
      deleteTimesheet
    }
  } = useFilters()
  const { openConfirmToDeleteModal, setConfirmDeleteConfirm, setCustomPostMessage } = useModals()
  const [removeClockings, setRemoveClockings] = useState(false)

  useEffect(() => {
    setCustomPostMessage(customPostMessage)
    setConfirmDeleteConfirm(() => () => deleteTimesheet({ id: arg.event.id, callback: deleteEvent, isAbsence: Boolean(arg.event?.extendedProps?.absence), removeClockings }))
  }, [removeClockings])

  const customPostMessage = useMemo(() => (
    <Box display='flex' flexDirection="row">
      <CheckboxGroup>
        <Checkbox
          onChange={() => {
            setRemoveClockings(prev => !prev)
          }}
          isChecked={removeClockings}
        />
        <Text ml={2}>Forcer la suppression des pointages associés</Text>
      </CheckboxGroup>
    </Box>
  ), [removeClockings])

  return <Tooltip
    placement="auto"
    label={arg.event.extendedProps?.constructionSite?.name ?? ''}
    color='white'
  >
    {
      arg.event.backgroundColor === 'transparent'
        ? <HStack position='relative' justify='center' align='center' width='100%'>
            <Box className='eventContent'>
              <Box style={{ borderBottom: '1px solid rgba(0,0,0,.12)', width: '100%', position: 'absolute' }} />
              <Button style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '7px', height: '30px', padding: '0px 30px', color: 'black' }}
                onClick={() => {
                  ReactGA.event({ action: 'click', category: 'planningClickAddChantierOnEventLine', label: 'planningClickAddChantierOnEventLine' })
                  openTimesheetModal({
                    name: arg.event.extendedProps?.constructionSite?.name ?? '',
                    // startDate,
                    // endDate,
                    employeeId: arg.event.extendedProps?.employee?.id ?? '',
                    chantierId: arg.event.extendedProps?.constructionSite?.id ?? ''
                  })
                }}>
                  <Text fontWeight='bold' fontSize='15px'>{ arg.event.title }</Text>
                </Button>
            </Box>
          </HStack>
        : <HStack position='relative' justify={moment(arg.view.currentStart).isSameOrBefore(moment(arg.event.start)) || moment(arg.view.currentEnd).isSameOrAfter(moment(arg.event.end)) ? 'space-between' : 'center'} align='center' style={{ borderRadius: '7px' }}>
            {moment(arg.view.currentStart).isSameOrBefore(moment(arg.event.start)) && <MdDragIndicator size="22" color='#fff' />}
            <Box className='eventContent' height="28px" >
              <Box className='actions'>
                <Button bg='#EDF2F7' color='#000' px='4' mr='1' onClick={() => {
                  arg.event.extendedProps.absence
                    ? openAbsenceModal({
                      id: arg.event.id.split('-')[1],
                      absenceId: arg.event?.extendedProps?.absence?.absence?.id,
                      absenceStartDate: arg.event?.extendedProps?.absence?.startFull,
                      absenceEndDate: arg.event?.extendedProps?.absence?.endFull
                    })
                    : openTimesheetModal({
                      id: arg.event.id,
                      name: arg.event.extendedProps?.constructionSite?.name ?? '',
                      startDate: arg.event.start,
                      endDate: arg.event.end,
                      employeeId: arg.event?.extendedProps?.employee?.id,
                      chantierId: arg.event?.extendedProps?.constructionSite?.id,
                      absenceId: arg.event?.extendedProps?.absence?.absence?.id,
                      absenceStartDate: arg.event?.extendedProps?.absence?.startFull,
                      absenceEndDate: arg.event?.extendedProps?.absence?.endFull
                    })
                }}>Modifier</Button>
                <Button bg='#EDF2F7' color='#000' px='4' ml='1' onClick={() => openConfirmToDeleteModal({
                  label: 'Planification ouvriers',
                  items: [{ id: arg.event.id, label: arg.event.title }],
                  confirm: () => deleteTimesheet({ id: arg.event.id, callback: deleteEvent, isAbsence: Boolean(arg.event?.extendedProps?.absence), removeClockings }),
                  customMessage: arg.event?.extendedProps?.absence ? '' : "⚠️ Attention, en supprimant la planification de cet employé, seul la période pour laquelle il n'a pas pointé sera supprimée.",
                  customPostMessage
                })}>Supprimer</Button>
              </Box>
              <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Text className='title' fontWeight='bold' fontSize='15px'>{ arg.event.title }</Text>
                {arg.event.extendedProps.team ? <><RiTeamLine /><Text fontSize='10px'>({arg.event.extendedProps.team?.name})</Text></> : ''}
              </Box>
            </Box>
            {moment(arg.view.currentEnd).isSameOrAfter(moment(arg.event.end)) && <MdDragIndicator size="22" color='#fff' />}
          </HStack>
    }
  </Tooltip>
}

const OuvriersContent = ({ events, filteredResources, deleteEvent, addEvent, updateEvent, openTimesheetModal, openAbsenceModal, setEmployeeModal }) => {
  const {
    filters: {
      fcDuration
    },
    actions: {
      eventResizeChantierSchedule,
      eventDropChantierSchedule,
      eventReceiveChantierSchedule
    },
    refs: {
      calendrierOuvriersRef
    }
  } = useFilters()
  const { datas: { clientSlug, hideWeekEnd } } = useDatas()

  return <FullCalendar
    ref={calendrierOuvriersRef}
    schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
    plugins={[resourceTimelinePlugin, momentPlugin, interaction]}
    locale={frLocale}
    height='800px'
    headerToolbar={false}
    hiddenDays={hideWeekEnd ? [6, 0] : []}
    editable={true}
    selectMirror={true}
    initialView='resourceTimeline'
    resourceGroupField='companyLabel'
    resourceAreaWidth={301}
    eventMinHeight={40}
    resources={filteredResources}
    events={events}
    resourceGroupLabelDidMount={(info) => {
      if (info.groupValue.toLowerCase().replace(' ', '').includes(clientSlug)) {
        info.el.querySelector('.fc-datagrid-expander').click()
      }
    }}
    rerenderDelay={1}
    resourcesInitiallyExpanded={false}
    eventResize={(e) => eventResizeChantierSchedule(e, updateEvent)}
    eventDrop={(e) => eventDropChantierSchedule(e, updateEvent)}
    eventReceive={(e) => eventReceiveChantierSchedule(e, addEvent, events)}
    droppable={true}
    eventBorderColor='transparent'
    views={{
      resourceTimeline: {
        type: 'timeline',
        ...fcDuration
      }
    }}
    /* CURRENT WEEK (RIGHT) - FORMAT DD ddd. */
    slotLabelContent={(arg) => <SlotLabelContent arg={arg} />}
    /* CURRENT WEEK (LEFT) - FORMAT [Semaine] num - YYYY */
    resourceAreaHeaderContent={(arg) => <ResourceAreaHeaderContent arg={arg} />}
    /* GROUPE (NOM DE L'ENTREPRISE OU NOM DU CHANTIER) */
    resourceGroupLabelContent={(arg) => <ResourceGroupLabelContent arg={arg} />}
    /* LISTE EMPLOYEE */
    resourceLabelContent={(arg) => <ResourceLabelContent arg={arg} setEmployeeModal={setEmployeeModal} />}
    /* PLANIFICATION EVENT */
    eventContent={(arg) => <EventContent arg={arg} deleteEvent={deleteEvent} openTimesheetModal={openTimesheetModal} openAbsenceModal={openAbsenceModal} />}
  />
}

function contentPropsAreEqual (prev, next) {
  return prev.events === next.events &&
  prev.filteredResources === next.filteredResources
}

export default memo(OuvriersContent, contentPropsAreEqual)
