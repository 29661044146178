import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Box } from '@chakra-ui/react'

const ConfirmDelete = ({ open, onClose, label, items, confirm, cancel, customMessage, customElement, customPostMessage }) => {
  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Supprimer | {label}</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody px='10'>
        {customMessage?.length ? <Text mb='8'>{customMessage}</Text> : ''}
        {items?.map(item => <Box key={item.id} p='5' mb='6' bg='#f1f1f4' borderRadius='8px'>
          {item.label}
        </Box>)}
        {customPostMessage && <Text mb='8'>{customPostMessage}</Text>}
        {customElement}
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={() => { confirm(); onClose() }}>Supprimer</Button>
        <Button data-variant='outline' onClick={cancel}>Annuler</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default ConfirmDelete
