import { Button, HStack, Icon, Text } from '@chakra-ui/react'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

const NavButton = (props) => {
  const { icon, label, to, ...buttonProps } = props
  return (
    <NavLink
      to={to + ''}
      {...buttonProps}
      style={{
        // borderRadius: '4px',
        // padding: "10px",
        fontWeight: '500',
        fontSize: 'md'
      }}
    >
      <Button variant="ghost" justifyContent="flex-start" _hover={{ backgroundColor: 'none' }} height='3.6rem' _active={{ background: 'none' }} _focusVisible={{ boxShadow: 'none' }}>
        <HStack spacing="3">
          <Icon as={icon} boxSize="6" color="subtle" />
          <Text fontSize='13' fontWeight='600'>{label}</Text>
        </HStack>
      </Button>
    </NavLink>

  )
}

export default NavButton
