import { useEffect, useState } from 'react'

import { isValidPhoneNumber } from 'react-phone-number-input'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, useToast, Stack } from '@chakra-ui/react'

import { PictureInput, Input, Select, PhoneInput } from 'components'
import AddEntreprise from './AddEntreprise'

import { useAuth, useDatas } from 'contexts'

import { fileToBePost } from 'helpers/utils'

const AddReferent = ({ open, onClose, callback = () => {} }) => {
  const { callApi } = useAuth()
  const { datas: { companies: companyOptions }, reload: { companies: getCompanies } } = useDatas()

  const [picture, setPicture] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()// +33XXXXX
  const [company, setCompany] = useState()
  const [openAddEntreprise, setOpenAddEntreprise] = useState(false)

  const toast = useToast()

  const submit = async () => {
    callApi({
      method: 'post',
      url: 'admin/construction-actor-referents',
      formData: true,
      data: Object.assign(
        {
          first_name: firstName,
          last_name: lastName
        },
        isValidPhoneNumber(phone) ? { phone } : {},
        email ? { email } : {},
        picture ? { picture: fileToBePost(picture.data_url) } : {}
      )
    })
      .then(res => {
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Référent ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        const newReferent = res?.data?.data?.id ? { value: res.data.data.id, label: res.data.data.fullName } : null
        callback(newReferent)
      })
  }

  useEffect(() => {
    if (company?.value === 'add') {
      setOpenAddEntreprise(true)
    }
  }, [company])

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Nouveau référent</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour ajouter un nouveau référent, entrez simplement ses informations de contact ci-dessous :</Text>
        <PictureInput picture={picture} setValue={(imageList) => setPicture(imageList[0])} onRemove={() => setPicture(undefined)} />
        <Stack spacing={5} my='5'>
          <Input name='Prénom *' value={firstName} setValue={setFirstName} />
          <Input name='Nom *' value={lastName} setValue={setLastName} />
          <Input name='Email' value={email} setValue={setEmail} type='email' />
          <PhoneInput value={phone} setValue={setPhone} />
          <Select placeholder='Entreprise' value={company} setValue={setCompany} options={companyOptions} addOption />
        </Stack>
        <AddEntreprise open={openAddEntreprise} onClose={() => { setOpenAddEntreprise(false); setCompany(null) } } callback={(newCompany) => { getCompanies(); setCompany(newCompany); setOpenAddEntreprise(false) }} />
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Ajouter le référent</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddReferent
