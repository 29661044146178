import { useState, useEffect } from 'react'

import moment from 'moment'

import { Box, Text, Heading, Divider, Button, TableContainer, Table, Tbody, Tr, Td, Stack, Progress } from '@chakra-ui/react'

import { Select as MySelect, DateTimeInput } from 'components'

import { useAuth, useDatas } from 'contexts'

import './Statistics.css'

const TableResult = ({ results, name, hourType }) => {
  return <Box w="100%">
		<Heading as={'h5'} fontSize="15px" size={'md'} mb={'20px'} mr="auto">{name}</Heading>
		{!results?.length
		  ? <Box className="statistic-global-totals-box">
					<Text>Total</Text>
					<Text>{hourType ? '0h' : '- 0,00 €'}</Text>
				</Box>
		  : <Box className="statistic-global-totals-box" padding="0!important">
					<TableContainer w="100%">
						<Table variant="simple" size="sm" className="statistic-table">
							<Tbody>
								{results?.map((result, index) => (
									<Tr key={index}>
										<Td fontWeight="inherit!important">{result.label}</Td>
										<Td fontWeight="inherit!important" isNumeric>
											{hourType ? `${result.value} h` : `-${parseFloat(result.value).toFixed(2)} €`}
										</Td>
									</Tr>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				</Box>
		}
	</Box>
}

const CardResult = ({ name, value, negative }) => {
  return <Box className="statistic-global-budget-box">
		<Text fontWeight='normal' mb='2'>{name}</Text>
		<Text>{`${negative ? '-' : ''}${value ?? '0.00'} €`}</Text>
	</Box>
}

const Statistics = () => {
  const { callApi } = useAuth()
  const { datas: { chantiers: chantierOptions } } = useDatas()

  const [startDateGlobal, setStartDateGlobal] = useState(moment().startOf('month').format('yyyy-MM-DD'))
  const [endDateGlobal, setEndDateGlobal] = useState(moment().format('yyyy-MM-DD'))
  const [startDatePersonal, setStartDatePersonal] = useState(moment().startOf('month').format('yyyy-MM-DD'))
  const [endDatePersonal, setEndDatePersonal] = useState(moment().format('yyyy-MM-DD'))

  const [selectedChantier, setSelectedChantier] = useState()

  const [globaFigures, setGlobaFigures] = useState()
  const [globalFiguresLoading, setGlobalFiguresLoading] = useState(false)
  const [personalFigures, setPersonalFigures] = useState()
  const [personalFiguresLoading, setPersonalFiguresLoading] = useState(false)

  const getGlobalFigures = () => {
    setGlobalFiguresLoading(true)
    callApi({
      method: 'get',
      url: `admin/figures?start_date=${moment(startDateGlobal).format('yyyy-MM-DD')}&end_date=${moment(endDateGlobal).format('yyyy-MM-DD')}`,
      catchCallback: () => setGlobalFiguresLoading(false)
    })
      .then(res => {
        if (!res) return
        setGlobaFigures(res.data.data)
        setGlobalFiguresLoading(false)
      })
  }

  const getPersonalFigures = (id) => {
    setPersonalFiguresLoading(true)
    callApi({
      method: 'get',
      url: `admin/figures?start_date=${moment(startDatePersonal).format('yyyy-MM-DD')}&end_date=${moment(endDatePersonal).format('yyyy-MM-DD')}&construction_site_id=${id ?? selectedChantier?.value}`,
      catchCallback: () => setPersonalFiguresLoading(false)
    })
      .then(res => {
        if (!res) return
        setPersonalFigures(res.data.data)
        setPersonalFiguresLoading(false)
      })
  }

  useEffect(() => {
    getGlobalFigures()
  }, [])

  useEffect(() => {
    if (chantierOptions.length > 0) {
      setSelectedChantier({ value: chantierOptions[0].value, label: chantierOptions[0].label })
      getPersonalFigures(chantierOptions[0].value)
    }
  }, [chantierOptions])

  return <>
		<Box className="about-div">
			<Stack className="statistic-global-row">
				<Heading as="h5" size="sm" color='#363958' fontWeight="800" fontSize='16px' width='210px'>Global</Heading>
				<DateTimeInput name='Début période' value={startDateGlobal} setValue={setStartDateGlobal} width='210px' />
				<DateTimeInput name='Début période' value={endDateGlobal} setValue={setEndDateGlobal} width='210px' />
				<Button m='0px !important' data-variant='solid' onClick={getGlobalFigures}>Valider</Button>
			</Stack>
			<Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
			<Stack direction={{ base: 'row' }} width={'100%'} justifyContent={'space-between'} padding="24px" spacing='24px'>
				<TableResult name='Factures' results={globaFigures?.billings} />
				<TableResult name='Heures' results={globaFigures?.hours} hourType />
			</Stack>
			{globalFiguresLoading && <Progress style={{ position: 'absolute', width: '100%', bottom: 0, left: 0, margin: 0 }} size='xs' isIndeterminate />}
		</Box>

		<Box className="about-div">
			<Stack className="statistic-global-row">
				<MySelect name='Chantier' value={selectedChantier} setValue={setSelectedChantier} options={chantierOptions} width='210px' />
				<DateTimeInput name='Début période' value={startDatePersonal} setValue={setStartDatePersonal} width='210px' />
				<DateTimeInput name='Début période' value={endDatePersonal} setValue={setEndDatePersonal} width='210px' />
				<Button m='0px !important' data-variant='solid' onClick={() => getPersonalFigures()}>Valider</Button>
			</Stack>
			<Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
			<Heading as={'h5'} fontSize="15px" size={'md'} mx={'20px'} mt='28px'>Budget</Heading>
			<Stack direction={{ base: 'column', sm: 'row' }} width="full" justifyContent='flex-start' padding="24px" spacing={7}>
				<CardResult name='Initial' value={personalFigures?.budgets[0]?.value} />
				<CardResult name='Restant' value={personalFigures?.budgets[1]?.value} />
				<CardResult name='Factures' value={personalFigures?.budgets[2]?.value} isNegative={personalFigures?.budgets[2]?.isNegative} />
			</Stack>
			<Stack direction={{ base: 'row' }} width={'100%'} justifyContent={'space-between'} padding="24px" spacing='24px'>
				<TableResult name='Factures' results={personalFigures?.billings} />
				<TableResult name='Heures' results={personalFigures?.hours} hourType />
			</Stack>
			{personalFiguresLoading && <Progress style={{ position: 'absolute', width: '100%', bottom: 0, left: 0, margin: 0 }} size='xs' isIndeterminate />}
		</Box>
	</>
}

export default Statistics
