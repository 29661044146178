import { useState, useEffect, useCallback, Fragment } from 'react'

import {
  Box,
  Text,
  Heading,
  Divider,
  Button,
  Grid,
  Stack,
  HStack,
  useToast,
  Icon,
  Checkbox
} from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { FiEdit2, FiSmartphone, FiTrash2 } from 'react-icons/fi'

import EditAbsence from './components/modals/EditAbsence'
import EditLabel from './components/modals/EditLabel'
import { LoadingScreen, Input, DateTimeInput } from 'components'

import { useAuth, useModals, useDatas } from 'contexts'

import moment from 'moment'

import './Settings.css'
import EditTask from './components/modals/EditTask'

const Setting = () => {
  const { callApi } = useAuth()
  const { openConfirmToDeleteModal } = useModals()
  const toast = useToast()
  const {
    datas: {
      invoiceLabels,
      absences: absenceLabels,
      positions: employeePositionLabels,
      companies: companyLabels,
      licenses: licenseLabels,
      tasks: taskLabels
    },
    reload: {
      absences: reloadAbsences,
      invoiceLabels: reloadInvoiceLabels,
      positions: reloadPositions,
      companies: reloadCompanies,
      licenses: reloadLicenses,
      defaultDaysHours: reloadDefaultDaysHours,
      tasks: reloadTasks
    }
  } = useDatas()

  const [settings, setSettings] = useState(null)

  const [coefficientNuit, setCoefficientNuit] = useState(0)
  const [coefficientWeekEnd, setCoefficientWeekEnd] = useState(0)
  const [panierRepasAssujetti, setPanierRepasAssujetti] = useState(0)
  const [panierRepasNonAssujetti, setPanierRepasNonAssujetti] = useState(0)
  const [coefficientJourFérié, setCoefficientJourFérié] = useState(0)
  const [coefficientDimanche, setCoefficientDimanche] = useState(0)

  const [lundi, setLundi] = useState('00:00')
  const [mardi, setMardi] = useState('00:00')
  const [mercredi, setMercredi] = useState('00:00')
  const [jeudi, setJeudi] = useState('00:00')
  const [vendredi, setVendredi] = useState('00:00')
  const [samedi, setSamedi] = useState('00:00')
  const [dimanche, setDimanche] = useState('00:00')
  const [defaultDays, setDefaultDays] = useState(1)

  const [hideWeekend, setHideWeekend] = useState(false)
  const [decimalVentilation, setDecimalVentilation] = useState(false)
  const [personalTransport, setPersonalTransport] = useState(false)
  const [packedLunch, setPackedLunch] = useState(false)
  const [loading, setLoading] = useState(false)

  const [editAbsence, setEditAbsence] = useState(null)
  const [editTask, setEditTask] = useState(null)
  const [labelToEdit, setLabelToEdit] = useState(null)
  const [newInvoiceLabelInput, setNewInvoiceLabelInput] = useState('')
  const [newEmployeePositionLabelInput, setNewEmployeePositionLabelInput] = useState('')
  const [newCompanyLabelInput, setNewCompanyLabelInput] = useState('')
  const [newLicenseLabelInput, setNewLicenseLabelInput] = useState('')
  const [newAbsenceLabelInput, setNewAbsenceLabelInput] = useState('')
  const [newTaskLabelInput, setNewTaskLabelInput] = useState('')

  useEffect(() => {
    const getSettings = () => {
      setLoading(true)
      callApi({
        method: 'get',
        url: 'admin/setting'
      })
        .then(res => {
          if (!res) return
          setSettings(res.data.data)
          setLoading(false)

          setHideWeekend(res?.data?.data?.hideWeekEnd)
          setDecimalVentilation(res?.data?.data?.decimalVentilation)
          setPersonalTransport(res?.data?.data?.personalTransport)
          setPackedLunch(res?.data?.data?.packedLunch)
          setCoefficientNuit(res?.data?.data?.rates?.filter(r => r.slug === 'coefficientNuit')[0].value)
          setCoefficientWeekEnd(res?.data?.data?.rates?.filter(r => r.slug === 'coefficientWeekEnd')[0].value)
          setPanierRepasAssujetti(res?.data?.data?.rates?.filter(r => r.slug === 'panierRepasAssujetti')[0].value)
          setPanierRepasNonAssujetti(res?.data?.data?.rates?.filter(r => r.slug === 'panierRepasNonAssujetti')[0].value)
          setCoefficientJourFérié(res?.data?.data?.rates?.filter(r => r.slug === 'coefficientJourFérié')[0].value)
          setCoefficientDimanche(res?.data?.data?.rates?.filter(r => r.slug === 'coefficientDimanche')[0].value)

          setLundi(moment(res?.data?.data?.defaultHours?.filter(r => r.slug === 'lundi')[0].value, [moment.ISO_8601, 'HH:mm']))
          setMardi(moment(res?.data?.data?.defaultHours?.filter(r => r.slug === 'mardi')[0].value, [moment.ISO_8601, 'HH:mm']))
          setMercredi(moment(res?.data?.data?.defaultHours?.filter(r => r.slug === 'mercredi')[0].value, [moment.ISO_8601, 'HH:mm']))
          setJeudi(moment(res?.data?.data?.defaultHours?.filter(r => r.slug === 'jeudi')[0].value, [moment.ISO_8601, 'HH:mm']))
          setVendredi(moment(res?.data?.data?.defaultHours?.filter(r => r.slug === 'vendredi')[0].value, [moment.ISO_8601, 'HH:mm']))
          setSamedi(moment(res?.data?.data?.defaultHours?.filter(r => r.slug === 'samedi')[0].value, [moment.ISO_8601, 'HH:mm']))
          setDimanche(moment(res?.data?.data?.defaultHours?.filter(r => r.slug === 'dimanche')[0].value, [moment.ISO_8601, 'HH:mm']))
          setDefaultDays(res?.data?.data?.defaultDays[0][0]?.value ?? 1)
        })
    }

    getSettings()
  }, [])

  const reloadContext = (context) => {
    if (context === 'invoice') {
      reloadInvoiceLabels()
    } else if (context === 'employee_position') {
      reloadPositions()
    } else if (context === 'company') {
      reloadCompanies()
    } else if (context === 'license') {
      reloadLicenses()
    }
  }

  const updateSettings = () => {
    const transformData = (a, v) => ({
      ...a,
      [v.slug]: { ...v, value: v.value, slug: v.slug }
    })

    Promise.all([
      callApi({
        method: 'post',
        url: 'admin/setting',
        data: {
          rates: [
            { ...settings.rates.filter(r => r.slug === 'coefficientNuit')[0], slug: 'coefficientNuit', value: coefficientNuit },
            { ...settings.rates.filter(r => r.slug === 'coefficientWeekEnd')[0], slug: 'coefficientWeekEnd', value: coefficientWeekEnd },
            { ...settings.rates.filter(r => r.slug === 'panierRepasAssujetti')[0], slug: 'panierRepasAssujetti', value: panierRepasAssujetti },
            { ...settings.rates.filter(r => r.slug === 'panierRepasNonAssujetti')[0], slug: 'panierRepasNonAssujetti', value: panierRepasNonAssujetti },
            { ...settings.rates.filter(r => r.slug === 'coefficientJourFérié')[0], slug: 'coefficientJourFérié', value: coefficientJourFérié },
            { ...settings.rates.filter(r => r.slug === 'coefficientDimanche')[0], slug: 'coefficientDimanche', value: coefficientDimanche }
          ].reduce(transformData, {}),
          defaultHours: [
            { ...settings.defaultHours.filter(r => r.slug === 'lundi')[0], slug: 'lundi', value: moment(lundi).format('HH:mm') },
            { ...settings.defaultHours.filter(r => r.slug === 'mardi')[0], slug: 'mardi', value: moment(mardi).format('HH:mm') },
            { ...settings.defaultHours.filter(r => r.slug === 'mercredi')[0], slug: 'mercredi', value: moment(mercredi).format('HH:mm') },
            { ...settings.defaultHours.filter(r => r.slug === 'jeudi')[0], slug: 'jeudi', value: moment(jeudi).format('HH:mm') },
            { ...settings.defaultHours.filter(r => r.slug === 'vendredi')[0], slug: 'vendredi', value: moment(vendredi).format('HH:mm') },
            { ...settings.defaultHours.filter(r => r.slug === 'samedi')[0], slug: 'samedi', value: moment(samedi).format('HH:mm') },
            { ...settings.defaultHours.filter(r => r.slug === 'dimanche')[0], slug: 'dimanche', value: moment(dimanche).format('HH:mm') }
          ].reduce(transformData, {}),
          defaultDays: [{
            id: settings?.defaultDays[0][0]?.id ?? null,
            value: defaultDays.toString() ?? '1'
          }]
        }
      }),
      callApi({
        method: 'put',
        url: 'admin/setting/planning-week-end',
        data: {
          decimalVentilation: decimalVentilation === true,
          show: hideWeekend === false,
          packedLunch: packedLunch === true,
          personalTransport: personalTransport === true
        }
      })
    ]).then(reses => {
      const res = reses[0]

      if (!res) return
      if (res.data && res.status === 200) {
        reloadDefaultDaysHours()
        toast({
          title: 'Mise à jour effectuée.',
          description: 'Les nouveaux paramètres ont été bien enregistrés.',
          status: 'success',
          duration: 4000,
          isClosable: false,
          position: 'bottom-right'
        })
      }
    })
  }

  const deleteLabel = (id, context, setLabels) => {
    callApi({
      method: 'delete',
      url: 'admin/setting/label',
      data: {
        id,
        context
      }
    })
      .then(() => {
        reloadContext(context)
      })
  }

  const deleteAbsence = useCallback((id) => {
    callApi({
      method: 'delete',
      url: `admin/absences/${id}`
    })
      .then(reloadAbsences)
  }, [reloadAbsences])

  const deleteTask = useCallback((id) => {
    callApi({
      method: 'delete',
      url: `admin/tasks/${id}`
    })
      .then(reloadTasks)
  }, [reloadTasks])

  const addNewLabel = (value, setValue, context) => {
    if (!value) return
    callApi({
      method: 'post',
      url: 'admin/setting/label',
      data: {
        name: value,
        context
      }
    })
      .then(res => {
        if (!res) return
        toast({
          title: 'Mise à jour effectuée.',
          description: 'Les nouveaux paramètres ont été bien enregistrés.',
          status: 'success',
          duration: 4000,
          isClosable: false,
          position: 'bottom-right'
        })
        setValue('')
        reloadContext(context)
      })
  }

  const addAbsence = useCallback(() => {
    if (!newAbsenceLabelInput) return

    callApi({
      method: 'post',
      url: 'admin/absences',
      data: {
        label: newAbsenceLabelInput,
        app_availability: true
      }
    })
      .then(res => {
        if (!res) return
        toast({
          title: 'Mise à jour effectuée.',
          description: 'Les nouveaux paramètres ont été bien enregistrés.',
          status: 'success',
          duration: 4000,
          isClosable: false,
          position: 'bottom-right'
        })
        setNewAbsenceLabelInput('')
        reloadAbsences()
      })
  }, [newAbsenceLabelInput])

  const addTask = useCallback(() => {
    if (!newTaskLabelInput) return
    callApi({
      method: 'post',
      url: 'admin/tasks',
      data: {
        label: newTaskLabelInput,
        measure_id: 1
      }
    })
      .then(res => {
        if (!res) return
        toast({
          title: 'Mise à jour effectuée.',
          description: 'La nouvelle tâche à bien été créee',
          status: 'success',
          duration: 4000,
          isClosable: false,
          position: 'bottom-right'
        })
        setNewTaskLabelInput('')
        reloadTasks()
      })
  }, [newTaskLabelInput])

  return loading
    ? <LoadingScreen />
    : !settings
        ? 'Aucune données disponibles'
        : <>
            <Box className="div-setting">
              <Heading as='h5' size='sm' p='6' color='#363958' fontWeight='600'>Taux, Coefficients et Coûts</Heading>
              <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
              <Grid className='grid-box-container' templateColumns='repeat(4, 1fr)' gap={6} p='24px'>
                {[
                  {
                    name: 'Coefficient nuit',
                    value: coefficientNuit,
                    setValue: setCoefficientNuit
                  },
                  {
                    name: 'Coefficient week end',
                    value: coefficientWeekEnd,
                    setValue: setCoefficientWeekEnd
                  },
                  {
                    name: 'Panier repas assujetti',
                    value: panierRepasAssujetti,
                    setValue: setPanierRepasAssujetti
                  },
                  {
                    name: 'Panier repas non assujetti',
                    value: panierRepasNonAssujetti,
                    setValue: setPanierRepasNonAssujetti
                  },
                  {
                    name: 'Coefficient jour férié',
                    value: coefficientJourFérié,
                    setValue: setCoefficientJourFérié
                  },
                  {
                    name: 'Coefficient dimanche',
                    value: coefficientDimanche,
                    setValue: setCoefficientDimanche
                  }
                ].map(({ name, value, setValue }) => <Input key={name} name={name} value={value} setValue={setValue} type='number' />)}
              </Grid>
            </Box>

            <Box className="div-setting">
              <Heading as='h5' size='sm' p='6' color='#363958' fontWeight='600'>Temps par défaut</Heading>
              <Heading as='h4' size='sm' p='6' color='#363958' fontWeight='400'>Heures de pointage par défaut</Heading>
              <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
              <Grid className='grid-box-container' templateColumns='repeat(4, 1fr)' gap={6} p='24px'>
                {[
                  {
                    name: 'Lundi',
                    value: lundi,
                    setValue: setLundi
                  },
                  {
                    name: 'Mardi',
                    value: mardi,
                    setValue: setMardi
                  },
                  {
                    name: 'Mercredi',
                    value: mercredi,
                    setValue: setMercredi
                  },
                  {
                    name: 'Jeudi',
                    value: jeudi,
                    setValue: setJeudi
                  },
                  {
                    name: 'Vendredi',
                    value: vendredi,
                    setValue: setVendredi
                  },
                  {
                    name: 'Samedi',
                    value: samedi,
                    setValue: setSamedi
                  },
                  {
                    name: 'Dimanche',
                    value: dimanche,
                    setValue: setDimanche
                  }
                ].map(({ name, value, setValue }) => <DateTimeInput key={name} name={name} value={value} setValue={setValue} time={true} date={false} minutesStep='10' />)}
              </Grid>
              <Heading as='h4' size='sm' p='6' color='#363958' fontWeight='400'>Jours du planning</Heading>
              <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
              <Grid className='grid-box-container' templateColumns='repeat(4, 1fr)' gap={6} p='24px'>
                <Input name='Jours par défaut (1-100)' value={defaultDays} setValue={setDefaultDays} type='number' min='1' max='100' />
                <Box display='flex' flexDirection="row">
                  <Checkbox isChecked={hideWeekend} onChange={() => setHideWeekend(!hideWeekend)}>
                    <Text className="ml-2">Masquer les samedis et dimanches dans le planning</Text>
                  </Checkbox>
                </Box>
                <Box display='flex' flexDirection="row">
                  <Checkbox isChecked={packedLunch} onChange={() => setPackedLunch(!packedLunch)}>
                    <Text className="ml-2">Panier repas par défaut</Text>
                  </Checkbox>
                </Box>
                <Box display='flex' flexDirection="row">
                  <Checkbox isChecked={personalTransport} onChange={() => setPersonalTransport(!personalTransport)}>
                    <Text className="ml-2">Transport personnel par défaut</Text>
                  </Checkbox>
                </Box>
                <Box display='flex' flexDirection="row">
                  <Checkbox isChecked={decimalVentilation} onChange={() => setDecimalVentilation(!decimalVentilation)}>
                    <Text className="ml-2">Ventilation des heures en décimales</Text>
                  </Checkbox>
                </Box>
              </Grid>
            </Box>

            <Box className="about-container-btn">
              <Button data-variant='solid'onClick={updateSettings}>Mettre à jour</Button>
            </Box>

            <Box className="div-setting">
              <Heading as='h5' size='sm' p='6' color='#363958' fontWeight='600'>Autres</Heading>
              <Divider borderColor="rgba(0,0,0,.12)" />
              <Grid className='grid-box-container' templateColumns='repeat(4, 1fr)' gap={6} p='24px'>
                {[
                  {
                    key: 'invoice',
                    options: invoiceLabels,
                    name: 'Libellés de facture',
                    value: newInvoiceLabelInput,
                    setValue: setNewInvoiceLabelInput
                  },
                  {
                    key: 'employee_position',
                    options: employeePositionLabels,
                    name: 'Libellés de poste',
                    value: newEmployeePositionLabelInput,
                    setValue: setNewEmployeePositionLabelInput
                  },
                  {
                    key: 'company',
                    options: companyLabels,
                    name: "Libellés d'entreprise/société",
                    value: newCompanyLabelInput,
                    setValue: setNewCompanyLabelInput
                  },
                  {
                    key: 'license',
                    options: licenseLabels,
                    name: 'Libellés de permis',
                    value: newLicenseLabelInput,
                    setValue: setNewLicenseLabelInput
                  },
                  {
                    key: 'absences',
                    options: absenceLabels,
                    name: "Libellés d'absences",
                    value: newAbsenceLabelInput,
                    setValue: setNewAbsenceLabelInput
                  },
                  {
                    key: 'tasks',
                    options: taskLabels,
                    name: 'Libellés de tâches',
                    value: newTaskLabelInput,
                    setValue: setNewTaskLabelInput
                  }
                ].map(({ key, name, value, setValue, options }) => <Stack key={key}>
                  <Text ml='2' mb='2'>{name}</Text>
                  <Box style={{ marginBottom: '1rem' }}>
                    <Input name='Ajouter un nouveau libellé' value={value} setValue={setValue} iconClick={() => key === 'absences' ? addAbsence() : key === 'tasks' ? addTask() : addNewLabel(value, setValue, key)} icon={<MdAdd />} />
                  </Box>
                  <Box height='450px' overflow='auto'>
                    {options?.map(option => <HStack mb='3' key={`${option.type}-${option.value}`} border='1px solid #E2E8F0' borderRadius='10px' p='3' justify="space-between">
                      <Text>{option.label}</Text>
                      <HStack spacing='0'>
                        {key === 'absences' && option.app_availability && (
                          <Icon as={FiSmartphone} color="#8186aa" />
                        )}
                        {(key !== 'absences' || !option.global) && (
                          <Fragment>
                            <Button className='add_button' variant="ghost" aria-label="Edit label" onClick={() => key === 'absences' ? setEditAbsence(option) : key === 'tasks' ? setEditTask(option) : setLabelToEdit({ ...option, context: key })} height='100%' width='100%' m='0' p='0'>
                              <Icon as={FiEdit2} color="#8186aa" />
                            </Button>
                            <Button className='add_button' variant="ghost" aria-label="Delete label" onClick={() => openConfirmToDeleteModal({
                              label: 'Label',
                              items: [{ id: option.value, label: option.label }],
                              confirm: () => key === 'absences' ? deleteAbsence(option.value) : key === 'tasks' ? deleteTask(option.id) : deleteLabel(option.value, key)
                            })} height='100%' width='100%' m='0' p='0'>
                              <Icon as={FiTrash2} color="#8186aa" />
                            </Button>
                          </Fragment>
                        )}
                      </HStack>
                    </HStack>)}
                  </Box>
                </Stack>)}
                <EditAbsence open={Boolean(editAbsence)} onClose={() => setEditAbsence(null)} labelToEdit={editAbsence} callback={reloadContext} />
                {Boolean(editTask) && <EditTask open={Boolean(editTask)} onClose={() => setEditTask(null)} labelToEdit={editTask} callback={reloadContext} />}
                <EditLabel open={Boolean(labelToEdit)} onClose={() => setLabelToEdit(null)} labelToEdit={labelToEdit} callback={reloadContext} />
              </Grid >
            </Box >
          </>
}

export default Setting
