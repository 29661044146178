import { useState } from 'react'

import { Button, Divider, Container, Heading, HStack, Stack, Text, useToast } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import { Card, Input, Select } from 'components'

import { useAuth, useDatas } from 'contexts'

import { isEmailValid } from 'helpers/utils'

const Profile = () => {
  const { user, setUser, callApi } = useAuth()
  const { datas: { employees } } = useDatas()

  const [firstName, setFirstName] = useState(user.first_name)
  const [lastName, setLastName] = useState(user.last_name)
  const [selectedEmployee, setSelectedEmployee] = useState(user?.employee ? { value: user?.employee?.id, label: user?.fullName } : '')
  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [passwordConf, setPasswordConf] = useState('')
  const [showPasswordConf, setShowPasswordConf] = useState(false)
  const toast = useToast()

  // useEffect(() => {
  //   setEmployees(prevEmployees => [prevEmployees.filter(employee => employee.id !== user.employee.id), ...res.data.data])
  // }, [])

  const handleClickPassword = () => setShowPassword(prevShowPassword => !prevShowPassword)
  const handleClickPasswordConf = () => setShowPasswordConf(prevShowPasswordConf => !prevShowPasswordConf)

  const validate = () => {
    if (email.length === 0) {
      toast({
        position: 'bottom-right',
        description: 'Veuillez entrer votre adresse mail',
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    if (!isEmailValid(email.trim())) {
      toast({
        position: 'bottom-right',
        description: "Le format de l'adresse mail n'est pas valide",
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    return true
  }

  const submit = () => {
    if (validate()) {
      callApi({
        method: 'PUT',
        url: 'admin/profile',
        data: Object.assign(
          {
            id: user.id,
            firstName,
            lastName,
            fullName: `${firstName} ${lastName}`
          },
          password ? { password } : {},
          passwordConf ? { password_confirmation: passwordConf } : {},
          email !== user.email ? { email } : {},
          selectedEmployee !== (user?.employee?.id || user?.employee_id) ? { employee_id: selectedEmployee } : {}
        )
      })
        .then(() => {
          toast({
            position: 'bottom-right',
            description: `L'utilisateur ${firstName} ${lastName} a été modifié avec succès`,
            status: 'success',
            duration: 5000,
            isClosable: false
          })
          setUser({
            ...user,
            email,
            first_name: firstName,
            last_name: lastName
          })
        })
    }
  }

  return (
    <Card>
      <Container maxWidth="md" m='0' p='0'>
        <Stack spacing="8">
          <Stack spacing="6">
            <Stack
              spacing={{
                base: '2',
                md: '3'
              }}
              textAlign="center"
            >
              <Heading fontSize='1.25rem !important' fontWeight="bold" textAlign='left'>Mon Profil</Heading>
              <Text style={{ fontSize: '.75rem' }} color="muted" textAlign='left'>Modifiez les champs ci-dessous pour mettre à jour votre profil :</Text>
            </Stack>
          </Stack>
          <Stack spacing="6">
            <Stack spacing="6">
              <Input name='Prénom' value={firstName} setValue={setFirstName} />
              <Input name='Nom' value={lastName} setValue={setLastName} />
              <Select name='Employé lié (facultatif)' value={selectedEmployee} setValue={setSelectedEmployee} options={employees} />
              <Input name='Email' value={email} setValue={setEmail} type='email' />
              <Input name='Mot de passe' value={password} setValue={setPassword} type={showPassword ? 'text' : 'password'} icon={showPassword ? <ViewIcon /> : <ViewOffIcon />} iconClick={handleClickPassword} />
              <Input name='Confirmer le mot de passe' value={passwordConf} setValue={setPasswordConf} type={showPasswordConf ? 'text' : 'password'} icon={showPasswordConf ? <ViewIcon /> : <ViewOffIcon />} iconClick={handleClickPasswordConf} />
            </Stack>
            <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
          </Stack>
          <HStack >
            <Button data-variant='solid' onClick={submit}>
              Mettre à jour mon profil
            </Button>
          </HStack>
        </Stack>
      </Container>
    </Card>
  )
}
export default Profile
