import { Box } from '@chakra-ui/react'

const Card = ({ padding, p, children, fullSize, ...props }) => {
  return <Box p={padding || p || '40px'} style={fullSize
    ? {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        background: '#fff'
      }
    : {
        border: '1px solid #d1d2e1',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
        borderRadius: '8px',
        background: '#fff'
      }} {...props}>
    { children }
  </Box>
}

export default Card
