const convertResources = ({ resources = [] }) => {
  const convertedResources = resources.flatMap(company => company.employees.map(employee => ({
    id: employee.id,
    groupId: company?.name || null,
    companySlug: company.slug,
    companyName: company?.name,
    companyLabel: company?.label,
    title: employee.fullName,
    extendedProps: {
      employee,
      company: {
        ...company,
        employees: null
      }
    }
  })))

  return convertedResources
}

const orderConvertedResources = ({ resources = [], clientSlug }) => {
  const orderedResources = []

  resources.forEach(resource => {
    if (resource.companySlug.includes(clientSlug)) {
      orderedResources.unshift(resource)
    } else {
      orderedResources.push(resource)
    }
  })

  return orderedResources
}

const filterConvertedResources = ({ resources = [], events, dispo, positions, search, chantier }) => {
  const positionsValues = positions.map(position => position.value)
  const lowSearch = search.toLowerCase()
  const filteredResources = resources.filter(resource => {
    const searchingValue = `${resource.extendedProps.employee?.firstName}${resource.extendedProps.employee?.lastName}${resource.extendedProps.employee?.firstName}${resource.extendedProps.company?.name}`.toLowerCase()

    return (!positionsValues.length || positionsValues.includes(resource.extendedProps.employee?.employeePosition?.id)) &&
    (!lowSearch.length || searchingValue.includes(lowSearch)) &&
    (
      chantier.value === 'all' ||
        events.filter(event => event?.extendedProps?.employee?.id === resource.extendedProps.employee?.id)
          .map(event => event.extendedProps?.constructionSite?.id)
          .includes(chantier.value) ||
        events.filter(event => event?.extendedProps?.employee?.id === resource.extendedProps.employee?.id)
          .map(event => event.extendedProps?.constructionSite?.id).length === 0
    )
  })

  return filteredResources
}

export { convertResources, orderConvertedResources, filterConvertedResources }
