import { useState } from 'react'

import { Box, Text, Heading, Divider, Button, TabPanel, Image, Avatar, Flex } from '@chakra-ui/react'
import AddChantier from 'pages/admin/Chantiers/components/modals/AddChantier'

import { Card } from 'components'

const Infos = ({ chantier }) => {
  const [open, setOpen] = useState(false)

  return (
    <TabPanel>
      <Card p='0' mb='5'>
        <Heading as='h5' size='sm' p='6'>Informations</Heading>
        <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
        <Flex padding={'24px'}>
          <Image boxSize='280px' mb={'20px'} src={`${process.env.REACT_APP_GOOGLE_API_URL}staticmap?center=${chantier.latitude},${chantier.longitude}&zoom=17&size=600x600&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API_KEY}`} alt='Localisation_du_site' />
          {/* {chantier?.map?.uri && <Image boxSize='280px' mb={'20px'} src={chantier?.map?.uri} alt='Localisation_du_site' />} */}
          <Box ml='4' mb={'8px'}>
            <Text fontSize='14px' fontWeight='700' color='#000'>{chantier?.name}</Text>
            <Text fontSize='14px' fontWeight='700' color='#000'>{chantier?.address}</Text>
            <Text fontSize='14px' fontWeight='700' color='#000'>{chantier?.postalCode !== undefined && chantier?.city !== undefined ? chantier?.postalCode + ' ' + chantier?.city : ''}</Text>
            <Text fontSize='12px' color='#000'>Du {chantier?.startDatedmY} au {chantier?.endDatedmY}</Text>
            <Text fontSize='12px'>{chantier?.workerCount} ouvriers</Text>
            <Text display='flex' fontSize='12px'>Budget : <Text ml='2' color='#ff5464' fontWeight='bold'>{chantier?.budget} €</Text></Text>
            <Text display='flex'fontSize='12px'><Text mr='2' color='#ff5464' fontWeight='bold'>{chantier?.moneySpent} €</Text> dépensés</Text>
            <Text display='flex'fontSize='12px'><Text mr='2' color='#ff5464' fontWeight='bold'>{chantier?.moneyRemaining} €</Text> restant</Text>
          </Box>
        </Flex>
      </Card>
      <Card p='0' mb='5'>
        <Heading as='h5' size='sm' p='6'>Image du chantier</Heading>
        <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
        <Box mb="8px" fontSize="1rem" padding="30px 30px 10px">
          {chantier?.picture?.uri && <Image boxSize='200px' mb={'20px'} src={chantier?.picture?.uri} alt='construction-image' />}
        </Box>
      </Card>
      {chantier?.constructionActors?.map((row, i) => (
        <Card p='0' key={`${row?.constructionActorType?.id}-${i}`} mb='5'>
          <Heading as='h5' size='sm' p='6'>{row?.constructionActorType?.name}</Heading>
          <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
          <Flex p='6'>
            <Avatar size='md' src={row?.constructionActorReferent?.picture} name={row?.constructionActorReferent?.fullName} />
            <Box ml='10'>
              <Text>{row?.company?.name}</Text>
              <Text fontSize='12px' margin={'0px 0px 2px'}>{row?.constructionActorReferent?.fullName}</Text>
              <Text fontSize='12px' margin={'0px 0px 2px'}>{row?.constructionActorReferent?.email}</Text>
              <Text fontSize='12px' margin={'0px 0px 2px'} color={'#ff5464'}><Text color='#ff5464' fontWeight='bold'>{row?.constructionActorReferent?.phone}</Text></Text>
            </Box>
          </Flex>
        </Card>
      ))}
      <Button data-variant='solid' onClick={() => setOpen(true)}>Ajouter une nouvelle personne</Button>
      <AddChantier open={open} onClose={() => setOpen(false)} chantier={chantier} />
    </TabPanel>
  )
}

export default Infos
