import { Box, HStack, useBreakpointValue, useColorModeValue } from '@chakra-ui/react'

import ProfileDropdown from './ProfileDropdown'

const Topbar = () => {
  const isDesktop = useBreakpointValue({
    base: false,
    md: false,
    lg: true
  })

  return (
    <Box boxShadow={useColorModeValue('sm', 'sm-dark')} className="app_header" py={{ base: '3', lg: '4' }}>
      <HStack ml={'2rem'} />
      {isDesktop && <HStack spacing="4" marginRight={'3rem'}>
        <ProfileDropdown />
      </HStack>}
    </Box>
  )
}

export default Topbar
