import { createContext, useContext, useState, useEffect } from 'react'

import { useToast, Box } from '@chakra-ui/react'

import Alert from './components/Alert'

import { useAuth } from '../auth/auth'

const initialValues = {
  reload: () => {},
  notificationsWhoNeedToBeRead: false
}

const NotificationsContext = createContext(initialValues)

const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])
  const [notificationsToBeKeepedIds, setNotificationsToBeKeepedIds] = useState([])
  const [welcomeMessage, setWelcomeMessage] = useState(true)
  const { callApi, isConnected, user } = useAuth()
  const toast = useToast()

  const getNotifications = () => {
    callApi({
      method: 'get',
      url: 'admin/notification/check'
    })
      .then(res => {
        if (!res) return
        setNotifications(res?.data?.data?.filter(r => !notificationsToBeKeepedIds.includes(r.id)) ?? [])
        setNotificationsToBeKeepedIds(res.data.data.map(r => r.id))
      })
  }

  const closeNotification = (id) => {
    setNotifications(prev => prev.filter(p => p.id !== id))
  }

  const checkNotification = (id) => {
    callApi({
      method: 'patch',
      url: `admin/notification/${id}`,
      data: { viewed: true }
    })
      .then(res => {
        if (!res) return
        closeNotification(id)
        setNotificationsToBeKeepedIds(prev => prev.filter(pId => pId !== id))
      })
  }

  useEffect(() => {
    if (!isConnected) {
      setNotifications([])
      setNotificationsToBeKeepedIds([])
      return
    }
    if (welcomeMessage) {
      setWelcomeMessage(false)
      toast({
        position: 'bottom-right',
        render: () => (
          <Box color='white' p={3} bg='#1bc181'>
            Bonjour {user?.first_name} {user?.last_name}
          </Box>
        ),
        duration: 3000
      })
    }
    getNotifications()
  }, [isConnected])

  useEffect(() => {
    const timer = setTimeout(getNotifications, 5 * 60 * 1000)// 5 min
    return () => clearTimeout(timer)
  }, [])

  return (
    <NotificationsContext.Provider value={{
      reload: getNotifications,
      notificationsWhoNeedToBeRead: Boolean(notificationsToBeKeepedIds.length)
    }}>
      {children}
      <Box style={{ position: 'fixed', left: 20, bottom: 20, zIndex: 1000, maxHeight: '50%', overflow: 'auto' }}>
        {notifications?.map(notification => <Alert key={notification.id} notification={notification} onClose={() => closeNotification(notification.id)} onCheck={() => checkNotification(notification.id)} />)}
      </Box>
    </NotificationsContext.Provider>
  )
}

const useNotifications = () => {
  return useContext(NotificationsContext)
}

export { NotificationsProvider, useNotifications }
