import { useState, useEffect } from 'react'

import { Box, Text, Heading, Divider, Button, HStack, IconButton, Icon, Progress } from '@chakra-ui/react'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight, MdCheck, MdKeyboardArrowRight } from 'react-icons/md'
import ReactPaginate from 'react-paginate'

import { Select, Card } from 'components'

import './Notifications.css'

import AddVehicule from 'pages/admin/Vehicules/components/modals/AddVehicule'
import AddEngin from 'pages/admin/Engins/components/modals/AddEngin'
import AddEmployee from 'pages/admin/Employees/components/modals/AddEmployee'
import AddInvoice from 'pages/admin/Invoices/components/modals/AddInvoice'

import { useAuth, useNotifications } from 'contexts'

const Notifications = () => {
  const { callApi } = useAuth()
  const { reload } = useNotifications()

  const [loading, setLoading] = useState(true)

  const [notifications, setNotifications] = useState([])
  const [notificationsViewed, setNotificationsViewed] = useState([])

  const [currentPage, setCurrentPage] = useState(1)
  const [resultParPage, setResultParPage] = useState({ value: 40, label: '40 résultats' })
  const [totalPage, setTotalPage] = useState(1)

  const [modalOpen, setModalOpen] = useState('')
  const [modalData, setModalData] = useState()

  const getNotificatations = () => {
    setLoading(true)
    callApi({
      method: 'get',
      url: 'admin/notification/check',
      catchCallback: () => setLoading(false)
    })
      .then(res => {
        if (!res) return
        setNotifications(res?.data?.data)
        setLoading(false)
        reload()
      })
  }

  const getNotificatationViewed = () => {
    setLoading(true)
    callApi({
      method: 'get',
      url: `admin/notification/viewed?page=${currentPage}&perPage=${resultParPage?.value ?? '40'}`,
      catchCallback: () => setLoading(false)
    })
      .then(res => {
        if (!res) return
        setNotificationsViewed(res?.data?.data)
        setTotalPage(res?.data?.meta?.pagination?.total_pages)
        setLoading(false)
      })
  }

  useEffect(() => {
    getNotificatations()
  }, [])

  useEffect(() => {
    getNotificatationViewed()
  }, [currentPage, resultParPage])

  const updateNotificationView = (id, viewed) => {
    if (id === 'all') {
      notifications.forEach(check => updateNotificationView(check.id, viewed))
      return
    }
    callApi({
      method: 'patch',
      url: `admin/notification/${id}`,
      data: { viewed }
    })
      .then(res => {
        getNotificatations()
        getNotificatationViewed()
      })
  }

  return (
    <>
      <AddVehicule open={modalOpen === 'document_vehicle'} onClose={() => setModalOpen('')} vehicule={modalData} />
      <AddEngin open={modalOpen === 'document_machine'} onClose={() => setModalOpen('')} engin={modalData} />
      <AddEmployee open={modalOpen === 'document_employee'} onClose={() => setModalOpen('')} employee={modalData} />
      <AddInvoice open={modalOpen === 'invoice'} onClose={() => setModalOpen('')} invoice={modalData} />
      <Card mb='5' p='0' pb='3' position='relative'>
        <HStack width={'100%'} className='hstack-head-container' position='relative'>
          <Heading as={'h1'} fontSize={'14px'} mb={'20px'} mt='3'>Nouvelles notifications</Heading>
          {notifications.length && <Button onClick={() => updateNotificationView('all', true)} className='notification-check_btn' fontSize={'12px'} rightIcon={<MdCheck className='check-icon' />} bg='#f1f1f4' color={'#363956'} h='25px' borderColor={'#757575'} variant='none' padding={'0px 18px'}>
            Tout marquer comme lu ({notifications.length})
          </Button>}
          {loading && <Progress style={{
            position: 'absolute',
            width: '100%',
            bottom: '-10px',
            left: 0,
            margin: 0
          }} size='xs' isIndeterminate />}
        </HStack>
        <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />

        {notifications?.length
          ? notifications.map(check => (<Box key={check.id}>
              <HStack width={'100%'} className='hstack-body-container' >
                <Box>
                  <Box className='notif-content-box'>
                    <span style={{ width: '8px', height: '8px', background: '#ff5464', borderRadius: '50%', marginRight: '5px', marginTop: '7px' }} />
                    <Text fontWeight='bold' fontSize='12px' _hover={{ color: 'brand.primary', cursor: 'pointer' }} onClick={() => {
                      setModalOpen(check.context)
                      setModalData(check.notifiable.model)
                    }}>{check.content}</Text>
                  </Box>
                  <Box ml={'13px'}>
                    <Text fontSize={'12px'} color={'#8186AA'}>Reçu le {check?.notifiedOn} - {check?.notifiedAt}</Text>
                  </Box>
                </Box>
                <Box>
                  <Button onClick={() => updateNotificationView(check.id, true) } className='notification-check_btn' rightIcon={<MdCheck className='check-icon' />} fontSize={'12px'} bg='#f1f1f4' color={'#363956'} h='25px' variant='none' padding={'0px 18px'} >
                    Marquer comme lu
                  </Button>
                  <IconButton style={{ cursor: 'pointer' }} aria-label='Arrow Right' bg={'none !important'} icon={<MdKeyboardArrowRight className='check-icon' />} onClick={() => {
                    setModalOpen(check.context)
                    setModalData(check.notifiable.model)
                  }} />
                </Box>
              </HStack>
              <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
            </Box>))
          : <Text align='center' my='8'>Aucune nouvelle notification</Text>
        }
      </Card>

      <Card p='0' pb='3' position='relative'>
        <HStack width={'100%'} className='hstack-head-container' position='relative'>
          <Heading as={'h1'} size={'sm'} mb={'20px'} mt='3' className='hstack-head2-container'>Historique</Heading>
          {loading && <Progress style={{
            position: 'absolute',
            width: '100%',
            left: 0,
            bottom: '-10px',
            margin: 0
          }} size='xs' isIndeterminate />}
        </HStack>
        <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
        {notificationsViewed.length
          ? notificationsViewed.map(notif => <Box key={notif.id}>
            <HStack width={'100%'} className='hstack-body-container' >
              <Box>
                <Text fontWeight='bold' fontSize='12px' _hover={{ color: 'brand.primary', cursor: 'pointer' }} onClick={() => {
                  setModalOpen(notif.context)
                  setModalData(notif.notifiable.model)
                }}>{notif.content}</Text>
                <Text fontSize={'12px'} color={'#8186AA'}>Reçu le {notif?.notifiedOn} - {notif?.notifiedAt}</Text>
              </Box>
              <Box>
                <Button onClick={(e) => updateNotificationView(notif.id, false)} className='notification-check_btn' rightIcon={<MdCheck className='check-icon' />} fontSize={'12px'} bg='#f1f1f4' color={'#363956'} h='25px' variant='none' padding={'0px 18px'} >
                  Marquer comme non lu
                </Button>
                <IconButton style={{ cursor: 'pointer' }} aria-label='Arrow Right' bg={'none !important'} icon={<MdKeyboardArrowRight className='check-icon' />} onClick={() => {
                  setModalOpen(notif.context)
                  setModalData(notif.notifiable.model)
                }} />
              </Box>
            </HStack>
            <Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
          </Box>)
          : <Text align='center' my='8'>Aucune notification</Text>
        }
        {notificationsViewed.length
          ? <HStack spacing="3" justify="space-between" className='footer-pagination' px='4' py='3' mt='5'>
              <ReactPaginate
                previousLabel={<Icon as={MdOutlineKeyboardArrowLeft} fontSize="1.50rem" />}
                nextLabel={<Icon as={MdOutlineKeyboardArrowRight} fontSize="1.50rem" />}
                breakLabel="..."
                onPageChange={event => setCurrentPage(event.selected + 1)}
                pageCount={totalPage}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
              <Select name='Nombre par page' value={resultParPage} setValue={setResultParPage} options={[
                { value: 15, label: '15 résultats' },
                { value: 40, label: '40 résultats' },
                { value: 100, label: '100 résultats' }
              ]} width='210px' />
            </HStack>
          : ''
        }
      </Card>
    </>
  )
}

export default Notifications
