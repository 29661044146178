import { useState, useEffect } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast, Grid, GridItem } from '@chakra-ui/react'

import { Select, DateTimeInput } from 'components'

import moment from 'moment'

import { useAuth, useDatas } from 'contexts'

const AddVehicule = ({ open, onClose, defaultValues, callback }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const { datas: { chantiers: chantierOptions } } = useDatas()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [chantier, setChantier] = useState()

  const [vehicule, setVehicule] = useState()
  const [vehiculeOptions, setVehiculeOptions] = useState([])

  const getVehicules = () => {
    callApi({
      method: 'get',
      url: 'admin/search/models/vehicles?search='
    })
      .then(res => {
        if (!res) return
        setVehiculeOptions(res?.data?.data?.map(c => ({ value: c.id, label: c.label })))
      })
  }

  useEffect(() => {
    if (!open) return
    getVehicules()
  }, [open])

  useEffect(() => {
    setStartDate(defaultValues?.startDate ? moment(defaultValues.startDate).format('yyyy-MM-DD') : null)
    setEndDate(defaultValues?.endDate ? moment(defaultValues.endDate).format('yyyy-MM-DD') : null)
    setVehicule('')
    setChantier(defaultValues?.chantierId ? chantierOptions.filter(chantier => defaultValues?.chantierId === chantier.value)[0] : '')
  }, [defaultValues])

  const submit = () => {
    callApi({
      method: 'post',
      url: 'admin/timesheet/vehicle',
      data: Object.assign(
        {
          vehicle_id: vehicule?.value ?? null,
          construction_site_id: chantier?.value ?? null,
          start_date: moment(startDate).format('YYYY-MM-DD 06:00:00'),
          end_date: moment(endDate).format('YYYY-MM-DD 19:00:00')
        }
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || defaultValues?.id ? "Plannification de l'employé modifié avec succès" : 'Employés ajoutés au planning avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Ajouter un véhicule au planning</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour associer un véhicule à un chantier, entrez les informations correspondantes ci-dessous :</Text>
        <Stack spacing={5} my='5'>
          <Select name='Vehicule concerné' value={vehicule} setValue={setVehicule} options={vehiculeOptions} />
          <Select name='Chantier concerné' value={chantier} setValue={setChantier} options={chantierOptions} isDisabled={Boolean(defaultValues?.chantierId)} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <DateTimeInput name='Début période' value={startDate} setValue={setStartDate} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Fin période' value={endDate} setValue={setEndDate} />
            </GridItem>
          </Grid>
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Ajouter</Button>
        <Button data-variant='outline' onClick={onClose}>Annuler</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddVehicule
