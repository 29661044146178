import { useState, useEffect } from 'react'

import moment from 'moment'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, useToast, Stack } from '@chakra-ui/react'

import { DateTimeInput } from 'components'

import { useAuth } from 'contexts'

const AssignVehicule = ({ open, onClose, callback = () => {}, defaultValues, chantierId }) => {
  const { callApi } = useAuth()
  const [submitting, setSubmitting] = useState(false)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [fromChantierId, setFromChantierId] = useState()
  const [vehiculeId, setVehiculeId] = useState()
  const [currentTimesheetId, setCurrentTimesheetId] = useState()

  useEffect(() => {
    setStartDate(defaultValues?.startDate ? moment(defaultValues?.startDate).format('YYYY-MM-DD HH:mm:ss') : null)
    setEndDate(defaultValues?.endDate ? moment(defaultValues?.endDate).format('YYYY-MM-DD HH:mm:ss') : null)
    setVehiculeId(defaultValues?.vehiculeId ?? '')
    setCurrentTimesheetId(defaultValues?.currentTimesheetId ?? '')
    setFromChantierId(defaultValues?.fromChantierId ?? '')
  }, [defaultValues])

  const toast = useToast()

  const submit = () => {
    setSubmitting(true)
    callApi({
      method: fromChantierId ? 'patch' : 'post',
      url: fromChantierId ? `admin/timesheet/vehicle/${chantierId}` : 'admin/timesheet/vehicle',
      data: fromChantierId
        ? {
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : '',
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : '',
            is_cloned: false,
            from: fromChantierId ?? null,
            vehicle_id: vehiculeId ?? null,
            timesheet_schedule_vehicle_id: currentTimesheetId ?? null
          }
        : {
            vehicle_id: vehiculeId ?? null,
            construction_site_id: chantierId ?? null,
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : '',
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : ''
          }

    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Assignation effectué avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Assigner un véhicule</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour assigner cet ouvrier, entrez les informations correspondantes ci-dessous :</Text>
        <Stack spacing={5} my='5'>
          <DateTimeInput name='Début période' value={startDate} setValue={setStartDate} time={true} />
          <DateTimeInput name='Fin période' value={endDate} setValue={setEndDate} time={true} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button isLoading={submitting} data-variant='solid' onClick={submit}>Assigner le véhicule</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AssignVehicule
