import { useState } from 'react'

import { HStack, Button, Box, Progress } from '@chakra-ui/react'

import { Input, OutputSelect } from 'components'

import { useDatas } from 'contexts'

import ExportModal from './modals/Export'
import ValidateModal from './modals/Validate'

const Header = ({ chantier, setChantier, search, setSearch, loading, callback }) => {
  const { datas: { chantiers } } = useDatas()

  const [exportModalOpen, setExportModalOpen] = useState(false)
  const [validateModalOpen, setValidateModalOpen] = useState(false)

  return <HStack p={'18px'} align="center" justify="space-between" position="relative">
    <HStack justify="center" spacing='5'>
      <Box width="210px"><Input name="Rechercher..." value={search} setValue={setSearch} /></Box>
      <OutputSelect name='Chantiers' value={chantier} setValue={setChantier} options={[{ id: 'all', label: 'Tous les chantiers' }, ...chantiers]} />
    </HStack>
    <HStack spacing='5'>
      <Button data-variant='outline' onClick={() => setValidateModalOpen(true)}>{"Valider les feuilles d'heures"}</Button>
      <Button data-variant='solid' onClick={() => setExportModalOpen(true)}>{"Exporter les feuilles d'heures"}</Button>
    </HStack>
    {loading && <Progress style={{
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      margin: 0
    }} size='xs' isIndeterminate />}
    <ExportModal open={exportModalOpen} onClose={() => setExportModalOpen(false)} />
    <ValidateModal open={validateModalOpen} onClose={() => setValidateModalOpen(false)} callback={callback} />
  </HStack>
}

export default Header
