import { useEffect, useState, memo } from 'react'

import { Divider, HStack, Text, Button, Icon, IconButton, TabList, Tab, Tabs, ButtonGroup, Box, Progress, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'
import { AiOutlineExpandAlt, AiOutlineShrink } from 'react-icons/ai'

import ExportPlannings from 'containers/exportPlanning/export'

import { Input, OutputSelect } from 'components'

import { useFilters } from './contexts/filters'

import { useDatas } from 'contexts'

const viewConfigsOptions = [
  {
    name: '1 sem',
    values: {
      duration: { weeks: 1 },
      slotDuration: { days: 1 },
      titleFormat: '[Semaine] WW',
      columnFormat: ['DD', 'ddd']
    }
  },
  {
    name: '2 sem',
    values: {
      duration: { weeks: 2 },
      slotDuration: { days: 1 },
      titleFormat: '[Sem.] WW',
      columnFormat: ['DD', 'ddd']
    }
  },
  {
    name: '1 mois',
    values: {
      duration: { weeks: 4 },
      slotDuration: { days: 1 },
      titleFormat: 'MMM',
      columnFormat: ['DD']
    }
  },
  {
    name: '3 mois',
    values: {
      duration: { weeks: 12 },
      slotDuration: { days: 1 },
      titleFormat: 'MMM',
      columnFormat: ['DD']
    }
  },
  {
    name: '6 mois',
    values: {
      duration: { weeks: 24 },
      slotDuration: { weeks: 1 },
      titleFormat: 'MMM YY',
      columnFormat: ['WW']
    }
  }
]

const Header = () => {
  const {
    filters: {
      zoom,
      chantier,
      planningType,
      fullSize,
      search,
      dispo,
      responsable,
      position,
      selectedCompanies,
      loading
    },
    setters: {
      setZoom,
      setChantier,
      setPlanningType,
      setResponsable,
      setDispo,
      setPosition,
      setSelectedCompanies,
      setFcDuration,
      setSearch
    },
    options: {
      chantiers,
      planningTypes,
      companies,
      responsables
    },
    actions: {
      handleFullSize,
      refreshEndDate
    }
  } = useFilters()
  const { datas: { disponibilitiesAll, positions } } = useDatas()
  const [viewConfigIndex, setViewConfigIndex] = useState(0)

  useEffect(() => {
    const newFcDuration = viewConfigsOptions[viewConfigIndex].values
    setFcDuration(newFcDuration)
    refreshEndDate(newFcDuration.duration.weeks)
  }, [viewConfigIndex])

  return <Box position='relative'>
    <HStack p={'18px'} align="flex-start" justify="space-between">
      <ButtonGroup size='md' isAttached variant='outline'>
        {planningTypes?.map(type => <Button
          key={type.val}
          style={Object.assign({ fontSize: '0.8rem', fontWeight: 'normal' }, planningType === type.val ? { backgroundColor: '#eee' } : {})}
          onClick={() => setPlanningType(type.val)}>
            {type.name}
          </Button>
        )}
      </ButtonGroup>

      <HStack spacing="6">
        <IconButton onClick={handleFullSize} aria-label="Expend screen" icon={<Icon as={fullSize ? AiOutlineShrink : AiOutlineExpandAlt} w={6} h={6} />} />
        <ExportPlannings />
      </HStack>
    </HStack>

    <Divider borderColor="rgba(0,0,0,.12)" />

    <HStack px={'18px'} py={'12px'} align="center" justify="space-between">
      {planningType !== 'vehicule'
        ? <Tabs align="flex-start" index={viewConfigIndex} onChange={setViewConfigIndex}>
            <TabList>
            {viewConfigsOptions.map(option => <Tab key={option.name}
                style={{
                  paddingLeft: '6px',
                  paddingRight: '6px',
                  fontSize: '0.8rem',
                  fontWeight: 'normal',
                  color: '#8186aa !important'
                }}
                _selected={{
                  borderColor: '#ff5464',
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >
                {option.name}
              </Tab>
            )}
            </TabList>
          </Tabs>
        : <Box style={{ display: 'flex' }}>
            <Text px='4'>{100 - zoom}%</Text>
            <Slider aria-label='zoom' colorScheme='#f00' min={0} max={75} step={1} width='240px' value={zoom} onChange={setZoom}>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb color='#f00' />
            </Slider>
        </Box>
    }
      <HStack spacing='8px' py='4px' gap='4'>
        {['ouvrier'].includes(planningType) && <OutputSelect name='Postes' value={position} setValue={setPosition} options={positions} multiple />}
        {['ouvrier'].includes(planningType) && <OutputSelect name='Disponibilités' value={dispo} setValue={setDispo} options={disponibilitiesAll} />}
        {['ouvrier', 'chantier', 'vehicule'].includes(planningType) && <OutputSelect name='Chantiers' value={chantier} setValue={setChantier} options={chantiers} />}
        {['chantier', 'vehicule'].includes(planningType) && <OutputSelect name='Responsables' value={responsable} setValue={setResponsable} options={responsables} />}
        {['chantier', 'vehicule'].includes(planningType) && <OutputSelect name='Sociétés' value={selectedCompanies} setValue={setSelectedCompanies} options={companies} multiple />}
        <Input name="Rechercher..." value={search} setValue={setSearch} width="240px" />
      </HStack>
    </HStack>
    {loading && <Progress style={{
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      margin: 0
    }} size='xs' isIndeterminate />}
  </Box>
}

export default memo(Header)
