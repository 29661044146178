import ExcelJS from 'exceljs'

const generateExcelFile = () => {
  const workbook = new ExcelJS.Workbook()
  workbook.creator = 'Tim Management'
  workbook.created = new Date()

  workbook.properties.date1904 = true
  workbook.calcProperties.fullCalcOnLoad = true

  return workbook
}

const downloadExcelFile = (workbook, filename = 'export') => {
  workbook.xlsx.writeBuffer({
    base64: true
  })
    .then(function (xls64) {
    // build anchor tag and attach file (works in chrome)
      const a = document.createElement('a')
      const data = new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

      const url = URL.createObjectURL(data)
      a.href = url
      a.download = `${filename}.xlsx`
      document.body.appendChild(a)
      a.click()
      setTimeout(function () {
        document.body.removeChild(a)
        window.URL.revokeObjectURL(url)
      },
      0)
    })
    .catch(function (error) {
      console.log(error.message)
    })
}

export { generateExcelFile, downloadExcelFile }
