import { useState, useEffect, memo } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast, Grid, GridItem } from '@chakra-ui/react'

import { Select, DateTimeInput } from 'components'

import moment from 'moment'

import { useAuth, useDatas } from 'contexts'

const Add = ({ open, onClose, defaultValues, callback }) => {
  const { callApi } = useAuth()
  const { datas: { absences } } = useDatas()
  const toast = useToast()

  const [absence, setAbsence] = useState()

  const [absenceStartDate, setAbsenceStartDate] = useState(null)
  const [absenceEndDate, setAbsenceEndDate] = useState(null)

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    setAbsence(defaultValues?.absenceId ? absences.filter(absence => parseInt(defaultValues?.absenceId) === absence.value)[0] : '')
    setAbsenceStartDate(defaultValues?.absenceStartDate ? moment(defaultValues.absenceStartDate).format('yyyy-MM-DDTHH:mm') : null)
    setAbsenceEndDate(defaultValues?.absenceEndDate ? moment(defaultValues.absenceEndDate).format('yyyy-MM-DDTHH:mm') : null)
  }, [defaultValues])

  const submit = () => {
    setSubmitting(true)
    callApi({
      method: 'patch',
      url: `admin/timesheet/employee/absence/${defaultValues?.id}`,
      data: {
        timesheet_absence_id: absence.value,
        start_date: moment(absenceStartDate).format('YYYY-MM-DD HH:mm:ss'),
        end_date: moment(absenceEndDate).format('YYYY-MM-DD HH:mm:ss')
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: 'Absence modifié avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res, `${res?.data?.data?.id}`)
        onClose()
      })
      .finally(() => setSubmitting(false))
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>{"Modifier l'absence"}</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour modifier l&apos;absence de l&apos;employé au chantier (Malade), entrez les informations correspondantes ci-dessous :</Text>
        <Stack spacing={5} my='5'>
          <Select name="Motif d'absence" value={absence} setValue={setAbsence} options={absences} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <DateTimeInput name='Début absence' value={absenceStartDate} setValue={setAbsenceStartDate} time={false} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Fin absence' value={absenceEndDate} setValue={setAbsenceEndDate} time={false} />
            </GridItem>
          </Grid>
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button isLoading={submitting} data-variant='solid' onClick={submit}>Modifier</Button>
        <Button data-variant='outline' onClick={onClose}>Annuler</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default memo(Add)
