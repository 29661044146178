import { Textarea, FormControl, FormLabel } from '@chakra-ui/react'

const MyTextarea = ({ name, value, setValue, variant = 'solid', disabled }) => {
  return <FormControl>
    <Textarea
      data-variant={variant}
      name={name}
      placeholder=" "
      onChange={(e) => setValue(e.target.value)}
      value={value}
      resize='none'
      disabled={disabled}
    />
    <FormLabel htmlFor={name} className='label-form'>{name}</FormLabel>
  </FormControl>
}

export default MyTextarea
