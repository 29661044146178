import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { Box, Text, Heading, Image, HStack, Stack, Avatar } from '@chakra-ui/react'

import { LoadingScreen, Card } from 'components'

import { useAuth } from 'contexts'

import './Home.css'

const Home = () => {
  const [allConstructions, setAllConstructions] = useState([])
  const [loader, setLoader] = useState(false)
  const { callApi } = useAuth()

  useEffect(() => {
    const fetchConstructionsData = async () => {
      setLoader(true)
      callApi({
        method: 'get',
        url: 'admin/dashboard/construction-sites',
        catchCallback: () => setLoader(false)
      })
        .then(res => {
          if (!res) return
          setAllConstructions(res.data.data)
          setLoader(false)
        })
    }

    fetchConstructionsData()
  }, [])

  return (
		<Box className="home-container">
			{
				loader
				  ? <LoadingScreen />
				  : !allConstructions.length
				    ?	'Aucun chantier disponible'
				    : (
				        allConstructions.map((d) => (
									<Card key={d.id} mb='5' p='0'>
										<Stack spacing="4" direction={{ base: 'column', sm: 'row' }} justify="space-between" p='4'>
											<HStack align="flex-start" p='6'>
												{/* {d.map && <Image src={d.map.uri} height='135' mr='4' alt="location-picture" />} */}
												<Image src={`${process.env.REACT_APP_GOOGLE_API_URL}staticmap?center=${d.latitude},${d.longitude}&zoom=17&size=600x600&maptype=roadmap&key=${process.env.REACT_APP_GOOGLE_API_KEY}`} height='135' mr='4' alt="location-picture" />
												<Stack spacing="0">
													<Link to={`/admin/construction-sites/${d.id}`}>
														<Heading as={'h1'} size={'md'} mb='1'>{d.name ?? '-'}</Heading>
													</Link>
													<Text color="#363958" fontSize="14px" style={{ marginBottom: '8px' }}>
														{ d.address }<br/>
														{ d.postalCode } { d.city }
													</Text>
													<Text color="#8186aa" fontSize="12px">Du {d.startDatedmY} au {d.endDatedmY}</Text>
													<Text color="#8186aa" fontSize="12px">{d.workerCount} ouvriers</Text>
													<Text color="#8186aa" fontSize="12px">{d.budget} € dépensés</Text>
												</Stack>
											</HStack>
											<Box className="clocking-status">
												<Box className={`clocking-status-${d.clockingUpToDate ? 'green' : 'red'}`} />
												<Text color="#363958" fontSize="12px">{`Feuille d'heures ${d.clockingUpToDate ? 'à jour' : 'incomplètes'}`}</Text>
											</Box>
										</Stack>
										<Box h="24px" w="100%" backgroundImage="linear-gradient(180deg,rgba(0,0,0,.04) -23%,transparent)" />
										<HStack spacing="3" overflowX="auto" padding="0 24px 24px">
											{d.constructionActors.map((actor, index) => (
											  !actor?.constructionActorReferent
											    ? ''
											    : <Box key={index} className="home-card">
													<Stack w={'100%'} h="100px">
														<Text fontSize="12px" color="#8186aa" mb="14px">
															{actor?.constructionActorType?.name}
														</Text>
														<HStack spacing="4">
															<Avatar src={actor?.constructionActorReferent?.picture?.uri} name={`${actor?.constructionActorReferent?.firstName} ${actor?.constructionActorReferent?.lastName}`} size="md" />
															<Box>
																<Text fontSize="12px" color="#363958">{`${actor?.constructionActorReferent?.firstName} ${actor?.constructionActorReferent?.lastName}`}</Text>
																<Text fontSize="12px" color="#363958">{actor?.constructionActorReferent?.email}</Text>
																<Text fontSize="12px" color="#363958">{actor?.constructionActorReferent?.phone}</Text>
															</Box>
														</HStack>
													</Stack>
												</Box>
											))}
										</HStack>
									</Card>
				        ))
				      )
			}
		</Box>
  )
}

export default Home
