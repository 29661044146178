import React, { useState, useEffect } from 'react'

import { Avatar, Box, Button, Divider, Flex, HStack, IconButton, Table, TableContainer, Tbody, Td, Tr, Text, useToast } from '@chakra-ui/react'
import { FiEdit2 } from 'react-icons/fi'
import { MdOutlineDelete } from 'react-icons/md'
import { GrView } from 'react-icons/gr'

import AddUser from './components/modals/AddUser'
import AddClient from './components/modals/AddClient'

import { useAuth, useModals } from 'contexts'

import './Administration.css'
import { LoadingScreen, Card, Input } from 'components'

const Administration = () => {
  const { role: currentUserRole, callApi } = useAuth()
  const toast = useToast()
  const { openConfirmToDeleteModal } = useModals()
  const [loading, setLoading] = useState(false)
  const [superAdminsList, setSuperAdminsList] = useState([])
  const [clients, setClients] = useState([])

  const [searchInput, setSearchInput] = useState('')

  const [addUserOpen, setAddUserOpen] = useState(false)
  const [addUserData, setAddUserData] = useState()
  const [addUserCientId, setAddUserCientId] = useState()
  const [addUserViewOnly, setAddUserViewOnly] = useState(false)
  const [addUserSuperAdmin, setAddUserSuperAdmin] = useState(false)

  const [addClientOpen, setAddClientOpen] = useState(false)
  const [addClientData, setAddClientData] = useState()
  const [addClientLicenseOnly, setAddClientLicenseOnly] = useState(false)

  const deleteItem = (type, id, id2) => {
    if (!['client', 'sa', 'user'].includes(type)) return
    callApi({
      method: 'delete',
      url: type === 'client' ? `admin/administration/${id}` : type === 'sa' ? `admin/administration/sa/${id}` : type === 'user' ? `admin/administration/clients/${id2}/users/${id}` : '',
      catchCallback: () => setLoading(false)
    })
      .then(() => type === 'sa' ? getAdmins() : getClients())
  }

  const getClients = () => {
    setLoading(true)
    callApi({
      method: 'get',
      url: `admin/administration?search=${searchInput}`,
      catchCallback: () => setLoading(false)
    })
      .then(res => {
        if (!res) return
        setClients(res.data.data)
        setLoading(false)
      })
  }

  const getAdmins = () => {
    setLoading(true)
    callApi({
      method: 'get',
      url: 'admin/administration/sa',
      catchCallback: () => setLoading(false)
    })
      .then(res => {
        if (!res) return
        setSuperAdminsList(res.data.data)
        setLoading(false)
      })
  }

  useEffect(() => {
    currentUserRole === 'super-admin' && getAdmins()
  }, [])

  useEffect(() => {
    getClients()
  }, [searchInput])

  const addLicenseClick = (client) => {
    setAddClientData(client)
    setAddClientLicenseOnly(true)
    setAddClientOpen(true)
  }

  const addUserClick = (clientId) => {
    setAddUserViewOnly(false)
    setAddUserCientId(clientId)
    setAddUserData(null)
    setAddUserOpen(true)
    setAddUserSuperAdmin(false)
  }

  const askLicenseClick = (clientId) => {
    callApi({
      method: 'get',
      url: `admin/administration/clients/${clientId}/ask-licenses`
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: 'Demande effectué avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
      })
  }

  return loading
    ? <LoadingScreen />
    : <>
				<AddUser open={addUserOpen} onClose={() => setAddUserOpen(false)} callback={addUserSuperAdmin ? getAdmins : getClients } user={addUserData} viewOnly={addUserViewOnly} clientId={addUserCientId} superAdmin={addUserSuperAdmin} />
				<AddClient open={addClientOpen} onClose={() => setAddClientOpen(false)} callback={ getClients } client={addClientData} licenseOnly={addClientLicenseOnly} />
				{currentUserRole === 'super-admin' && superAdminsList && (
					<Card p='0' mb='8'>
						<Flex display={'flex'} justifyContent="space-between" align="center" p='5'>
							<Box display={'flex'} justifyContent="center" align='center'>
								<Text fontWeight='bold' mr='4' style={{ display: 'flex', alignItems: 'center' }}>Administration</Text>
							</Box>
							<Button className="admin-header-button" onClick={() => {
							  setAddUserViewOnly(false)
							  setAddUserCientId(null)
							  setAddUserData(null)
							  setAddUserOpen(true)
							  setAddUserSuperAdmin(true)
							}}>Ajouter un Super Administrateur</Button>
						</Flex>
						<Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
						<TableContainer>
							<Table size="sm">
								<Tbody>
									{superAdminsList.map((sa) => {
									  return (
											<Tr key={sa.id}>
												<Td className="admin-table-td">{sa.firstName}</Td>
												<Td className="admin-table-td">{sa.lastName}</Td>
												<Td className="admin-table-td">{sa.status?.label}</Td>
												<Td className="admin-table-td">
													{sa.fullName} - {sa.status?.label}
												</Td>
												<Td className="admin-table-td">{sa.email}</Td>
												<Td className="admin-table-td">*********</Td>
												<Td className="admin-table-td">
													<HStack justifyContent={'center'}>
														<IconButton
															className="icon-button"
															icon={<GrView fontSize={sa.permisions.show ? '1.25rem' : '0'} color="#8186aa" />}
															variant="ghost"
															aria-label="See member"
															onClick={sa.permisions.show
															  ? () => {
															      setAddUserViewOnly(true)
															      setAddUserCientId(null)
															      setAddUserData(sa)
															      setAddUserOpen(true)
															      setAddUserSuperAdmin(true)
															  	}
															  : null}
														/>
														<IconButton
															className="icon-button"
															icon={<FiEdit2 fontSize={sa.permisions.update ? '1.25rem' : '0'} color="#8186aa" />}
															variant="ghost"
															aria-label="Edit member"
															onClick={sa.permisions.update
															  ? () => {
															      setAddUserViewOnly(false)
															      setAddUserCientId(null)
															      setAddUserData(sa)
															      setAddUserOpen(true)
															      setAddUserSuperAdmin(true)
															  	}
															  : null}
														/>

														<IconButton
															className="icon-button"
															icon={<MdOutlineDelete fontSize={sa.permisions.delete ? '1.25rem' : '0'} color="#8186aa" />}
															variant="ghost"
															aria-label="Delete member"
															onClick={sa.permisions.delete
															  ? () => openConfirmToDeleteModal({
															      label: 'Super Administrateur',
															      items: [{ id: sa.id, label: sa.fullName }],
															      confirm: () => deleteItem('sa', sa.id)
															    })
															  : null
															}
														/>
													</HStack>
												</Td>
											</Tr>
									  )
									})}
								</Tbody>
							</Table>
						</TableContainer>
					</Card>
				)}

				{clients && (
					<Card p='0' mb='8'>
						<Flex display={'flex'} justifyContent="space-between" align="center" p='5'>
							<Box display={'flex'} justifyContent="center" align='center'>
								<Text fontWeight='bold' mr='4' style={{ display: 'flex', alignItems: 'center' }}>Administration</Text>
								{currentUserRole === 'super-admin' && <Input name='Rechercher...' value={searchInput} setValue={setSearchInput} width='210px' />}
							</Box>

							<Box display={'flex'} justifyContent="center">
								<Text size="sm" mt={'3'} mr="5">{clients?.length} {clients?.length > 1 ? 'clients' : 'client'}</Text>
								{currentUserRole === 'super-admin' && (
									<Button onClick={() => {
									  setAddClientData(null)
									  setAddClientLicenseOnly(false)
									  setAddClientOpen(true)
									}} className="admin-header-button">Ajouter un client</Button>
								)}
							</Box>
						</Flex>

						<Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />

						{clients?.map(client => <Box key={client.id} margin={'5'} borderWidth="1px" borderRadius={'8px'}>
							<Flex display='flex' padding={'10px 23px 0px'}>
								<Text fontSize={'12px'} fontWeight="bold" mr="2">{client?.name}</Text>
								<Text fontSize={'12px'} color="#8186aa" mr="2">{client?.licenseAllowedCount} licences</Text>
								{currentUserRole === 'super-admin' && (
									<Text
										cursor={'pointer'}
										fontSize={'12px'}
										fontWeight="bold"
										color="#ff5464"
										textDecorationLine={'underline'}
										mr="2"
										onClick={() => {
										  setAddClientData(client)
										  setAddClientLicenseOnly(false)
										  setAddClientOpen(true)
										}}
									>
										Modifier
									</Text>
								)}

								{currentUserRole === 'super-admin' && (
									<Text
										cursor={'pointer'}
										fontSize={'12px'}
										fontWeight="bold"
										color="#8186aa"
										textDecorationLine={'underline'}
										mr="2"
										onClick={() => openConfirmToDeleteModal({
										  label: 'Clients',
										  items: [{ id: client.id, label: client.name }],
										  confirm: () => deleteItem('client', client.id)
										})}
									>
										Supprimer ce client
									</Text>
								)}
							</Flex>
							<Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
							<TableContainer>
								<Table size="sm">
									<Tbody>
										{client?.users?.length > 0
										  ? client.users.map(user => <Tr key={user.id}>
													<Td className="admin-table-td">{user.firstName}</Td>
													<Td className="admin-table-td">{user.lastName} </Td>
													<Td className="admin-table-td">{user.status.label}</Td>
													<Td className="admin-table-td">
														<HStack>
															<Avatar name={user.fullName} src={user.picture} boxSize='8' size='sm'/>
															<Box><Text fontWeight="medium">{user.fullName} - {user.status.label}</Text></Box>
														</HStack>
													</Td>
													<Td className="admin-table-td">{user.email}</Td>
													<Td className="admin-table-td">
														<HStack justifyContent='center'>
															<IconButton
																className="icon-button"
																icon={<GrView fontSize={user.permisions.show ? '1.25rem' : '0'} />}
																variant="ghost"
																aria-label="See member"
																onClick={user.permisions.show
																  ? () => {
																      setAddUserViewOnly(true)
																      setAddUserCientId(client.id)
																      setAddUserData(user)
																      setAddUserOpen(true)
																      setAddUserSuperAdmin(false)
																    }
																  : null
																}
															/>

															<IconButton
																className="icon-button"
																icon={<FiEdit2 fontSize={user.permisions.update ? '1.25rem' : '0'} color="#8186aa" />}
																variant="ghost"
																aria-label="Edit member"
																onClick={user.permisions.update
																  ? () => {
																      setAddUserViewOnly(false)
																      setAddUserCientId(client.id)
																      setAddUserData(user)
																      setAddUserOpen(true)
																      setAddUserSuperAdmin(false)
																    }
																  : null
																}
															/>

															<IconButton
																className="icon-button"
																icon={<MdOutlineDelete fontSize={user.permisions.delete ? '1.25rem' : '0'} color="#8186aa"/>}
																variant="ghost"
																aria-label="Delete member"
																onClick={user.permisions.delete
																  ? () => openConfirmToDeleteModal({
																      label: 'Utilisateurs',
																      items: [{ id: user.id, label: user.fullName }],
																      confirm: () => deleteItem('user', user.id, client.id)
																    })
																  : null
																}
															/>
														</HStack>
													</Td>
												</Tr>)
										  : <Tr><Td textAlign={'center'}>Aucune utilisateur</Td></Tr>

										}
									</Tbody>
								</Table>
							</TableContainer>
							<Box textAlign='left' marginLeft="25px">
								{client.licenseAllowedCount > client.users.length
								  ? <Button className='btn-add-license' onClick={() => addUserClick(client.id)}>Ajouter un utilisateur +</Button>
								  : currentUserRole === 'super-admin'
								    ? <Button className='btn-add-license' onClick={() => addLicenseClick(client)}>Ajouter une licence +</Button>
								    : <Button className='btn-add-license' onClick={() => askLicenseClick(client.id)}>Demander des licences +</Button>
								}
							</Box>
						</Box>)}
					</Card>
				)}
			</>
}

export default Administration
