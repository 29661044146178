import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { useAuth } from 'contexts'

import { GuestRoutes, AdminRoutes, SuperAdminRoutes } from 'pages/routes'

import { LoadingScreen, MaintenanceScreen } from 'components'

import ReactGA from 'react-ga4'

const App = () => {
  const { user, role, isLoading } = useAuth()

  const location = useLocation()

  useEffect(() => {
    console.log(location.pathname)
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location])

  return process.env.REACT_APP_MAINTENANCE === 'true'
    ? <MaintenanceScreen />
    : isLoading
      ? <LoadingScreen />
      : <Routes>
          {!user
            ? <Route path='/*' element={<GuestRoutes />} />
            : role === 'super-admin'
              ? <Route path='/*' element={<SuperAdminRoutes />} />
              : <Route path='/*' element={<AdminRoutes />} />
          }
        </Routes>
}

export default App
