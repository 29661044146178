import moment from 'moment'

const minToDisplayTime = (mins = 0) => {
  let hour = parseInt(mins / 60)
  hour = hour < 10 && hour !== 0 ? `0${hour}` : hour
  let min = parseInt(mins % 60)
  min = min === 0 ? '' : min < 10 ? `0${min}` : min
  return `${hour}h${min}`
}

const isEmailValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const fileToBePost = (dataURI) => {
  // const file = dataURI.includes('http') ? (await getBase64FromUrl(dataURI)).replace('text/html', `image/${dataURI.split('.')[dataURI.split('.').length - 1] ?? 'png'}`) : dataURI
  const file = dataURI
  const splitDataURI = file.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  // const mimeString = dataURI.includes('http') ? `image/${dataURI.split('.')[dataURI.split('.').length - 1] ?? 'png'}` : splitDataURI[0].split(':')[1].split(';')[0]
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) { ia[i] = byteString.charCodeAt(i) }

  return new Blob([ia], { type: mimeString })
}

const getBase64FromUrl = async (url) => {
  const reader = new FileReader()
  const response = await fetch(url, { mode: 'no-cors' })
  const data = await response.blob()

  return await new Promise((resolve, reject) => {
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(data)
  })
}

const getBlobFromUrl = async (url) => {
  return fileToBePost(await getBase64FromUrl(url))
}

const convertMinToHour = (m, format) => {
  const hour = moment.utc().startOf('day').add({ minutes: m }).format('H:mm')
  if (format === 'h:mm') {
    return hour
  } else if (format === 'str') {
    const H = hour.split(':')[0]
    const mm = hour.split(':')[1]
    return H + ' heures ' + mm + ' minutes'
  }
}

const getTimesheetScheduleColor = (endDate) => {
  const currentDate = moment().format('YYYY-MM-DD')

  if (moment(endDate).diff(currentDate, 'days') <= 3) {
    return {
      event: '#DE4433',
      accent: '#990001'
    }
  } else if (moment(endDate).diff(currentDate, 'days') > 3 && moment(endDate).diff(currentDate, 'days') <= 7) {
    return {
      event: '#FCC35B',
      accent: '#bf8200'
    }
  }
  return {
    event: '#1BC181',
    accent: '#188955'
  }
}

export { isEmailValid, fileToBePost, getBlobFromUrl, convertMinToHour, getBase64FromUrl, getTimesheetScheduleColor, minToDisplayTime }
