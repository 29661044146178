import { Box, Drawer, DrawerContent, DrawerOverlay, IconButton, Flex, useColorModeValue, useDisclosure } from '@chakra-ui/react'
import { HiOutlineMenu } from 'react-icons/hi'

import ProfileDropdown from './ProfileDropdown'
import Sidebar from './Sidebar'

const Navbar = () => {
  const { isOpen, onToggle, onClose } = useDisclosure()

  return (
		<Box
			width="full"
			position={'fixed'}
			zIndex="12"
			background={'#fff'}
			py="4"
			px={{
			  base: '4',
			  md: '8'
			}}
			boxShadow={useColorModeValue('sm', 'sm-dark')}
		>
			<Flex justify="space-between">
				<IconButton
					onClick={onToggle}
					display="flex"
					cursor="pointer"
					aria-label="Menu"
					icon={<HiOutlineMenu className="mobile-menu-icon" />}
					background="none"
					borderRadius={'50%'}
				/>
				{/* <Logo /> */}
				<Drawer
					isOpen={isOpen}
					placement="left"
					onClose={onClose}
					isFullHeight
					preserveScrollBarGap // Only disabled for showcase
					trapFocus={false}
				>
					<DrawerOverlay />
					<DrawerContent>
						<Sidebar onItemClick={onClose} />
					</DrawerContent>
				</Drawer>
				<ProfileDropdown />
			</Flex>
		</Box>
  )
}

export default Navbar
