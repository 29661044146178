import { useState, useEffect } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast, Grid, GridItem } from '@chakra-ui/react'

import { Select, DateTimeInput } from 'components'

import moment from 'moment'

import { useAuth, useDatas } from 'contexts'

const Add = ({ open, onClose, addModalDefaultValues, callback }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const { datas: { chantiers: chantierOptions } } = useDatas()

  const [chantier, setChantier] = useState()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [engin, setEngin] = useState([])
  const [enginOptions, setEnginOptions] = useState([])

  useEffect(() => {
    const getEngins = () => {
      callApi({
        method: 'get',
        url: 'admin/search/models/machines?search='
      })
        .then(res => {
          if (!res) return
          setEnginOptions(res?.data?.data?.map(c => ({ value: c.id, label: c.label })))
        })
    }

    getEngins()
  }, [])

  useEffect(() => {
    setStartDate(addModalDefaultValues?.startDate ? moment(addModalDefaultValues.startDate).format('YYYY-MM-DD HH:mm:ss') : null)
    setEndDate(addModalDefaultValues?.endDate ? moment(addModalDefaultValues.endDate).format('YYYY-MM-DD HH:mm:ss') : null)
    setChantier(addModalDefaultValues?.chantierId ? chantierOptions.filter(chantier => addModalDefaultValues?.chantierId === chantier.value)[0] : '')
    setEngin(addModalDefaultValues?.enginId ? enginOptions.filter(engin => addModalDefaultValues?.enginId === engin.value) : [])
  }, [addModalDefaultValues])

  const submit = () => {
    if (!chantier?.value) {
      toast({
        position: 'bottom-right',
        description: 'Le champ chantier est obligatoire.',
        status: 'error',
        duration: 5000,
        isClosable: false
      })
      return
    }
    callApi({
      method: addModalDefaultValues?.id ? 'patch' : 'post',
      url: `admin/timesheet/${addModalDefaultValues?.id ?? 'machine'}`,
      data: {
        machine_id: addModalDefaultValues?.id && addModalDefaultValues?.enginId ? addModalDefaultValues?.enginId : engin?.length ? engin.map(c => c.value) : null,
        construction_site_id: chantier?.value ?? null,
        start_date: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        end_date: endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || addModalDefaultValues?.id ? "Plannification de l'engin modifié avec succès" : 'Engins ajoutés au planning avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Ajouter un engin au planning</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour ajouter un engin au planning, entrez les informations correspondantes ci-dessous :</Text>
        <Stack spacing={5} my='5'>
          <Select name='Chantier concerné' value={chantier} setValue={setChantier} options={chantierOptions} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <DateTimeInput name='Début période' value={startDate} setValue={setStartDate} time={true} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Fin période' value={endDate} setValue={setEndDate} time={true} />
            </GridItem>
          </Grid>
          <Select name='Engins' value={engin} setValue={setEngin} options={enginOptions} isMulti isDisabled={Boolean(addModalDefaultValues?.enginId)} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Valider</Button>
        <Button data-variant='outline' onClick={onClose}>Annuler</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default Add
