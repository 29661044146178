import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast, CheckboxGroup, Checkbox } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

import { Input, Select } from 'components'

import { useAuth, useDatas } from 'contexts'

const AddUser = ({ open, onClose, user, clientId, callback = () => {}, viewOnly, superAdmin }) => {
  const { callApi, role } = useAuth()
  const { datas: { companies, employees } } = useDatas()

  const toast = useToast()

  const [firstName, setFirstName] = useState(undefined)
  const [lastName, setLastName] = useState(undefined)
  const [email, setEmail] = useState(undefined)
  const [status, setStatus] = useState(undefined)
  const [linkedEmployed, setLinkedEmployed] = useState(undefined)
  const [createEmployee, setCreateEmployee] = useState(false)
  const [company, setCompany] = useState(undefined)
  const [statusOptions] = useState(!superAdmin
    ? [
        {
          value: 'admin',
          label: 'Administrateur'
        },
        {
          value: 'cdc',
          label: 'Chef de chantier restreint'
        },
        {
          value: 'cdc-all-cs',
          label: 'Chef de chantier'
        },
        {
          value: 'cdt',
          label: 'Conducteur de travaux'
        },
        {
          value: 'cdt-r',
          label: 'Conducteur de travaux restreint'
        },
        {
          value: 'ouvrier',
          label: 'Compagnon'
        }
      ]
    : [
        {
          value: 'super-admin',
          label: 'Super Admin'
        }
      ]
  )
  const [password, setPassword] = useState(undefined)
  const [passwordConf, setPasswordConf] = useState(undefined)
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConf, setShowPasswordConf] = useState(false)
  const handleClickPassword = () => setShowPassword(prevShowPassword => !prevShowPassword)
  const handleClickPasswordConf = () => setShowPasswordConf(prevShowPasswordConf => !prevShowPasswordConf)

  /* useEffect(() => {
    const getStatus = () => {
      callApi({
        method: 'get',
        url: 'admin/administration/statuses'
      })
        .then(res => setStatusOptions(res?.data?.data?.map(data => ({ value: data.value, label: data.label })) || []))
    }

    getStatus()
  }, []) *//* GETTING BAD REQUEST */

  useEffect(() => {
    setFirstName(user?.firstName ?? '')
    setLastName(user?.lastName ?? '')
    setEmail(user?.email ?? '')
    setStatus(user?.status ?? null)
    setLinkedEmployed(user?.employeeId ? { value: user?.employee?.id, label: user?.employee?.label } : null)
    setPassword('')
    setPasswordConf('')
    setShowPassword(false)
    setShowPasswordConf(false)
    console.log('user', user)
  }, [user])

  const submit = () => {
    callApi({
      method: user ? 'put' : 'post',
      url: superAdmin ? `admin/administration/sa${user ? `/${user.id}` : ''}` : `admin/administration/clients/${clientId}/users${user ? `/${user.id}` : ''}`,
      data: Object.assign(
        {
          first_name: firstName,
          last_name: lastName
        },
        !superAdmin && status ? { status: status?.value } : {},
        email && email !== user?.email ? { email } : {},
        password ? { password } : {},
        passwordConf ? { password_confirmation: passwordConf } : {},
        linkedEmployed?.value ? { employee_id: linkedEmployed.value } : {},
        !superAdmin && !user ? { create_employee: createEmployee } : {},
        createEmployee ? { company: company?.value } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || user ? 'Utilisateur modifié avec succès' : 'Utilisateur ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {user ? viewOnly ? `Détail de ${user.fullName}` : `Modifier ${user.fullName}` : `Ajouter un ${superAdmin ? 'super administrateur' : 'utilisateur'}`}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>
          {user ? viewOnly ? '' : `Pour mettre à jour ${superAdmin ? 'ce super administrateur' : ' cet utilisateur'}, renseigner les informations à modifier ci-dessous :` : `Pour ajouter un ${superAdmin ? 'nouveau super administrateur' : 'nouvel utilisateur'}, entrez les informations de contact ci-dessous :`}
        </Text>
      <Stack spacing={5} my='5'>
          <Input name='Prénom *' value={firstName} setValue={setFirstName} disabled={viewOnly} />
          <Input name='Nom *' value={lastName} setValue={setLastName} disabled={viewOnly} />
          {role !== 'super-admin' && !createEmployee ? <Select name='Employé lié (facultatif)' value={linkedEmployed} setValue={setLinkedEmployed} options={employees} /> : ''}
          {!superAdmin && <Select name="Statut d'accès" value={status} setValue={setStatus} options={statusOptions} isDisabled={viewOnly} />}
          <Input name='Email' value={email} setValue={setEmail} type='email' disabled={viewOnly} />
          {!viewOnly && <Input name='Mot de passe' value={password} setValue={setPassword} type={showPassword ? 'text' : 'password'} icon={showPassword ? <ViewIcon /> : <ViewOffIcon />} iconClick={handleClickPassword} />}
          {!viewOnly && <Input name='Confirmer le mot de passe' value={passwordConf} setValue={setPasswordConf} type={showPasswordConf ? 'text' : 'password'} icon={showPasswordConf ? <ViewIcon /> : <ViewOffIcon />} iconClick={handleClickPasswordConf} />}
          {createEmployee && <Select name='Société' value={company} setValue={setCompany} options={companies} />}
          {role !== 'super-admin' && !user
            ? <CheckboxGroup>
              <Checkbox
                padding='5px 10px'
                _hover={{ color: '#fe5464' }}
                isChecked={createEmployee}
                onChange={() => setCreateEmployee(prev => !prev)}
              >
                <Text>{"Créer l'employé correspondant"}</Text>
              </Checkbox>
            </CheckboxGroup>
            : ''
          }
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={user && viewOnly ? onClose : submit }>
          {user ? viewOnly ? 'Fermer' : 'Metter à jour' : 'Ajouter'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddUser
