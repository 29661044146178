import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, useToast, Stack } from '@chakra-ui/react'

import { Input } from 'components'

import { useAuth } from 'contexts'

const EditLabel = ({ open, onClose, labelToEdit, callback: mcallback = () => {} }) => {
  const { callApi } = useAuth()

  const [label, setLabel] = useState('')

  useEffect(() => {
    setLabel(labelToEdit?.label ?? '')
  }, [labelToEdit])

  const toast = useToast()

  const submit = () => {
    callApi({
      method: 'patch',
      url: 'admin/setting/label',
      data: {
        id: labelToEdit.value,
        context: labelToEdit.context,
        name: label
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Libellé modifié avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        // labelToEdit.onEdit(prevs => prevs.map(prev => prev.id === res?.data?.data?.id ? res.data.data : prev))
        mcallback(labelToEdit.context)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Modifier le libellé</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={5} my='5'>
          <Input name='Libellé' value={label} setValue={setLabel} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Mettre à jour</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default EditLabel
