import { HStack, Button, Box, Progress } from '@chakra-ui/react'

import { Input, OutputSelect } from 'components'

import { useDatas } from 'contexts'
import { useMemo } from 'react'
import moment from 'moment/moment'

const Header = ({ chantier, setChantier, search, setSearch, loading, onOpenAddModal, startDate, endDate }) => {
  const {
    datas: { chantiers }
  } = useDatas()

  const constructionSites = useMemo(() => {
    return chantiers.filter(chan => {
      return moment(chan.startDate).format('YYYY-MM-DD') <= startDate && moment(chan.endDate).format('YYYY-MM-DD') >= startDate
    })
  }, [chantiers])

  return <HStack p={'18px'} align="center" justify="space-between" position="relative">
    <HStack justify="center" spacing='5'>
      <Box width="210px"><Input name="Rechercher..." value={search} setValue={setSearch} /></Box>
      <OutputSelect name='Chantiers' value={chantier} setValue={setChantier} options={[{ id: 'all', label: 'Tous les chantiers' }, ...constructionSites]} />
    </HStack>
    <HStack spacing='5'>
      <Button data-variant='solid' onClick={onOpenAddModal}>Ajouter</Button>
    </HStack>
    {loading && <Progress style={{
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      margin: 0
    }} size='xs' isIndeterminate />}
  </HStack>
}

export default Header
