import { useEffect, useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  useToast,
  Stack,
  Checkbox, CheckboxGroup, Box
} from '@chakra-ui/react'

import { Input } from 'components'

import { useAuth, useDatas } from 'contexts'

const EditAbsence = ({ open, onClose, labelToEdit, callback: mcallback = () => {} }) => {
  const { callApi } = useAuth()
  const {
    reload: {
      absences: reloadAbsences
    }
  } = useDatas()

  const [appAvailability, setAppAvailability] = useState()
  const [label, setLabel] = useState()

  useEffect(() => {
    setAppAvailability(Boolean(labelToEdit?.app_availability))
    setLabel(labelToEdit?.label ?? '')
  }, [labelToEdit])

  const toast = useToast()

  const submit = () => {
    callApi({
      method: 'patch',
      url: `admin/absences/${labelToEdit.value}`,
      data: {
        label,
        app_availability: appAvailability
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Absence modifiée avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })

        reloadAbsences()
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Modifier le libellé</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={5} my='5'>
          <Input name='Libellé' value={label} setValue={setLabel} />
        </Stack>
        <Stack spacing={5} my='5'>
          <Box display='flex' flexDirection="row">
            <CheckboxGroup>
              <Checkbox
                onChange={() => {
                  setAppAvailability(prev => !prev)
                }}
                isChecked={appAvailability}
              />
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              <Text ml={2}>Absence disponible sur l'application</Text>
            </CheckboxGroup>
          </Box>
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Mettre à jour</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default EditAbsence
