import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, Divider, Heading, HStack, Stack, Text, useToast } from '@chakra-ui/react'
import { AiOutlineArrowLeft } from 'react-icons/ai'

import { useAuth } from 'contexts'

import { Input } from 'components'

import { isEmailValid } from 'helpers/utils'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const { callApi } = useAuth()
  const toast = useToast()

  const validate = () => {
    if (email.length === 0) {
      toast({
        position: 'bottom-right',
        description: 'Veuillez entrer votre adresse mail',
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    if (!isEmailValid(email.trim())) {
      toast({
        position: 'bottom-right',
        description: "Le format de l'adresse mail n'est pas valide",
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    return true
  }

  const submit = () => {
    if (validate()) {
      callApi({
        method: 'POST',
        url: 'guest/password/request',
        data: {
          email
        },
        needAuth: false
      })
        .then((res) => {
          toast({
            position: 'bottom-right',
            description: res?.data?.message || 'Nous vous enverrons un mail de réinitialisation si vous vous êtes inscrit',
            status: 'success',
            duration: 5000,
            isClosable: false
          })
        })
    }
  }

  return (
    <Stack spacing="8">
      <Stack spacing="6">
        <Stack
          spacing={{
            base: '2',
            md: '3'
          }}
          textAlign="center"
        >
          <HStack >
            <Link to="/guest"><AiOutlineArrowLeft fontSize="1.5rem" bg={'none'} color ="#ff5464" /></Link>
            <Heading
              fontSize={'1.25rem !important'}
              textAlign='left'
            >
              Mot de passe oublié ?
            </Heading>
          </HStack>
          <Text color="muted" textAlign='left'>Pour réinitialiser votre mot de passe, entrez une adresse email valide ci-dessous :</Text>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="5">
          <Input name='Email' value={email} setValue={setEmail} type='email' />
        </Stack>
				<Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
      </Stack>
      <Button data-variant="solid" onClick={submit}>
        Réinitialiser mon mot de passe
      </Button>
    </Stack>
  )
}
export default ForgotPassword
