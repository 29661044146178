import { Box, Text, Avatar, Icon, Menu, MenuButton, MenuList, MenuItem, useToast } from '@chakra-ui/react'
import { MdAirlineSeatReclineNormal } from 'react-icons/md'
import { TbSteeringWheel } from 'react-icons/tb'
import { BsThreeDots } from 'react-icons/bs'
import { RiTeamLine } from 'react-icons/ri'

import { useDrag } from 'react-dnd'

import { useAuth } from 'contexts'

const Ouvrier = ({ ouvrier, timesheetId, background, driver, passenger, callback }) => {
  const [{ opacity }, drag] = useDrag({
    type: 'ouvrier',
    item: {
      ...ouvrier,
      timesheetId
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  })
  const toast = useToast()
  const { callApi } = useAuth()

  const setAsDriver = () => {
    callApi({
      method: 'patch',
      url: `admin/timesheet/vehicle/employee/${ouvrier.employee.id}`,
      data: {
        employee_default_id: ouvrier.employee.id,
        from: timesheetId,
        to: timesheetId,
        new_index: 0,
        old_index: 1
      }
    })
      .then(res => {
        if (!res) return
        callback(res)
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Assignation effectué avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
      })
  }

  return <Box border='1px solid #D2D8E0' borderRadius='8px' p='3' mb='4' ref={drag} style={{ opacity }} background={background} position='relative'>
  <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      alignItems: 'center'
    }}>
      <Avatar boxSize='6' size='xs' name={ouvrier?.employee?.fullName} src={ouvrier?.employee?.picture?.uri ?? null} />
      {driver ? <Icon as={TbSteeringWheel} /> : passenger ? <Icon as={MdAirlineSeatReclineNormal} /> : ''}
    </Box>
    <Box align='left' ml='3'>
      <Box style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
      <Text fontWeight='bold' fontSize='13px'>{ouvrier?.employee?.fullName}</Text>
        {ouvrier.teamId ? <><RiTeamLine /><Text fontSize='10px'>({ouvrier.teamName})</Text></> : ''}
      </Box>
      <Text mt='-1' fontSize='11px'>{ouvrier?.company?.name ?? '-'}</Text>
      <Text fontSize='11px' lineHeight='initial'>{ouvrier?.employee?.address ?? '-'}</Text>
    </Box>
  </Box>

  {passenger && <Menu>
    <MenuButton style={{ position: 'absolute', top: 10, right: 10 }}>
      <BsThreeDots />
    </MenuButton>
    <MenuList>
      <MenuItem color='#000' onClick={setAsDriver}>Définir comme conducteur</MenuItem>
    </MenuList>
  </Menu>}
</Box>
}

export default Ouvrier
