import {
  Modal,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  ModalOverlay,
  useToast
} from '@chakra-ui/react'
import { Input } from 'components'
import { useCallback, useState } from 'react'
import { CiImport } from 'react-icons/ci'
import { useAuth } from '../../../../../contexts'

const ImportTask = ({ constructionSite, open, onClose, reloadData }) => {
  const { callApi } = useAuth()
  const [file, setFile] = useState()
  const [loading, setLoading] = useState()
  const toast = useToast()

  const downloadModel = useCallback(() => {
    callApi({
      method: 'get',
      url: 'admin/tasks/download-model'
    })
      .then(result => {
        const csv = 'data:text/csv;charset=utf-16,\uFEFF' + result.data
        const link = document.createElement('a')
        link.setAttribute('href', csv)
        link.setAttribute('download', 'tasks_import.csv')
        link.click()
      })
  }, [onClose])

  const submit = useCallback(() => {
    if (!file) {
      return
    }

    callApi({
      method: 'post',
      url: `admin/construction-sites/${constructionSite.id}/tasks-import`,
      formData: true,
      data: { file }
    })
      .then(res => {
        if (res.status === 200) {
          toast({
            position: 'bottom-right',
            description: res?.data?.message || 'Import des tâches effectué avec succès'
          })
          reloadData()
          onClose()
        }
      })
      .finally(() => setLoading(false))
  }, [constructionSite, file])

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="3xl"
    isOpen={open}
    onClose={onClose}>
      <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '45%'
      }}
    >
        <ModalHeader>
            <Text>
                Importer des tâches
            </Text>
            <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <Text fontSize={14} mb='0.5rem'>
              1. Télécharger le modèle <Button data-variant='outline' onClick={downloadModel} size="xs" style={{ padding: 5 }}>Modèle d&apos;import</Button>
          </Text>
          <Text fontSize={14} mb='0.5rem'>
            2. Ajouter vos données dans le modèle
          </Text>
          <Text fontSize={14} mb='0.5rem'>
            3. Enregistrer le fichier en format .csv
          </Text>
          <Text fontSize={14} mb='1.5rem'>
            4. Importer le fichier en cliquant sur le bouton ci-dessous
          </Text>
          <Input
            name={<Button className="ml-2" leftIcon={<CiImport />} data-variant='solid'> Télécharger les données depuis le fichier </Button>}
            value={file}
            setValue={setFile}
            type='file'
            style={{ alignItems: 'center', justifyContent: 'center', height: file ? '100px' : '65px', overflow: 'hidden', textAlign: 'center' }}
            styleLabel={{ textAlign: 'center', width: '100%' }}
          />
        </ModalBody>
        <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' disabled={!file} isLoading={loading} mr='8' onClick={submit}>
          Valider
        </Button>
        <Button data-variant='outline' ml='8' onClick={onClose}>
          Annuler
        </Button>
      </ModalFooter>
    </ModalContent>

  </Modal>
}

export default ImportTask
