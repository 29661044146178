import { useRef, useEffect, useState, useMemo } from 'react'

import { Box, Text, Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'

const OutputSelectOptions = ({ options = [], value, setValue, onClose }) => {
  return <>
    {options.map(option => <Text key={option.value} onClick={() => {
      setValue(option)
      onClose()
    }} color={option.value === value.value ? '#fe5464' : ''} _hover={{ color: '#fe5464', cursor: 'pointer' }} mb='1'>
      {option.label}
    </Text>)}
  </>
}

const OutputSelectOptionsMultiple = ({ options = [], value = [], setValue/*, onClose */ }) => {
  const valueValues = useMemo(() => value.map(v => v.value), [value])

  return <CheckboxGroup>
    {options.map(option => <Checkbox
      key={option.value}
      padding='5px 10px'
      _hover={{ color: '#fe5464' }}
      isChecked={valueValues.includes(option.value)}
      onChange={() => {
        valueValues.includes(option.value)
          ? setValue(prevs => prevs.filter(prev => prev.value !== option.value))
          : setValue(prevs => [...prevs, option])
        // onClose()
      }}

    >
      <Text>{option.label}</Text>
    </Checkbox>)}
  </CheckboxGroup>
}

const OutputSelect = ({ name = 'Filtres', value: mv, setValue: msv, options, multiple, ...props }) => {
  const outputSelectRef = useRef()

  const [open, setOpen] = useState(false)

  const [value, setValue] = useState(mv)
  useEffect(() => {
    if (mv !== value) setValue(mv)
  }, [mv])

  const timeoutRef = useRef()
  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => msv(value), 500)
  }, [value])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (outputSelectRef.current && !outputSelectRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    if (outputSelectRef) { document.addEventListener('mousedown', handleClickOutside) }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [outputSelectRef])

  return <Box ref={outputSelectRef} {...props}>
    <Box display='flex' flexDirection="row" fontSize='1rem' justify="center" align='flex-start' _hover={{ cursor: 'pointer' }} onClick={() => setOpen(prev => !prev)}>
      <Text mr='2px'>{multiple ? name : value ? value.label : name}</Text>
      {open ? <ChevronUpIcon mt='3px' /> : <ChevronDownIcon mt='3px' />}
    </Box>
    <Box style={{ padding: '10px 20px', backgroundColor: '#fff', position: 'absolute', display: open ? 'flex' : 'none', flexDirection: 'column', zIndex: 100, border: '1px solid #d1d2e1', borderRadius: '8px', boxShadow: '0px 8px 22px 4px #d1d2e1', maxHeight: '250px', overflow: 'auto' }}>
      {multiple ? <OutputSelectOptionsMultiple options={options} value={value} setValue={setValue} onClose={() => setOpen(false)} /> : <OutputSelectOptions options={options} value={value} setValue={setValue} onClose={() => setOpen(false)} />}
    </Box>
  </Box>
}

export default OutputSelect
