import { Link } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

import { ReactComponent as TimLogo } from 'assets/images/logos/tim.svg'

const Logo = () => {
  return (
    <Link to="/admin">
      <Box display='flex' padding="20px 24px" height="80px" boxShadow='0 1px 0 0 #d1d2e1' borderBottomWidth={'1px'} >
        <TimLogo />
      </Box>
    </Link>
  )
}

export default Logo
