import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, Grid, GridItem, useToast } from '@chakra-ui/react'

import { PictureInput, Input, Select, Textarea, DateTimeInput } from 'components'

import { /* getBlobFromUrl, */ fileToBePost } from 'helpers/utils'

import { useAuth, useDatas } from 'contexts'

import moment from 'moment'

const AddEngin = ({ open, onClose, engin, callback = () => {}, viewOnly }) => {
  const { callApi } = useAuth()
  const { datas: { licenses: licenseOptions } } = useDatas()

  const toast = useToast()

  const [picture, setPicture] = useState(undefined)
  const [brand, setBrand] = useState(undefined)
  const [year, setYear] = useState(undefined)
  const [immatriculation, setImmatriculation] = useState(undefined)
  const [insuranceDate, setInsuranceDate] = useState(null)
  const [technicalControlFile, setTechnicalControlFile] = useState(undefined)
  const [technicalControlDate, setTechnicalControlDate] = useState(null)
  const [registrationFile, setRegistrationFile] = useState(undefined)
  const [registrationDate, setRegistrationDate] = useState(null)
  const [license, setLicense] = useState(undefined)
  const [description, setDescription] = useState(undefined)

  const [technicalControlFileDefault, setTechnicalControlFileDefault] = useState([])
  const [registrationFileDefault, setRegistrationFileDefault] = useState([])

  useEffect(() => {
    setPicture(engin?.picture?.uri ? { data_url: engin.picture.uri } : '')

    setBrand(engin?.brand ?? '')
    setYear(engin?.year ?? '')
    setImmatriculation(engin?.immatriculation ?? '')
    setInsuranceDate(engin?.insuranceDate ? moment(engin?.insuranceDate).format('YYYY-MM-DD') : null)

    setRegistrationDate(engin?.registrationDate ? moment(engin?.registrationDate).format('YYYY-MM-DD') : null)

    if (engin?.registration) {
      setRegistrationFile(engin.registration.uri)
      // getBlobFromUrl(engin.registration.uri).then(res => setRegistrationFile(res))
      setRegistrationFileDefault([{ name: 'Carte grise', size: 0, url: engin.registration.uri }])
    } else {
      setRegistrationFile('')
      setRegistrationFileDefault([])
    }

    setTechnicalControlDate(engin?.technicalControlDate ? moment(engin?.technicalControlDate).format('YYYY-MM-DD') : null)

    if (engin?.technicalControl) {
      setTechnicalControlFile(engin.technicalControl.uri)
      // getBlobFromUrl(engin.technicalControl.uri).then(res => setTechnicalControlFile(res))
      setTechnicalControlFileDefault([{ name: 'Contrôle technique', size: 0, url: engin.technicalControl.uri }])
    } else {
      setTechnicalControlFile('')
      setTechnicalControlFileDefault([])
    }

    setLicense(engin?.license ? { value: engin.license.id, label: engin.license.name } : '')
    setDescription(engin?.description ?? '')
  }, [engin])

  const submit = async () => {
    if (engin &&
      (
        picture ||
        registrationFile ||
        technicalControlFile ||
        (!picture && engin.picture) ||
        (!registrationFile && engin.registration) ||
        (!technicalControlFile && engin.technicalControl)
      )
    ) {
      callApi({
        method: 'post',
        url: `admin/machines/${engin.id}/edit-file`,
        formData: true,
        data: Object.assign(
          picture && picture?.data_url !== engin?.picture?.uri ? { picture: fileToBePost(picture.data_url) } : {},
          registrationFile ? { registration: registrationFile } : {},
          technicalControlFile ? { technical_control: technicalControlFile } : {}
        )
      })
    }
    callApi({
      method: engin ? 'patch' : 'post',
      url: engin ? `admin/machines/${engin.id}` : 'admin/machines',
      formData: !engin,
      data: Object.assign(
        {
          brand,
          description,
          immatriculation,
          year,
          insurance_date: insuranceDate ? moment(insuranceDate).format('YYYY-MM-DD') : '',
          registrationDate: registrationDate ? moment(registrationDate).format('YYYY-MM-DD') : '',
          technical_control_date: technicalControlDate ? moment(technicalControlDate).format('YYYY-MM-DD') : ''
        },
        insuranceDate ? { insurance_date: moment(insuranceDate).format('YYYY-MM-DD') } : {},
        license ? { license_id: license.value } : {},
        !engin && picture ? { picture: fileToBePost(picture.data_url) } : {},
        !engin && registrationFile ? { registration: registrationFile } : {},
        !engin && technicalControlFile ? { technical_control: technicalControlFile } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || engin ? 'Engin modifié avec succès' : 'Engin ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {engin ? viewOnly ? 'Informations' : 'Modifier cet engin' : 'Ajouter un engin'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>
          {engin ? viewOnly ? '' : "Modifiez les informations d'un engin en éditant le champ ci-dessous :" : 'Pour ajouter un nouvel engin, entrez les informations ci-dessous :'}
        </Text>
        <PictureInput disabled={viewOnly} picture={picture} setValue={(imageList) => setPicture(imageList[0])} onRemove={() => setPicture(undefined)} />
        <Stack spacing={5} my='5'>
          <Input name='Marque engin *' value={brand} setValue={setBrand} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name='Année' value={year} setValue={setYear} disabled={viewOnly} type='number' />
            </GridItem>
            <GridItem w='100%'>
              <Input name='Immatriculation' value={immatriculation} setValue={setImmatriculation} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <DateTimeInput name='Date Assurance' value={insuranceDate} setValue={setInsuranceDate} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name="Carte grise" value={registrationFile} setValue={setRegistrationFile} type="file" defaultFiles={registrationFileDefault} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Date expiration' value={registrationDate} setValue={setRegistrationDate} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name="Contrôle technique" value={technicalControlFile} setValue={setTechnicalControlFile} type="file" defaultFiles={technicalControlFileDefault} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Date expiration' value={technicalControlDate} setValue={setTechnicalControlDate} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <Select name='Permis *' value={license} setValue={setLicense} options={licenseOptions} isDisabled={viewOnly} />
          <Textarea name='Description' value={description} setValue={setDescription} type="textarea" disabled={viewOnly} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={engin && viewOnly ? onClose : submit }>
          {engin ? viewOnly ? 'Fermer' : 'Mettre à jour' : 'Ajouter'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddEngin
