import { useState } from 'react'

import Moment from 'moment'

import { Box, Icon, Button } from '@chakra-ui/react'
import { FiEye } from 'react-icons/fi'

import { useAuth } from 'contexts'

import { Table } from 'components'
import ShowCorbeille from './components/modals/ShowCorbeille'

const columns = [
  {
    key: 'deleted_at',
    name: 'supprimé le',
    display: (value) => value ? Moment(value.slice(0, 10) + ' ' + value.slice(11, 19)).format('DD/MM/YYYY - HH:mm') : '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'label',
    name: 'nom',
    display: (value) => value ?? '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'type',
    name: 'type',
    display: (_value, data) => data?.type_label ?? '-',
    canSort: true,
    displayByDefault: true
  }
]

const Corbeille = () => {
  const { callApi } = useAuth()

  const [modalOpen, setModalOpen] = useState(false)
  const [modalCorbeille, setModalCorbeille] = useState()
  const [modalCallback, setModalCallback] = useState()
  const [modalViewOnly, setModalViewOnly] = useState(false)

  const [typeOptions] = useState([
    { value: 'all', label: 'Tous les types' },
    { value: 'App\\Models\\ConstructionSite', label: 'Chantier' },
    { value: 'App\\Models\\Machine', label: 'Engin' },
    { value: 'App\\Models\\Employee', label: 'Employé' },
    { value: 'App\\Models\\Invoice', label: 'Facture' },
    { value: 'App\\Models\\User', label: 'Utilisateur' }
  ])

  const getCorbeille = ({ currentPage, rowsPerPage, sortBy, sortDir, searchInput, filter }, callback) => {
    callApi({
      method: 'get',
      url: `admin/trash/restorable-items?page=${currentPage}&perPage=${rowsPerPage}&sort[${sortBy}]=${sortDir}&search=${encodeURIComponent(searchInput)}&type=${encodeURIComponent(filter)}`
    })
      .then(res => {
        if (!res) return
        callback(res)
      })
  }

  const viewCorbeille = (corbeille, callback) => {
    setModalOpen(true)
    setModalViewOnly(true)
    setModalCorbeille(corbeille)
    setModalCallback(() => callback)
  }

  const actions = (invoice, callback) => {
    return <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      <Button variant="ghost" aria-label="Show member" className='icon-btn' onClick={() => viewCorbeille(invoice, callback)} m='0' p='0'>
        <Icon as={FiEye} fontSize="1.25rem" />
      </Button>
    </Box>
  }

  return <Box>
    <Table getDatas={getCorbeille} defaultSortBy='deleted_at' actions={actions} columns={columns} filterSelectInput={typeOptions} randomKey={true} defaultSortDir='desc'/>
    <ShowCorbeille open={modalOpen} onClose={() => setModalOpen(false)} corbeille={modalCorbeille} callback={modalCallback} viewOnly={modalViewOnly} />
  </Box>
}

export default Corbeille
