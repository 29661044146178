import { useState } from 'react'

import Moment from 'moment'

import { Box, Text, Icon, Button, useToast } from '@chakra-ui/react'
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi'

import { useAuth, useModals } from 'contexts'

import { Table } from 'components'
import AddInvoice from './components/modals/AddInvoice'

const columns = [
  {
    key: 'id',
    name: 'id',
    display: (value, _data) => value,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'due_date',
    name: 'date',
    display: (_value, { dueDate }) => dueDate ? Moment(dueDate.slice(0, 10) + ' ' + dueDate.slice(11, 19)).format('DD/MM/YYYY') : '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'designation',
    name: 'nom',
    display: (value) => value ?? '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'invoice_label',
    name: 'type',
    display: (_value, data) => data?.invoiceLabel?.name ?? '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'construction_site',
    name: 'chantier',
    display: (value, { constructionSite }) => constructionSite?.name ?? '-',
    displayByDefault: true
  },
  {
    key: 'price_without_tax',
    name: 'ht',
    display: (_value, { priceWithoutTax }) => Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    }).format(priceWithoutTax),
    displayByDefault: true
  },
  {
    key: 'price_all_tax',
    name: 'ttc',
    display: (_value, { priceAllTax }) => Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0
    }).format(priceAllTax),
    displayByDefault: true
  },
  {
    key: 'real_state',
    name: 'statut',
    display: (_value, { state }) => {
      return state
        ? <Text color="muted" className='container-text'>
            <span style={{ minWidth: '10px', minHeight: '10px', background: ['validated', 'processing'].includes(state) ? '#1bc181' : '#de4433', borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
            {state === 'validated' ? 'Validé' : state === 'paid' ? 'Payé' : state === 'processing' ? 'En cours' : state === 'rejected' ? 'Rejeté' : state === 'late' ? 'Retard' : '-'}
          </Text>
        : ''
    },
    displayByDefault: true
  },
  {
    key: 'validated_at',
    name: 'validée le',
    display: (_value, { validatedAt }) => validatedAt ? Moment(validatedAt.slice(0, 10) + ' ' + validatedAt.slice(11, 19)).format('DD/MM/YYYY - HH:mm') : '-',
    displayByDefault: true
  }
]

const Invoices = () => {
  const { callApi } = useAuth()
  const { openConfirmToDeleteModal } = useModals()
  const toast = useToast()

  const [invoices, setInvoices] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalInvoice, setModalInvoice] = useState()
  const [modalCallback, setModalCallback] = useState()
  const [modalViewOnly, setModalViewOnly] = useState(false)

  const getInvoices = ({ currentPage, rowsPerPage, sortBy, sortDir, searchInput }, callback) => {
    callApi({
      method: 'get',
      url: `admin/invoices?page=${currentPage}&perPage=${rowsPerPage}&sort[${sortBy}]=${sortDir}&search=${encodeURIComponent(searchInput)}`
    })
      .then(res => {
        callback(res)
        setInvoices(res?.data?.data)
        setModalInvoice(null)
      })
  }

  const addInvoice = (callback) => {
    setModalOpen(true)
    setModalViewOnly(false)
    setModalCallback(() => callback)
    setModalInvoice(null)
  }

  const editInvoice = (invoice, callback) => {
    setModalOpen(true)
    setModalViewOnly(false)
    setModalInvoice(invoice)
    setModalCallback(() => callback)
  }

  const viewInvoice = (invoice, callback) => {
    setModalOpen(true)
    setModalViewOnly(true)
    setModalInvoice(invoice)
    setModalCallback(() => callback)
  }

  const deleteInvoice = (invoice, callback) => {
    callApi({
      method: 'delete',
      url: `admin/invoices/${invoice.id}`
    })
      .then((res) => {
        toast({
          position: 'bottom-right',
          description: 'Ce véhicule a été bien supprimé.',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
      })
  }

  const deleteInvoices = (selectedInvoices, callback) => {
    if (!selectedInvoices.length) return
    openConfirmToDeleteModal({
      label: 'Factures',
      items: invoices.filter(i => selectedInvoices.includes(i.id)).map(i => ({ id: i.id, label: i.designation })),
      confirm: () =>
        callApi({
          method: 'delete',
          url: 'admin/invoices/delete/multiple',
          data: {
            ids: selectedInvoices
          }
        })
          .then(res => {
            if (!res) return
            toast({
              position: 'bottom-right',
              description: res?.data?.message || 'Véhicules supprimés avec succès',
              status: 'success',
              duration: 5000,
              isClosable: false
            })
            callback(res)
          })
    })
  }

  const actions = (invoice, callback) => {
    return <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      <Button variant="ghost" aria-label="Show member" className='icon-btn' onClick={() => viewInvoice(invoice, callback)} m='0' p='0'>
        <Icon as={FiEye} fontSize="1.25rem" color="#8186aa"/>
      </Button>
      <Button variant="ghost" aria-label="Edit member" className='icon-btn' onClick={() => editInvoice(invoice, callback)} m='0' p='0'>
        <Icon as={FiEdit2} fontSize="1.25rem" color="#8186aa"/>
      </Button>
      <Button variant="ghost" aria-label="Delete member" className='icon-btn' onClick={() => openConfirmToDeleteModal({
        label: 'Factures',
        items: [{ id: invoice.id, label: invoice.designation }],
        confirm: () => deleteInvoice(invoice, callback)
      })} m='0' p='0'>
        <Icon as={FiTrash2} fontSize="1.25rem" color="#8186aa" />
      </Button>
    </Box>
  }

  return <Box>
    <Table getDatas={getInvoices} canBeSelected={true} canSearch={true} actions={actions} columns={columns} addOnClick={addInvoice} deleteSelectedRowsOnClick={deleteInvoices} defaultSortDir='desc' />
    <AddInvoice open={modalOpen} onClose={() => setModalOpen(false)} invoice={modalInvoice} callback={modalCallback} viewOnly={modalViewOnly} />
  </Box>
}

export default Invoices
