import { Link } from 'react-router-dom'

import { useDrop } from 'react-dnd'

import { Box, Text } from '@chakra-ui/react'

import Vehicule from './vehicule'

import { useFilters } from '../../../contexts/filters'

import { minToDisplayTime } from 'helpers/utils'
import { useDatas } from 'contexts'

const ChantierHeader = ({ chantier }) => {
  const {
    datas: {
      chantiersConsumedTimes
    }
  } = useDatas()

  return <Box width={'calc(100% - 40px)'} my='20px' borderBottom={`4px solid ${chantier?.color ?? '#ff5464'}`} minHeight='140px' p='3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'column' }}>
    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
      <Link to={`/admin/construction-sites/${chantier.id}`}><Text fontSize='16px' fontWeight='bold' mb='1'>{chantier.name}</Text></Link>
      <Text fontSize='14px' style={{
        width: '80%',
        whiteSpace: 'normal',
        textAlign: 'center'
      }}>{chantier.address}</Text>
    </Box>
    <Box style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
      <Text align='center' width='fit-content' fontWeight='bold' borderRight='1px solid #E2E8F0' px='4'>{minToDisplayTime(chantiersConsumedTimes[chantier?.id])}</Text>
      <Text width='fit-content' px='4'>{chantier.time ? new Intl.NumberFormat('fr-FR').format(chantier.time) : '- '}h</Text>
    </Box>
  </Box>
}

const Chantier = ({ chantier, assignOuvrier, assignVehicule, callback/*, openTimesheetModal */ }) => {
  const {
    filters: {
      // zoom,
      fullSize
    }
  } = useFilters()

  const [{ isOver, canDrop, isActive }, drop] = useDrop({
    accept: ['timesheet', 'vehicule'],
    drop: (item, _monitor) => {
      assignVehicule(chantier.id, {
        vehiculeId: item.brand ? item.id : item.vehicule.id,
        currentTimesheetId: item.brand ? null : item.timesheetId, // item.id
        fromChantierId: item.brand ? null : item.chantier.id
      })
    },
    canDrop: (_item, _monitor) => {
      return true
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isActive: monitor.isOver() && monitor.canDrop()
    })
  })

  return <Box key={chantier.id} style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    minWidth: '350px',
    maxWidth: '350px',
    height: '100%',
    minHeight: fullSize ? '100%' : 'calc(800px - 82px)',
    borderRight: '1px solid #E2E8F0'
  }}>
    <ChantierHeader chantier={chantier} />
    <Box align='center' width='100%' height='100%' p='5' className={`dropZone ${isOver && canDrop ? 'action' : ''}`} ref={drop}>
      {chantier?.vehicules?.map(vehicule => <Vehicule key={vehicule.timesheetVehiculeId} vehicule={vehicule} ouvriers={vehicule.employees} chantier={chantier} assignOuvrier={assignOuvrier} callback={callback} />)}
      {isActive && <Box border='1px dashed #D2D8E0' borderRadius='8px' p='3' cursor='pointer' bg='#ff5464' color='#fff' mb='5'>
        Lacher pour ajouter le véhicule
      </Box>}
      <Vehicule ouvriers={chantier?.noVehiculesEmployees} chantier={chantier} assignOuvrier={assignOuvrier} callback={callback} />

      {/* {chantier?.timesheetScheduleVehicles?.map(timesheet => <Vehicule key={timesheet.id} timesheet={timesheet} vehicule={timesheet.vehicle} chantier={chantier} ouvriers={timesheet?.peoples || []} assignOuvrier={assignOuvrier} callback={callback} />)}
      {isActive && <Box border='1px dashed #D2D8E0' borderRadius='8px' p='3' cursor='pointer' bg='#ff5464' color='#fff' mb='5'>
        Lacher pour ajouter le véhicule
      </Box>}
      <Vehicule ouvriers={chantier?.withoutVehicles[0]?.peoples || []} timesheet={chantier?.withoutVehicles[0] ?? 'other'} chantier={chantier} assignOuvrier={assignOuvrier} callback={callback} /> */}
    </Box>
  </Box>
}

export default Chantier
