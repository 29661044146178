import { useDrag } from 'react-dnd'

import { HStack, Box, Avatar, Text } from '@chakra-ui/react'

const Vehicule = ({ vehicule, canHaveMorePassenger, openVehiculeModal }) => {
  const [{ opacity }, drag] = vehicule
    ? useDrag({
      type: 'vehicule',
      item: vehicule,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1
      })
    })
    : [{ opacity: 1 }, null]

  return <HStack key={vehicule.id} position='relative' mb='3' p='3' width='100%' border='1px solid #D2D8E0' borderRadius='8px' onClick={() => openVehiculeModal(vehicule)} ref={drag} style={{ opacity }}>
    <Box mr='2'>
      <Avatar boxSize='8' size='xs' name={vehicule.brand} src={vehicule?.picture?.uri ?? null} />
      <Text fontSize='11px'>{vehicule?.peopleInside ?? 0}/{vehicule?.passengerCount}</Text>
    </Box>
    <Box align='left'>
      <Text fontWeight='bold' fontSize='13px'>{vehicule.brand}</Text>
      <Text fontSize='11px'>
        {vehicule.immatriculation ?? '-'}
        <span style={{ width: '8px', height: '8px', background: canHaveMorePassenger ? '#1bc181' : '#de4433', borderRadius: '50%', position: 'absolute', marginTop: '5px', marginLeft: '5px' }} />
      </Text>
    </Box>
  </HStack>
}

export default Vehicule
