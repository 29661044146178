import { Box, Spinner } from '@chakra-ui/react'

const LoadingScreen = ({ style }) => {
  return <Box
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...style
    }}
  >
    <Spinner color='#ff5464' thickness='10px' style={{
      width: '140px',
      height: '140px'
    }} />
  </Box>
}

export default LoadingScreen
