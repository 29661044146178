
import { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { Button, Divider, Heading, HStack, Stack, Text, useToast } from '@chakra-ui/react'
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

import { Input } from 'components'

import { isEmailValid } from 'helpers/utils'

import { useAuth } from 'contexts'

const ResetPassword = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConf, setPasswordConf] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showPasswordConf, setShowPasswordConf] = useState(false)
  const handleClickPassword = () => setShowPassword(prevShowPassword => !prevShowPassword)
  const handleClickPasswordConf = () => setShowPasswordConf(prevShowPasswordConf => !prevShowPasswordConf)
  const { callApi } = useAuth()
  const toast = useToast()
  const navigate = useNavigate()
  const { token } = useParams()

  const validate = () => {
    if (email.length === 0) {
      toast({
        position: 'bottom-right',
        description: 'Veuillez entrer votre adresse mail',
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    if (!isEmailValid(email.trim())) {
      toast({
        position: 'bottom-right',
        description: "Le format de l'adresse mail n'est pas valide",
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    if (password.length === 0) {
      toast({
        position: 'bottom-right',
        description: 'Veuillez entrer votre mot de passe',
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    if (password !== passwordConf) {
      toast({
        position: 'bottom-right',
        description: 'Les mots de passes ne correspondent pas',
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    return true
  }

  const submit = () => {
    if (validate()) {
      callApi({
        method: 'POST',
        url: 'guest/password/reset',
        data: {
          email,
          password,
          password_confirmation: passwordConf,
          token
        },
        needAuth: false
      })
        .then((res) => {
          toast({
            position: 'bottom-right',
            description: res?.data?.message || 'Mot de passe réinitialisé avec succès',
            status: 'success',
            duration: 5000,
            isClosable: false
          })
          navigate('/guest')
        })
    }
  }

  return (
    <Stack spacing="8">
      <Stack spacing="6">
        <Stack
          spacing={{
            base: '2',
            md: '3'
          }}
          textAlign="center"
        >
          <HStack >
            <Link to="/guest"><AiOutlineArrowLeft fontSize="1.5rem" bg={'none'} color ="#ff5464" /></Link>
            <Heading
              fontSize={'1.25rem !important'}
              textAlign='left'

            >
              Réinitialiser le mot de passe
            </Heading>
          </HStack>
          <Text color="muted" textAlign='left'>Pour réinitialiser votre mot de passe, entrez les champs ci-dessous :</Text>
        </Stack>
      </Stack>
      <Stack spacing="6">
        <Stack spacing="5">
          <Input name='Email' value={email} setValue={setEmail} type='email' />
          <Input name='Mot de passe' value={password} setValue={setPassword} type={showPassword ? 'text' : 'password'} icon={showPassword ? <ViewIcon /> : <ViewOffIcon />} iconClick={handleClickPassword} />
          <Input name='Confirmer le mot de passe' value={passwordConf} setValue={setPasswordConf} type={showPasswordConf ? 'text' : 'password'} icon={showPasswordConf ? <ViewIcon /> : <ViewOffIcon />} iconClick={handleClickPasswordConf} />
        </Stack>
        <Divider mt='3rem !important' mb="6px" borderColor="rgba(0,0,0,.12)" />
      </Stack>
      <Button data-variant="solid" onClick={submit}>
        Nouveau mot de passe
      </Button>
    </Stack>
  )
}
export default ResetPassword
