import moment from 'moment'

import { useDrag, useDrop } from 'react-dnd'

import { Box, Text, Avatar, useToast /* Icon, IconButton */ } from '@chakra-ui/react'

import Ouvrier from './ouvrier'

import { useFilters } from '../../../contexts/filters'

import { useAuth, useDatas } from 'contexts'

const Vehicule = ({ timesheet, vehicule, ouvriers, chantier, assignOuvrier, callback }) => {
  const {
    filters: {
      selectedCompanies
    }
  } = useFilters()
  const { callApi } = useAuth()
  const {
    datas: {
      defaultDays
    },
    reload: {
      consumedTime: reloadConsumedTime
    }
  } = useDatas()
  const toast = useToast()

  const [{ opacity }, drag] = vehicule
    ? useDrag({
      type: 'timesheet',
      item: {
        timesheetId: vehicule?.timesheetVehiculeId,
        vehicule: vehicule?.vehicule,
        chantier
      },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1
      })
    })
    : [{ opacity: 1 }, null]

  const [{ isOver, canDrop, isActive }, drop] = useDrop({
    accept: ['ouvrier', 'team'],
    drop: (item, _monitor) => {
      console.log(item)
      // return
      if (vehicule?.timesheetVehiculeId) {
        assignOuvrier(item?.employees?.length ? item.employees.map(e => e.id).join(',') : item.employee.id, {
          from: item?.timesheetId ?? 'other',
          to: vehicule?.timesheetVehiculeId ?? 'other'
        })
      } else {
        callApi({
          method: 'post',
          url: 'admin/timesheet/employee',
          data: Object.assign(
            {
              employee_id: item?.employees?.length ? item.employees.map(e => e.id) : [item.employee.id],
              construction_site_id: chantier.id,
              start_date: moment().set({ h: 7, m: 0, s: 0 }).format('YYYY-MM-DD HH:mm:ss'),
              end_date: moment().add(defaultDays, 'day').set({ h: 17, m: 0, s: 0 }).format('YYYY-MM-DD HH:mm:ss')
            },
            item?.employees?.length ? { team_id: item.id } : {}
          )
        })
          .then(res => {
            if (!res) return
            toast({
              position: 'bottom-right',
              description: res?.data?.message || 'Employés ajoutés au planning avec succès',
              status: 'success',
              duration: 5000,
              isClosable: false
            })
            reloadConsumedTime()
            callback(res)
          })
      }
    },
    canDrop: (_item, _monitor) => {
      return true
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isActive: monitor.isOver() && monitor.canDrop()
    })
  })

  return <Box border='1px solid #D2D8E0' borderRadius='8px' p='3' mb='6' ref={drag} style={{ opacity }} background={vehicule?.vehicule ? '' : '#f1f1f4'}>
    <Box className={`dropZone ${isOver && canDrop ? 'action' : ''}`} ref={drop}>
      {vehicule
        ? <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }} mb='4' position='relative'>
            <Box>
              <Avatar boxSize='6' size='xs' name={vehicule?.vehicule.brand} src={vehicule?.vehicule.picture?.uri ?? null} />
              <Text fontSize='11px'>{ouvriers.length ?? 0}/{vehicule.vehicule.passengerCount}</Text>
            </Box>
            <Box align='left' ml='3'>
              <Text fontWeight='bold' fontSize='13px'>{vehicule.vehicule.brand}</Text>
              <Text mt='-1' fontSize='11px'>{vehicule.vehicule.immatriculation ?? '-'}</Text>
              <Text mt='-1' fontSize='11px'>{`Du ${moment(vehicule.timesheetVehiculeStartDate).format('DD-MM-YYYY')} au ${moment(vehicule.timesheetVehiculeEndDate).format('DD-MM-YYYY')}`}</Text>
            </Box>
            {/* <IconButton variant='ghost' onClick={() => alert(`delete timesheet ${timesheet.id}`)} style={{ position: 'absolute', top: 0, right: 0 }}>
              <Icon as={FiTrash2} fontSize="1rem" color="#8186aa" />
            </IconButton> */}
          </Box>
        : <Box style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start' }} mb='4'>
            <Box align='left' ml='3'>
              <Text fontWeight='bold' fontSize='13px'>Autre / Propre moyen de transport</Text>
            </Box>
          </Box>
      }
      <Box>
        {ouvriers
          .filter((people) => !selectedCompanies.length || selectedCompanies.map(c => c.value).includes(people?.company?.id))
          .length
          ? ouvriers
            .filter((people) => !selectedCompanies.length || selectedCompanies.map(c => c.value).includes(people?.company?.id))
            .map((people, index) => <Ouvrier key={people.employeeId} timesheetId={vehicule?.timesheetVehiculeId} ouvrier={people} background={vehicule?.vehicule ? false : '#fff'} driver={vehicule && index === 0} passenger={vehicule && index !== 0} callback={callback} />)
          : ''
        }
        {isActive && <Box border='1px dashed #D2D8E0' borderRadius='8px' p='3' cursor='pointer' bg='#ff5464' color='#fff'>
          {"Lacher pour ajouter l'ouvrier"}
        </Box>}
      </Box>
    </Box>
  </Box>
}

export default Vehicule
