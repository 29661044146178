import { useEffect, useState } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  useToast,
  Stack,
  Box
} from '@chakra-ui/react'

import { Input, Select } from 'components'

import { useAuth, useDatas } from 'contexts'

const EditTask = ({ open, onClose, labelToEdit, callback: mcallback = () => {} }) => {
  const { callApi } = useAuth()
  const {
    datas: {
      measures: measureList
    },
    reload: {
      tasks: reloadTasks
    }
  } = useDatas()

  const toast = useToast()
  const [measure, setMeasure] = useState()
  const [measuresList, setMeasureList] = useState()
  const [label, setLabel] = useState()

  useEffect(() => {
    const optionsMeasure = (measureList?.map((r) => {
      return ({ value: r.id, label: r.unit })
    }))
    setMeasureList(optionsMeasure)
    if (labelToEdit) {
      const defaultValueSelectMeasure = {
        value: labelToEdit.measure.id,
        label: labelToEdit.measure.unit
      }
      setMeasure(defaultValueSelectMeasure)
    }
    setLabel(labelToEdit?.label ?? '')
  }, [labelToEdit])

  const submit = () => {
    callApi({
      method: 'patch',
      url: `admin/tasks/${labelToEdit.id}`,
      data: {
        label,
        measure_id: measure.value
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Tâche modifiée avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        reloadTasks()
        onClose()
      })
  }
  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Modifier le libellé</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={5} my='5'>
          <Input name='Libellé' value={label} setValue={setLabel} />
        </Stack>
        <Stack spacing={5} my='5'>
          <Box display='flex' flexDirection="row">
          <Select name='Mesure' value={measure} setValue={setMeasure} options={measuresList}></Select>
          </Box>
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Mettre à jour</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default EditTask
