
import { useDrag } from 'react-dnd'

import { useDatas, useModals } from 'contexts'

import { Box, Text } from '@chakra-ui/react'

import Ouvrier from '../vehicules/components/sidebar/ouvrier'

const Team = ({ team, dragAllow = true }) => {
  const {
    reload: {
      teams: reloadTeams
    }
  } = useDatas()
  const { openTeamModal } = useModals()

  const [{ opacity }, drag] = team && dragAllow
    ? useDrag({
      type: 'team',
      item: team,
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1
      })
    })
    : [{ opacity: 1 }, null]

  return <Box onClick={() => openTeamModal({ team, callback: reloadTeams })} cursor='pointer' borderRadius='8px' border='1px solid #D2D8E0' align='left' p='2' pb='0' mb='4'ref={drag} style={{ opacity }}>
    <Text fontSize='14' fontWeight='bold' mb='3'>{team.name}</Text>
    {team.employees.map(employee => <Ouvrier key={`team-${team.id}-employee-${employee.id}`} ouvrier={employee} dragAllow={false} />)}
  </Box>
}

export default Team
