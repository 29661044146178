import { useState } from 'react'

import { Modal, Box, Avatar, HStack, Divider, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast } from '@chakra-ui/react'
import { BsMoonFill } from 'react-icons/bs'

import { useAuth } from 'contexts'

import UpdateClockingModal from './UpdateClocking'

import moment from 'moment'

const Clocking = ({ open, onClose, clocking, callback }) => {
  const { callApi } = useAuth()
  const toast = useToast()

  const [updateOpen, setUpdateOpen] = useState(false)

  const submit = () => {
    callApi({
      method: 'patch',
      url: `admin/clocking/validate/${clocking.clockingId}`,
      data: {
        ...clocking.employee,
        clockingEntries: clocking.employee.clockingEntries.map(clockingEntry => clockingEntry.id === clocking.clockingId
          ? {
              ...clockingEntry,
              validatedAt: clocking?.isValidated ? null : moment().format('YYYY-MM-DD HH:mm:ss')
            }
          : clockingEntry)
      }
    })
      .then(res => {
        if (!res) return
        callback(res)
        toast({
          position: 'bottom-right',
          description: res?.data?.message || clocking?.isValidated ? 'Cette feuille a bien été invalidé' : 'Cette feuille a bien été validé',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <HStack width='100%'>
          <Avatar boxSize='14' mr='4' size='md' name={clocking?.employeeFullName ?? '-'} src={clocking?.picture?.uri ?? null} />
          <Box align='left'>
            <Text mt='0.5' fontSize={24}>{clocking?.employeeFullName ?? '-'}</Text>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Text mt='-1' fontSize={16}>{clocking?.employeePosition ?? '-'}</Text>
            </Box>
          </Box>
        </HStack>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Stack spacing={1} mb='5'>
          <Text fontSize='20px' fontWeight='bold'>{`${clocking?.durationStr}${clocking?.labelAbsence ? ` | ${clocking?.labelAbsence}` : ''}`}{}</Text>

          {clocking?.comment && (
            <Box display='flex' flexDirection="row">
              <Text>Commentaires : <b>{clocking.comment}</b></Text>
            </Box>
          )}

          {clocking?.personalTransport && (
            <HStack>
              <Text>✅ Transport personnel</Text>
            </HStack>
          )}

          {clocking?.packedLunch && (
            <HStack>
              <Text>✅ Panier repas</Text>
            </HStack>
          )}

          <HStack>
            <Text>Heures effectuées de {clocking?.isNight ? 'Nuit 🌙' : 'Jour ☀️'}</Text>
            {clocking?.isNight && <BsMoonFill size='10' />}
          </HStack>
          <Text>🏗 Chantier associé : <b>{clocking?.constructionSite ?? 'Aucun'}</b></Text>
          <Divider borderColor="#A0AEC0" />
          <Text>🙍‍♂️ Créé par : <b>{clocking?.createdBy}</b></Text>
          <Text>🗓 Créé à : <b>{moment.utc(clocking?.createdAt).local().format('DD/MM/YYYY HH:mm:ss')}</b></Text>
          {clocking?.date && moment(clocking?.date).isAfter(moment('2022-11-06'))
            ? <>
                <Divider borderColor="#A0AEC0" />
                <Text>🙍‍♂️ Modifié par : <b>{clocking?.updatedAt !== clocking?.createdAt || clocking.createdBy !== clocking.updatedBy ? clocking?.updatedBy : '-'}</b></Text>
                <Text>🗓 Modifié à : <b>{clocking?.updatedAt !== clocking?.createdAt || clocking.createdBy !== clocking.updatedBy ? moment.utc(clocking?.updatedAt).local().format('DD/MM/YYYY HH:mm:ss') : '-'}</b></Text>
                <Divider borderColor="#A0AEC0" />
                <Text>🙍‍♂️ Validé par : <b>{clocking?.validatedBy?.length ? clocking?.validatedBy : '-'}</b></Text>
                <Text>🗓 Validé à : <b>{clocking?.validatedAt ? moment.utc(clocking?.validatedAt).local().format('DD/MM/YYYY HH:mm:ss') : '-'}</b></Text>
              </>
            : ''
          }
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>{clocking?.isValidated ? 'Invalider cet horaire' : 'Valider cet horaire'}</Button>
        <Button data-variant='outline' onClick={() => setUpdateOpen(true)}>Modifier</Button>
      </ModalFooter>
    </ModalContent>
    <UpdateClockingModal open={updateOpen} onClose={() => setUpdateOpen(false)} clocking={clocking} callback={() => {
      callback()
      onClose()
    }} />
  </Modal>
}

export default Clocking
