import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast } from '@chakra-ui/react'

import { Input } from 'components'

import { useAuth } from 'contexts'

const AddClient = ({ open, onClose, client, callback = () => {}, licenseOnly }) => {
  const { callApi } = useAuth()

  const toast = useToast()

  const [name, setName] = useState(undefined)
  const [licenses, setLicenses] = useState(undefined)

  useEffect(() => {
    setName(client?.name ?? '')
    setLicenses(licenseOnly ? '' : client?.licenseAllowedCount ?? '')
  }, [client])

  const submit = () => {
    callApi({
      method: client && !licenseOnly ? 'patch' : 'post',
      url: client ? licenseOnly ? `admin/administration/clients/${client.id}/licenses` : `admin/administration/${client.id}` : 'admin/administration',
      data: Object.assign(
        {
          license_allowed_count: licenses
        },
        !licenseOnly ? { name } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || client ? licenseOnly ? 'Licenses ajoutées avec succès' : 'Client modifié avec succès' : 'Client ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {client ? licenseOnly ? `Ajouter des licenses à ${client.name}` : `Modifier ${client.name}` : 'Ajouter un client'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>
          {client ? licenseOnly ? 'Pour ajouter des licenses à ce client, renseigner les informations ci-dessous' : 'Pour mettre à jour ce client, renseigner les informations à modifier ci-dessous :' : 'Pour ajouter un client, entrez les informations de contact ci-dessous :'}
        </Text>
      <Stack spacing={5} my='5'>
          <Input name="Nom de l'entreprise *" value={name} setValue={setName} disabled={licenseOnly} />
          <Input name='License *' value={licenses} setValue={setLicenses} type='number' />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={ submit }>
          {client ? licenseOnly ? 'Ajouter les licenses' : 'Metter à jour' : 'Ajouter le client'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddClient
