import { useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, useToast, Stack } from '@chakra-ui/react'

import { Input } from 'components'

import { useAuth } from 'contexts'

const AddEntreprise = ({ open, onClose, callback = () => {} }) => {
  const { callApi } = useAuth()

  const [name, setName] = useState()

  const toast = useToast()

  const submit = () => {
    if (!name.length) {
      toast({
        position: 'bottom-right',
        description: "Le nom de l'entreprise est requis",
        status: 'error',
        duration: 5000,
        isClosable: false
      })
      return
    }
    callApi({
      method: 'post',
      url: 'admin/company',
      data: {
        name
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Entreprise ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        const newEntreprise = res?.data?.data?.id ? { value: res.data.data.id, label: res.data.data.name } : null
        callback(newEntreprise)
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Nouvelle Entreprise</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour ajouter une nouvelle entreprise, entrez simplement son nom ci-dessous :</Text>
        <Stack spacing={5} my='5'>
          <Input name='Name *' value={name} setValue={setName} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>{"Ajouter l'entreprise"}</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddEntreprise
