import { memo } from 'react'

import { HStack, Box } from '@chakra-ui/react'

import { useFilters } from '../../contexts/filters'

const ProgrammedHours = ({ datas }) => {
  const {
    utils: {
      generateDisplayHour
    }
  } = useFilters()

  return <HStack height='30px' overflow='hidden' display='flex' justify='flex-start' align='center'>
    <Box width='301px' align='center'>Heures programmées</Box>
    <Box width='calc(100% - 301px)' overflow='auto'>
      <HStack justify='space-around'>{generateDisplayHour(datas)}</HStack>
    </Box>
  </HStack>
}

export default memo(ProgrammedHours)
