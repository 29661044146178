import { useState, useEffect } from 'react'

import { TabPanel, Tabs, TabList, Tab, Divider, Button, Heading, Text, Box, HStack, Checkbox, Icon } from '@chakra-ui/react'
import { FiEye, FiEdit2, FiTrash2, FiPlus } from 'react-icons/fi'
import { RiArrowRightSFill, RiArrowDownSFill } from 'react-icons/ri'
import { AiFillFile, AiFillFolder, AiFillFolderOpen } from 'react-icons/ai'

import { LoadingScreen, Card, Input } from 'components'

import { useAuth } from 'contexts'

import AddItem from '../modals/AddItem'

const Documents = ({ chantier }) => {
  const { callApi } = useAuth()

  const [search, setSearch] = useState(undefined)

  const [selectedIds, setSelectedIds] = useState([])
  const clickSelectedId = (newId) => setSelectedIds(prev => prev.includes(newId) ? prev.filter(p => p !== newId) : [...prev, newId])

  const [folderOpens, setFolderOpens] = useState([])
  const handleFolderOpens = (newId) => setFolderOpens(prev => prev.includes(newId) ? prev.filter(p => p !== newId) : [...prev, newId])

  const [loading, setLoading] = useState(true)
  const [documentCategories, setDocumentCategories] = useState([])
  const [documentList, setDocumentList] = useState([])
  const [currentCategory, setCurrentCategory] = useState()

  const [addItemOpen, setAddItemOpen] = useState(false)
  const [addItemItem, setAddItemItem] = useState()
  const [addItemDefaultFolder, setAddItemDefaultFolder] = useState()

  useEffect(() => {
    const getDocumentsCategories = () => {
      setLoading(true)

      callApi({
        method: 'get',
        url: 'admin/documents/categories',
        catchCallback: () => setLoading(false)
      })
        .then(res => {
          if (!res) return
          setDocumentCategories(res?.data ?? [])
          setCurrentCategory(res?.data[0].key)
          setLoading(false)
        })
    }

    getDocumentsCategories()
  }, [])

  const getDocumentsByCategory = () => {
    if (!currentCategory) return
    setLoading(true)
    callApi({
      method: 'get',
      url: `admin/documents/${chantier.id}?search=${search ?? ''}&category=${currentCategory}`,
      catchCallback: () => setLoading(false)
    })
      .then(res => {
        if (!res) return
        setDocumentList([...res?.data?.data?.folder, ...res?.data?.data?.files])
        setLoading(false)
      })
  }

  useEffect(() => {
    getDocumentsByCategory()
  }, [currentCategory, search])

  const deleteMultiple = () => {
    callApi({
      method: 'delete',
      url: 'admin/documents/delete/multiple',
      data: {
        ids: selectedIds
      }
    })
      .then(res => {
        if (!res) return
        getDocumentsByCategory()
        setSelectedIds([])
      })
  }

  const deleteOne = (id) => {
    callApi({
      method: 'delete',
      url: `admin/documents/${id}`
    })
      .then(res => {
        if (!res) return
        getDocumentsByCategory()
      })
  }

  const displayRows = (datas, parentData) => {
    return datas?.map(doc => <Box key={doc.id}>
      <HStack align='center' justify='space-between' _hover={doc.type === 'folder' ? { background: '#f1f1f4', cursor: 'pointer' } : {}} onClick={doc.type === 'folder' ? () => handleFolderOpens(doc.id) : () => {}}>
        <HStack spacing='3'>
          <Icon as={folderOpens.includes(doc.id) ? RiArrowDownSFill : RiArrowRightSFill} fontSize="1.25rem" _hover={{ cursor: 'pointer' }} style={ doc.type === 'file' ? { opacity: 0 } : {}} />
          <Checkbox onChange={() => clickSelectedId(doc.id)} isChecked={selectedIds.includes(doc.id)} />
          <Icon as={doc.type === 'file' ? AiFillFile : folderOpens.includes(doc.id) ? AiFillFolderOpen : AiFillFolder} fontSize="1.25rem" color={folderOpens.includes(doc.id) ? 'brand.primary' : ''} />
          <Text>{doc.name}</Text>
        </HStack>
        <Box>
          {doc.type === 'file'
            ? <a href={doc?.file?.uri} target="_blank" rel="noreferrer">
                <Button variant="ghost" aria-label="View content" className='icon-btn' m='0' p='0'>
                  <Icon as={FiEye} fontSize="1.25rem" />
                </Button>
              </a>
            : <Button variant="ghost" aria-label="Add content" className='icon-btn' onClick={(e) => {
              e.stopPropagation()
              setAddItemItem(null)
              setAddItemDefaultFolder({ value: doc.id, label: doc.name })
              setAddItemOpen(true)
            }} m='0' p='0'>
                    <Icon as={FiPlus} fontSize="1.25rem" />
              </Button>
          }
          <Button variant="ghost" aria-label="Edit item" className='icon-btn' onClick={(e) => {
            e.stopPropagation()
            setAddItemItem(doc)
            setAddItemDefaultFolder(parentData)
            setAddItemOpen(true)
          }} m='0' p='0'>
            <Icon as={FiEdit2} fontSize="1.25rem" />
          </Button>
          <Button variant="ghost" aria-label="Delete item" className='icon-btn' onClick={(e) => {
            e.stopPropagation()
            deleteOne(doc.id)
          }} m='0' p='0'>
            <Icon as={FiTrash2} fontSize="1.25rem" />
          </Button>
        </Box>
      </HStack>
      {doc.type === 'folder' && doc?.files?.length && folderOpens.includes(doc.id)
        ? <Box pl='10'>
            {displayRows(doc.files, { value: doc.id, label: doc.name })}
          </Box>
        : ''
      }
    </Box>)
  }

  return loading
    ? <LoadingScreen />
    : <TabPanel>
        <Card p='0'>
          <AddItem open={addItemOpen} onClose={() => setAddItemOpen(false)} callback={getDocumentsByCategory} category={currentCategory} item={addItemItem} chantierId={chantier.id} defaultFolder={addItemDefaultFolder} />
          <Heading as='h5' size='sm' p='6' display='flex' direction={{ base: 'column', md: 'row' }} style={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
            <Input name='Rechercher...' value={search} setValue={setSearch} width='240px' />
            <HStack spacing='3'>
              {selectedIds?.length ? <Button data-variant='outline' onClick={deleteMultiple}>Supprimer la sélection ({selectedIds.length})</Button> : ''}
              <Button data-variant='solid' onClick={() => {
                setAddItemItem(null)
                setAddItemDefaultFolder(null)
                setAddItemOpen(true)
              }}>Ajouter un dossier ou un fichier</Button>
            </HStack>
          </Heading>
          <Divider mt="2" borderColor="rgba(0,0,0,.12)" />
          <Tabs variant={'unstyled'} size={'lg'} align='center'>
            <TabList mb='4'>
              {documentCategories.map(documentCategory => <Tab
                  key={documentCategory.key}
                  color={documentCategory.key === currentCategory ? 'brand.primary' : 'rgba(0,0,0,.54)'}
                  borderColor={documentCategory.key === currentCategory ? 'brand.primary' : ''}
                  borderBottomWidth='2px'
                  fontSize='14px'
                  fontWeight={'700'}
                  _hover={{ background: '#f1f1f4' }}
                  onClick={() => setCurrentCategory(documentCategory.key)}
                  width='100px'
                >{documentCategory.name}</Tab>
              )}
            </TabList>
            <Box align='left' p='5'>
              {displayRows(documentList)}
            </Box>
          </Tabs>
        </Card>
      </TabPanel>
}

export default Documents
