import { useState, useEffect } from 'react'

import moment from 'moment'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, useToast, Stack } from '@chakra-ui/react'

import { DateTimeInput } from 'components'

import { useAuth } from 'contexts'

const AssignOuvrier = ({ open, onClose, callback = () => {}, defaultValues, employeId = '' }) => {
  const { callApi } = useAuth()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [from, setFrom] = useState()
  const [to, setTo] = useState()

  useEffect(() => {
    setStartDate(defaultValues?.startDate ? moment(defaultValues?.startDate).format('YYYY-MM-DD HH:mm:ss') : null)
    setEndDate(defaultValues?.endDate ? moment(defaultValues?.endDate).format('YYYY-MM-DD HH:mm:ss') : null)
    setFrom(defaultValues?.from ?? '')
    setTo(defaultValues?.to ?? '')
  }, [defaultValues])

  const toast = useToast()

  const submit = () => {
    const ids = String(employeId ?? '').split(',')
    ids.forEach(id => {
      callApi({
        method: 'patch',
        url: `admin/timesheet/vehicle/employee/${id}`, // employeId
        data: Object.assign(
          {
            employee_default_id: id, // employeId
            end_date: endDate ? moment(endDate).format('YYYY-MM-DD HH:mm:ss') : '',
            start_date: startDate ? moment(startDate).format('YYYY-MM-DD HH:mm:ss') : '',
            from: from ?? null,
            to: to ?? null,
            new_index: 1,
            old_index: 1
          }
        )
      })
        .then(res => {
          if (!res) return
          toast({
            position: 'bottom-right',
            description: res?.data?.data?.message || 'Assignation effectué avec succès',
            status: 'success',
            duration: 5000,
            isClosable: false
          })
          callback(res)
          onClose()
        })
    })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>{String(employeId ?? '').split(',').length > 1 ? 'Assigner une équipe' : 'Assigner un ouvrier'}</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour assigner {String(employeId ?? '').split(',').length > 1 ? 'cette équipe' : 'cet ouvrier'}, entrez les informations correspondantes ci-dessous :</Text>
        <Stack spacing={5} my='5'>
          <DateTimeInput name='Début période' value={startDate} setValue={setStartDate} time={true} />
          <DateTimeInput name='Fin période' value={endDate} setValue={setEndDate} time={true} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>{"Assigner l'ouvrier"}</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AssignOuvrier
