import { useState } from 'react'

import moment from 'moment'

import { Button, Box } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

import Chantier from './components/chantier'
import AssignOuvrier from './components/modals/assignOuvrier'
import AssignVehicule from './components/modals/assignVehicule'

import { useFilters } from '../../contexts/filters'

const VehiculesContent = ({ datas, openTimesheetModal, callback }) => {
  const {
    filters: {
      fullSize,
      zoom,
      vehiculeDate: date,
      chantier: chantiersFilter
    },
    actions: {
      updateVehiculeDate
    }
  } = useFilters()

  const [assignOuvrierModalEmployeeId, setAssignOuvrierModalEmployeeId] = useState()
  const [assignOuvrierModalValues, setAssignOuvrierModalValues] = useState()

  const [assignVehiculeModalChantierId, setAssignVehiculeModalChantierId] = useState()
  const [assignVehiculeModalValues, setAssignVehiculeModalValues] = useState()

  const assignOuvrier = (ouvrierId, values) => {
    setAssignOuvrierModalEmployeeId(ouvrierId)
    setAssignOuvrierModalValues(values)
  }

  const assignVehicule = (chantierId, values) => {
    setAssignVehiculeModalChantierId(chantierId)
    setAssignVehiculeModalValues(values)
  }

  return <>
    <AssignOuvrier open={Boolean(assignOuvrierModalEmployeeId)} onClose={() => { setAssignOuvrierModalEmployeeId(null); setAssignOuvrierModalValues(null) }} defaultValues={assignOuvrierModalValues} employeId={assignOuvrierModalEmployeeId} callback={callback} />
    <AssignVehicule open={Boolean(assignVehiculeModalChantierId)} onClose={() => { setAssignVehiculeModalChantierId(null); setAssignVehiculeModalValues(null) }} defaultValues={assignVehiculeModalValues} chantierId={assignVehiculeModalChantierId} callback={callback} />
    <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} borderY='1px solid #E2E8F0' height='82px'>
      <Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', width: '310px' }} px='2'>
        <Button data-variant='outline' onClick={() => updateVehiculeDate(-1)} style={{ padding: 0, borderRadius: '4px' }}>
          <ChevronLeftIcon size="22" />
        </Button>
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#8186aa' }}>{ moment(date).format('DD MMM YYYY') }</Box>
        <Button data-variant='outline' onClick={() => updateVehiculeDate(1)} style={{ padding: 0, borderRadius: '4px' }}>
          <ChevronRightIcon size="22" />
        </Button>
      </Box>
    </Box>
    <Box style={{
      transform: `scale(${100 - zoom}%)`,
      WebkitTransform: `scale(${100 - zoom}%)`,
      transformOrigin: 'top left',
      width: `${100 / ((100 - zoom) / 100)}%`,
      height: fullSize ? `${100 / ((100 - zoom) / 100)}%` : `calc(${800 / ((100 - zoom) / 100)}px - 82px)`,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'row',
      overflow: 'auto'
    }}>
      {datas
        ?.filter(chantier => chantiersFilter.value === 'all' || chantiersFilter.value === chantier.id)
        // ?.filter(chantier => responsable.value === 'all' || chantier.constructionActors.flatMap(({ constructionActorReferent }) => constructionActorReferent).map(c => c.id).includes(responsable.value))
        ?.map(chantier => <Chantier key={chantier.id} chantier={chantier} openTimesheetModal={openTimesheetModal} assignOuvrier={assignOuvrier} assignVehicule={assignVehicule} callback={callback} />)
      }
    </Box>
  </>
}

export default VehiculesContent
