import { createRoot } from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider, NotificationsProvider, ModalsProvider, DatasProvider } from 'contexts/provider'

import { CssBaseline } from '@mui/material'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

import './index.css'

import ReactGA from 'react-ga4'
import * as Sentry from '@sentry/react'

ReactGA.initialize(process.env.REACT_APP_GA_ID)
if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.Replay()],
    // Performance Monitoring
    tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// document.body.style.zoom = '85%'

const container = document.getElementById('root')

const root = createRoot(container)
const theme = extendTheme({
  colors: {
    brand: {
      primary: '#ff5464',
      text: '#545866',
      sidebar: '#f1f1f4'
    }
  },
  fonts: {
    body: 'DM Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif',
    heading: 'DM Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen-Sans,Ubuntu,Cantarell,Helvetica Neue,sans-serif'
  }
})

const themeMui = createTheme(
  {
    palette: {
      primary: {
        light: '#ff5464',
        main: '#ff5464',
        dark: '#ff5464'
      // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        light: '#ff5464',
        main: '#ff5464',
        dark: '#ff5464'
      // contrastText: will be calculated to contrast with palette.secondary.main
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536
      }
    }
  },
  {
    components: {
      MuiLocalizationProvider: {
        defaultProps: {
          localeText: {
            cancelButtonLabel: 'Annuler'
          }
        }
      }
    }
  }
)

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={themeMui}>
      <ChakraProvider theme={theme} className="main">
        <AuthProvider>
          <DatasProvider>
            <NotificationsProvider>
              <ModalsProvider>
                <BrowserRouter>
                  <CssBaseline />
                  <App />
                </BrowserRouter>
              </ModalsProvider>
            </NotificationsProvider>
          </DatasProvider>
        </AuthProvider>
      </ChakraProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
console.log(reportWebVitals())
