import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Text } from '@chakra-ui/react'

import { Card, LoadingScreen } from 'components'

import { useAuth } from 'contexts'

import AddEmployee from 'pages/admin/Employees/components/modals/AddEmployee'
import AddEngin from 'pages/admin/Engins/components/modals/AddEngin'
import AddVehicule from 'pages/admin/Vehicules/components/modals/AddVehicule'
import AddChantier from 'pages/admin/Chantiers/components/modals/AddChantier'

const SearchCardHeader = ({ title, subtitle1, subtitle2, type, onClick }) => {
  return <Box style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }}>
    <Box onClick={onClick} _hover={{ cursor: 'pointer' }}>
      <Text fontWeight='bold'>{title}</Text>
      <Text fontSize='12px'>{subtitle1}</Text>
      <Text fontSize='12px'>{subtitle2}</Text>
    </Box>
    <Box>
      <Text fontWeight='bold' color='brand.primary'>{type}</Text>
    </Box>
  </Box>
}

const Search = () => {
  const { callApi } = useAuth()
  const { search } = useParams()

  const [loading, setLoading] = useState(false)
  const [datas, setDatas] = useState([])

  const [dataToOpen, setDataToOpen] = useState()
  const [dataToOpenType, setDataToOpenType] = useState()

  useEffect(() => {
    if (search) return

    setLoading(true)
    callApi({
      method: 'GET',
      url: `admin/search${search}`,
      catchCallback: () => setLoading(false)
    })
      .then(res => {
        if (!res) return
        setDatas(res?.data?.data ?? [])
        setLoading(false)
      })
  }, [search])

  return (
		<Box>
      <AddChantier open={dataToOpenType === 'chantier'} onClose={() => { setDataToOpenType(); setDataToOpen() }} chantier={dataToOpen} viewOnly />
      <AddEmployee open={dataToOpenType === 'employee'} onClose={() => { setDataToOpenType(); setDataToOpen() }} employee={dataToOpen} viewOnly />
      <AddEngin open={dataToOpenType === 'engin'} onClose={() => { setDataToOpenType(); setDataToOpen() }} engin={dataToOpen} viewOnly />
      <AddVehicule open={dataToOpenType === 'vehicule'} onClose={() => { setDataToOpenType(); setDataToOpen() }} vehicule={dataToOpen} viewOnly />
			{ loading
			  ? <LoadingScreen />
			  : <Box>
            <Box pl='2' mb='6' display='flex'>
              <Text fontSize='22px'>{!datas.length ? 'Aucun résultat pour la recherche : ' : 'Résultats pour : '}</Text>
              <Text fontWeight='bold' ml='2' fontSize='22px'>{search}</Text>
            </Box>
            {datas?.map(chantier => <Card key={chantier.id}>
              <SearchCardHeader title={chantier.name} subtitle1={chantier.address} subtitle2={`${chantier.city} ${chantier.postalCode}`} type='Chantier' onClick={() => {
                setDataToOpen(chantier)
                setDataToOpenType('chantier')
              }} />
              {chantier?.timesheetScheduleVehicles?.length
                ? chantier.timesheetScheduleVehicles.map(({ vehicle, peoples }) => <Card key={vehicle.id} mt='6'>
                    <SearchCardHeader title={vehicle.brand} subtitle1={vehicle.immatriculation} subtitle2={vehicle.year} type='Véhicule' onClick={() => {
                      setDataToOpen(vehicle)
                      setDataToOpenType('vehicule')
                    }} />
                    {peoples?.length
                      ? peoples.map(people => <Card key={people.id} mt='6'>
                          <SearchCardHeader title={people.name} subtitle1={`${people.phone} ${people.email}`} subtitle2={vehicle.address} type='Employé' onClick={() => {
                            setDataToOpen(people)
                            setDataToOpenType('employee')
                          }}/>
                        </Card>)
                      : ''
                    }
                  </Card>)
                : ''}
                {chantier?.timesheetSchedules?.length
                  ? chantier?.timesheetSchedules.filter(e => Boolean(e.employee))?.map(timesheet => <Card key={timesheet.id} mt='6'>
                    <SearchCardHeader title={timesheet.employee.name} subtitle1={`${timesheet.employee.phone} ${timesheet.employee.email}`} subtitle2={`Du ${timesheet.startFull} au ${timesheet.endFull}`} type='Planning ouvrié' onClick={() => {
                      setDataToOpen(timesheet.employee)
                      setDataToOpenType('chantier')
                    }} />
                  </Card>)
                  : ''
                }
                {chantier?.timesheetSchedules?.length
                  ? chantier?.timesheetSchedules.filter(e => Boolean(e.machine))?.map(timesheet => <Card key={timesheet.id} mt='6'>
                    <SearchCardHeader title={timesheet.machine.brand} subtitle1={timesheet.machine.immatriculation} subtitle2={timesheet.machine.year} type='Engin' onClick={() => {
                      setDataToOpen(timesheet.machine)
                      setDataToOpenType('engin')
                    }}/>
                  </Card>)
                  : ''
                }
            </Card>)}
          </Box>
			}
		</Box>
  )
}

export default Search
