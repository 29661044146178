import { useState, useEffect, useMemo } from 'react'

import { HStack, Box } from '@chakra-ui/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Content from './Content'
import Sidebar from './Sidebar'
import PlanVehiculeModal from '../components/modals/AddVehicule'
import AddEmployee from 'pages/admin/Employees/components/modals/AddEmployee'
import AddVehicule from 'pages/admin/Vehicules/components/modals/AddVehicule'

import { useAuth } from 'contexts'
import { useFilters } from '../../contexts/filters'

const Vehicules = () => {
  const { callApi } = useAuth()
  const {
    filters: {
      fullSize,
      search,
      planningType,
      vehiculeDate: date
    }
  } = useFilters()

  const [contentDatas, setContentDatas] = useState([])

  const [timesheetDefaultValues, setTimesheetDefaultValues] = useState()
  const [timesheetOpen, setTimesheetOpen] = useState(false)
  const [employeeModal, setEmployeeModal] = useState()
  const [vehiculeModal, setVehiculeModal] = useState()

  const openTimesheetModal = useMemo(() => (chantierId) => {
    setTimesheetDefaultValues()
    setTimesheetOpen(true)
  }, [])

  const [timesheetVehiculeIds, setTimesheetVehiculeIds] = useState([])
  const [timesheetEmployeeIds, setTimesheetEmployeeIds] = useState([])
  const getVehiculeTimesheet = () =>
    callApi({
      method: 'get',
      url: `admin/timesheet/vehicle?search=${search ?? ''}&date=${date ?? ''}`
    })
      .then(res => {
        const { chantiers = [], vehicules = [], employees = [] } = res?.data?.data
        setTimesheetVehiculeIds(vehicules.map(v => v.vehiculeId))
        setTimesheetEmployeeIds(employees.map(e => e.employeeId))
        const vehiculesEmployeesIds = vehicules.map(v => v.employeeId)
        const newDatas = chantiers.map(chantier => {
          const chantierVehicules = []
          const chantierEmployeesWithVehicule = employees.filter(({ employeeId, constructionSiteId }) => constructionSiteId === chantier.id && vehiculesEmployeesIds.includes(employeeId))
          const chantierEmployeesWithoutVehicule = employees.filter(({ employeeId, constructionSiteId }) => constructionSiteId === chantier.id && !vehiculesEmployeesIds.includes(employeeId))

          vehicules.filter(({ constructionSiteId }) => constructionSiteId === chantier.id).forEach(vehicule => {
            if (!chantierVehicules[vehicule.timesheetVehiculeId]) {
              chantierVehicules[vehicule.timesheetVehiculeId] = {
                ...vehicule,
                employees: []
              }
            }
            const toAdd = chantierEmployeesWithVehicule.filter(({ employeeId }) => employeeId === vehicule.employeeId)[0]
            if (toAdd) {
              if (vehicule.employeeIsConductor) {
                chantierVehicules[vehicule.timesheetVehiculeId].employees.unshift(toAdd)
              } else {
                chantierVehicules[vehicule.timesheetVehiculeId].employees.push(toAdd)
              }
            }
          })

          return {
            ...chantier,
            vehicules: chantierVehicules.filter(cv => cv),
            noVehiculesEmployees: chantierEmployeesWithoutVehicule
          }
        })
        setContentDatas(newDatas)
      })

  const [fullEmployees, setFullEmployees] = useState([])
  const getEmployees = () => {
    callApi({
      method: 'get',
      url: 'admin/employee?page=1&perPage=1000&sort[id]=desc'
    })
      .then(res => {
        if (!res) return
        setFullEmployees(res?.data?.data ?? [])
      })
  }

  const [fullVehicules, setFullVehicules] = useState([])
  const getVehicules = () => {
    callApi({
      method: 'get',
      url: 'admin/vehicles?page=1&perPage=200&sort[id]=desc&search='
    })
      .then(res => {
        if (!res) return
        setFullVehicules(res?.data?.data ?? [])
      })
  }

  useEffect(() => {
    getVehicules()
    getEmployees()
  }, [])

  useEffect(() => {
    if (planningType === 'vehicule') { getVehiculeTimesheet() }
  }, [date, planningType])

  return <DndProvider backend={HTML5Backend}>
    <HStack height='100%' align='flex-start'>
      <PlanVehiculeModal open={timesheetOpen} onClose={() => setTimesheetOpen(false)} defaultValues={timesheetDefaultValues} callback={getVehiculeTimesheet} />
      <AddEmployee open={Boolean(employeeModal)} onClose={() => setEmployeeModal(null)} employee={employeeModal} viewOnly />
      <AddVehicule open={Boolean(vehiculeModal)} onClose={() => setVehiculeModal(null)} vehicule={vehiculeModal} viewOnly />
      <Box width='80%' height={fullSize ? '100%' : '800px'}>
        <Content datas={contentDatas} openTimesheetModal={openTimesheetModal} callback={getVehiculeTimesheet} />
      </Box>
      <Box width='20%' height={fullSize ? 'calc(100% + 35px)' : '800px'} style={{ marginInlineStart: 0 }} boxShadow='-6px 83px 7px 0px #e2e8f0'>
        <Sidebar datas={{
          ouvriers: fullEmployees.filter(e => !timesheetEmployeeIds.includes(e.id)),
          vehicules: fullVehicules.filter(v => !timesheetVehiculeIds.includes(v.id))
        }} openEmployeeModal={setEmployeeModal} openVehiculeModal={setVehiculeModal} openTimesheetModal={openTimesheetModal} callback={getVehiculeTimesheet} />
      </Box>
    </HStack>
  </DndProvider>
}

export default Vehicules
