import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Tabs, TabList, Tab, TabPanels, Heading, Spacer } from '@chakra-ui/react'

import Infos from './components/tabs/Infos'
import Documents from './components/tabs/Documents'
import Interventions from './components/tabs/Interventions'

import { Card, LoadingScreen } from 'components'

import { useAuth } from 'contexts'

const Chantier = () => {
  const { chantierId } = useParams()
  const navigate = useNavigate()
  const [chantier, setChantier] = useState()
  const [loading, setLoading] = useState(true)
  const { callApi } = useAuth()

  useEffect(() => {
    if (!chantierId) navigate('/admin/construction-sites')
    setLoading(true)
    callApi({
      method: 'get',
      url: `admin/construction-sites/${chantierId}`,
      catchCallback: () => navigate('/admin/construction-sites')
    })
      .then(res => {
        if (!res) return
        setChantier(res?.data?.data)
        setLoading(false)
      })
  }, [chantierId])

  return loading
    ? <LoadingScreen />
    : <Card p='0'>
        <Tabs size='lg'>
          <TabList>
            <Heading as='h1' size='md' p='6'>{chantier?.name}</Heading>
            <Spacer flex='0.5' />
            <Tab _hover={{ background: '#f1f1f4' }} _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }} fontSize='14px' fontWeight={'500'} width='200px'>INFOS</Tab>
            <Tab _hover={{ background: '#f1f1f4' }} _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }} fontSize='14px' fontWeight={'500'} width='200px'>DOCUMENTS</Tab>
            <Tab _hover={{ background: '#f1f1f4' }} _selected={{ color: 'brand.primary', borderColor: 'brand.primary' }} fontSize='14px' fontWeight={'500'} width='200px'>INTERVENTIONS</Tab>
          </TabList>
          <TabPanels>
            <Infos chantier={chantier} />
            <Documents chantier={chantier} />
            <Interventions chantier={chantier} />
          </TabPanels>
        </Tabs>
      </Card>
}

export default Chantier
