import { useState } from 'react'
import { Link } from 'react-router-dom'

import { Button, Divider, Heading, HStack, Stack, Text, useToast } from '@chakra-ui/react'
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'

import { useAuth } from 'contexts'

import { isEmailValid } from 'helpers/utils'

import { Input } from 'components'

const Login = () => {
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const toast = useToast()
  const handleClick = () => setShowPassword(prevShowPassword => !prevShowPassword)
  const { login } = useAuth()

  const validate = () => {
    if (email.length === 0) {
      toast({
        position: 'bottom-right',
        description: 'Veuillez entrer votre adresse mail',
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    if (!isEmailValid(email.trim())) {
      toast({
        position: 'bottom-right',
        description: "Le format de l'adresse mail n'est pas valide",
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    if (password.length === 0) {
      toast({
        position: 'bottom-right',
        description: 'Veuillez entrer votre mot de passe',
        status: 'error',
        duration: 5000,
        isClosable: false
      })

      return false
    }

    return true
  }

  const submit = () => {
    if (validate()) {
      login({
        email,
        password
      })
    }
  }

  return (
		<Stack spacing="8">
			<Stack spacing="6">
				<Stack
					spacing={{
					  base: '2',
					  md: '3'
					}}
					textAlign="center"
				>
					<Heading fontSize={'1.25rem !important'} textAlign="left">
						Connexion
					</Heading>
					<Text color="muted" textAlign="left">
						Pour vous connecter, entrez votre adresse email & votre mot de passe ci-dessous :
					</Text>
				</Stack>
			</Stack>
			<Stack spacing="6">
				<Stack spacing="5">
					<Input name='Email' value={email} setValue={setEmail} type='email' />
        	<Input name='Mot de passe' value={password} setValue={setPassword} type={showPassword ? 'text' : 'password'} icon={showPassword ? <ViewIcon /> : <ViewOffIcon />} iconClick={handleClick} />
				</Stack>
				<HStack>
					<Link to="/guest/password/request">
						<Button size="sm" bg="none" _hover={{ color: 'brand.primary' }}>
							Mot de passe oublié ?
						</Button>
					</Link>
				</HStack>
				<Divider mt="2" mb="6px" borderColor="rgba(0,0,0,.12)" />
			</Stack>
			<Button data-variant='solid' onClick={submit}>
				Connexion
			</Button>
		</Stack>
  )
}

export default Login
