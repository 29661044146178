import { useState } from 'react'

import { Box } from '@chakra-ui/react'
import { SketchPicker } from 'react-color'

const ColorInput = ({ value, setValue }) => {
  const [open, setOpen] = useState(false)

  return <>
    <Box width='100px' height='40px' borderRadius='8px' border='1px solid #a6a6a6' cursor='pointer' background={value || '#fe5464'} onClick={() => setOpen(prev => !prev)} />
    {open && <Box position='absolute' right='30px' zIndex='1000'>
      <SketchPicker
        color={value}
        onChangeComplete={(v) => setValue(v.hex)}
        presetColors={['#20B5A3', '#EF474C', '#FCBB3D', '#5A1951', '#5FB96E', '#34D3CB']}
      />
    </Box>}
  </>
}

export default ColorInput
