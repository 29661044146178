import { useState } from 'react'

import { Box, Button, Icon, Text } from '@chakra-ui/react'
import { MdCheck, MdClose } from 'react-icons/md'
import { WarningTwoIcon, InfoIcon } from '@chakra-ui/icons'

import 'pages/admin/Notifications/Notifications.css'

import AddVehicule from 'pages/admin/Vehicules/components/modals/AddVehicule'
import AddEngin from 'pages/admin/Engins/components/modals/AddEngin'
import AddEmployee from 'pages/admin/Employees/components/modals/AddEmployee'
import AddInvoice from 'pages/admin/Invoices/components/modals/AddInvoice'

const Alert = ({ notification: { type, context, content, notifiable }, onClose, onCheck }) => {
  const [modalOpen, setModalOpen] = useState('')
  const [modalData, setModalData] = useState()

  return <Box>
    <AddVehicule open={modalOpen === 'document_vehicle'} onClose={() => setModalOpen('')} vehicule={modalData} />
    <AddEngin open={modalOpen === 'document_machine'} onClose={() => setModalOpen('')} engin={modalData} />
    <AddEmployee open={modalOpen === 'document_employee'} onClose={() => setModalOpen('')} employee={modalData} />
    <AddInvoice open={modalOpen === 'invoice'} onClose={() => setModalOpen('')} invoice={modalData} />
    <Box borderLeft={type === 'error' ? '8px solid #de4433' : '8px solid #de4'} className='box'>
      {type === 'error' ? <Icon as={WarningTwoIcon} className='alert-icon' /> : <Icon as={InfoIcon} className='info-icon' />}
      <Box>
        <Text textAlign='start' fontSize='16px' mb={'9px'}>{content}</Text>
        <Button className='alert-button' ml={'10px'} onClick={() => {
          setModalOpen(context)
          setModalData(notifiable.model)
        }}>
          Voir
        </Button>
        <Button onClick={onCheck} className='alert-button' ml={'10px'} rightIcon={<MdCheck className='check-icon' />}>
          Marquer comme lu
        </Button>
        <Button className='alert-button' ml={'10px'} onClick={onClose} rightIcon={<MdClose className='check-icon' />}>Fermer</Button>
      </Box>
    </Box>
  </Box>
}

export default Alert
