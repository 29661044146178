import { useMemo } from 'react'
import { HStack, Avatar, Box, Text } from '@chakra-ui/react'

import { useDrag } from 'react-dnd'

import { useDatas } from 'contexts'

const Ouvrier = ({ ouvrier, openEmployeeModal, dragAllow = true }) => {
  const [{ opacity }, drag] = ouvrier && dragAllow
    ? useDrag({
      type: 'ouvrier',
      item: { employee: ouvrier },
      collect: monitor => ({
        opacity: monitor.isDragging() ? 0.4 : 1
      })
    })
    : [{ opacity: 1 }, null]

  const { datas: { positions } } = useDatas()

  const ouvrierPotisition = useMemo(() => {
    return ouvrier.employeePositionId ? positions.filter(p => p.value === ouvrier.employeePositionId)[0]?.label : '-'
  }, [ouvrier])

  return <HStack key={ouvrier.id} position='relative' mb='3' p='3' width='100%' border='1px solid #D2D8E0' borderRadius='8px' onClick={openEmployeeModal ? () => openEmployeeModal(ouvrier) : () => {}} ref={drag} style={{ opacity }}>
    <Avatar boxSize='8' size='xs' name={ouvrier.fullName ?? `${ouvrier.firstName} ${ouvrier.lastName}` ?? '-'} src={ouvrier?.picture?.uri ?? null} />
    <Box align='left'>
      <Text mt='0.5' fontSize='13px'>{ouvrier.fullName ?? `${ouvrier.first_name} ${ouvrier.last_name}` ?? '-'}</Text>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        {/* <Text mt='-1' fontSize='11px'>{ouvrier.employeePosition?.name ?? '-'}</Text> */}
        <Text mt='-1' fontSize='11px'>{ouvrierPotisition}</Text>
        <span style={{ width: '8px', height: '8px', background: ouvrier?.isAvailable, borderRadius: '50%', marginLeft: '5px' }} />
      </Box>
    </Box>
  </HStack>
}

export default Ouvrier
