import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast } from '@chakra-ui/react'

import { Input, Select } from 'components'

import { useAuth } from 'contexts'

const AddItem = ({ open, onClose, item, chantierId, category, callback = () => {}, defaultFolder }) => {
  const { callApi } = useAuth()

  const toast = useToast()

  const [noFolderFiles, setNoFolderFiles] = useState([])
  const [filesToAddIds, setFilesToAddIds] = useState([])

  const [name, setName] = useState(undefined)
  const [type, setType] = useState(undefined)
  const typeOptions = [{
    value: 'file',
    label: 'Fichier'
  }, {
    value: 'folder',
    label: 'Dossier'
  }, {
    value: 'picture',
    label: 'Image'
  }]
  const [parentFolder, setParentFolder] = useState(undefined)
  const [parentFolderOptions, setParentFolderOptions] = useState([])
  const [files, setFiles] = useState('')

  useEffect(() => {
    setName(item?.name ?? '')
    setParentFolder(defaultFolder ?? null)
    setFilesToAddIds([])
    setFiles('')

    const getParentFolder = () => {
      callApi({
        method: 'get',
        url: `admin/search/models/folder?search=&filter=${chantierId}&type=${category}`
      })
        .then(res => setParentFolderOptions(res?.data?.data?.map(data => ({ value: data.id, label: data.label })) || []))
    }

    const getNoFolderFiles = () => {
      callApi({
        method: 'get',
        url: `admin/search/models/document-without-folder?search=&filter=${chantierId}&type=${category}`
      })
        .then(res => setNoFolderFiles(res?.data?.data?.map(data => ({ value: data.id, label: data.label })) || []))
    }

    getParentFolder()
    getNoFolderFiles()
  }, [item, defaultFolder])

  const submit = () => {
    callApi({
      method: item ? 'put' : 'post',
      url: item ? `admin/documents/${item.id}` : 'admin/documents',
      formData: type?.value === 'file' || type?.value === 'picture',
      data: Object.assign(
        {
          category,
          construction_site_id: chantierId,
          name
        },
        type?.value === 'file' || type?.value === 'picture' ? { media: files } : {},
        !item ? { type: type?.value ?? null, children: filesToAddIds } : { initial_category: category },
        parentFolder?.value ? { parent_id: parentFolder?.value } : {}
      )
    })
      .then(res => {
        toast({
          position: 'bottom-right',
          description: res?.data?.message || item ? `${item.name} modifié avec succès` : `${name} ajouté avec succès`,
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {item ? `Modifier le dossier ou fichier ${item.name}` : 'Créer un nouveau dossier ou ajouter un fichier'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>
          {item ? `Pour modifier le dossier ou fichier ${item.name}, entrez les informations ci-dessous :` : 'Pour créer un nouveau dossier ou ajouter un fichier, entrez les informations ci-dessous :'}
        </Text>
        <Stack spacing={5} my='5'>
          {!item && <Select name='Type *' value={type} setValue={setType} options={typeOptions} />}
          {!item && <Input name='Catégorie *' value={category} setValue={null} disabled={true} />}
          {(type?.value === 'folder' || item) && <Input name='Libellé' value={name} setValue={setName} />}
          <Select name='Dossier parent' value={parentFolder} setValue={setParentFolder} options={parentFolderOptions} isDisabled={Boolean(defaultFolder)} />
          {!item && type?.value === 'folder' && <Select name='Ajouter des documents' value={filesToAddIds} setValue={setFilesToAddIds} options={noFolderFiles} isMulti />}
          {!item && (type?.value === 'picture' || type?.value === 'file') && <Input name='Ajouter des fichiers' value={files} setValue={setFiles} type='file' multiple />}
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>{item ? 'Modifier' : 'Ajouter'}</Button>
        <Button data-variant='outline' onClick={onClose}>Annuler</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddItem
