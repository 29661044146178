import { createContext, useContext, useState, useEffect } from 'react'

import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { useToast } from '@chakra-ui/react'

const initialValues = {
  login: () => {},
  logout: () => {},
  user: undefined,
  setUser: () => {},
  authToken: undefined,
  role: undefined,
  isConnected: false,
  isLoading: true,
  callApi: () => {}
}

const AuthContext = createContext(initialValues)

const authorizedRoles = ['admin', 'super-admin', 'cdt', 'cdt-r']// 'cdc'

function dataToFormFormat (object) {
  const formData = new FormData()
  Object.keys(object).forEach(key => {
    if (Array.isArray(object[key])) {
      object[key].forEach((item, index) => {
        typeof item === 'object' && item?.type !== 'blob' //! (item instanceof Blob)
          ? Object.keys(item).forEach(nkey => formData.append(`${key}[${index}][${nkey}]`, object[key][index][nkey]))
          : item?.type === 'blob'
            ? formData.append(`${key}[]`, item.blob, item.name)
            : formData.append(`${key}[]`, item)// item.forEach(val => formData.append(`${key}[${index}]`, val))
      })
    } else {
      object[key]?.type === 'blob'
        ? formData.append(key, object[key].blob, object[key].name)
        : formData.append(key, object[key])
    }
  })
  return formData
}

const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState()
  const [authToken, setAuthToken] = useState()
  const [role, setRole] = useState()
  const toast = useToast()

  const getRole = (user) => user?.roles.filter((r) => r.name === 'super-admin').length ? 'super-admin' : user?.roles.filter((r) => authorizedRoles.includes(r.name)).length ? 'admin' : logout()

  const isAuthorized = (role) => authorizedRoles.includes(role)

  const refreshToken = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}guest/token/refresh`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      )

      setAuthToken(res.data.access_token)
    } catch (error) {
      if (error.response.data.message === 'The token has been blacklisted') {
        logout()
      }
    }
  }

  const tokenHasExpired = () => {
    const decodedToken = jwtDecode(authToken)
    return Date.now() >= decodedToken.exp * 1000 + 1000
  }

  const displayApiError = (err) => {
    if (!user && err?.response?.data?.status === 'Token is Expired') return
    const errors = !user ? ['Les identifiants utilisés ne correspondent à aucun compte enregistré, veuillez vérifier votre email et mot de passe ou contacter votre administrateur.'] : typeof err?.response?.data === 'string' ? [err?.response?.data] : err?.response?.data?.errors ? Object.values(err?.response?.data?.errors) : [413, 0].includes(err?.response?.status) ? ['Le fichier est trop volumineux (> 5mo).'] : ["Une erreur est survenue lors de l'appel au service"]

    errors.forEach(errorByFied => {
      (Array.isArray(errorByFied) ? errorByFied : [errorByFied]).forEach(error => {
        toast({
          position: 'bottom-right',
          description: error,
          status: 'error',
          duration: 5000,
          isClosable: false
        })
      })
    })
  }

  const callApi = async ({ url, method, data, needAuth = true, formData, catchCallback = () => {}, preventDisplayApiError = false }) => {
    if (!url || !method) return
    if (needAuth && tokenHasExpired()) await refreshToken()
    return await axios({
      method,
      url: `${process.env.REACT_APP_API_URL}${url}`,
      data: formData ? dataToFormFormat(data) : data,
      headers: Object.assign(
        needAuth ? { Authorization: `Bearer ${authToken}` } : {},
        formData ? { 'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarylMDDBGJvvP9OW4O0' } : {}
      )
    })
      .catch(err => {
        if (!preventDisplayApiError) {
          displayApiError(err)
        }

        catchCallback(err)
      })
  }

  useEffect(() => {
    if (localStorage.getItem('USER_TOKEN_KEY')) {
      setAuthToken(localStorage.getItem('USER_TOKEN_KEY'))
    } else setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!user && authToken) {
      getUserFromToken(authToken)
    }
  }, [authToken])

  const getUserFromToken = async (token) => {
    if (!token) return

    callApi({
      method: 'get',
      url: 'admin/me',
      catchCallback: () => setIsLoading(false)
    })
      .then(async (res) => {
        const role = getRole(res?.data?.data)
        if (!isAuthorized(role)) {
          throw new Error('You are not authorized here')
        }

        setUser(res.data.data)
        setRole(role)
        setIsLoading(false)
      })
  }

  const login = (data) => {
    callApi({
      method: 'post',
      url: 'guest/login',
      data,
      needAuth: false
    })
      .then(res => {
        if (!res) return
        setAuthToken(res.data.access_token)
        localStorage.setItem('USER_TOKEN_KEY', res.data.access_token)
      })
  }

  const logout = () => {
    setUser(undefined)
    localStorage.clear()
  }

  return (
    <AuthContext.Provider value={{
      login,
      logout,
      user,
      setUser,
      authToken,
      role,
      isConnected: Boolean(user),
      isLoading,
      callApi
    }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  return useContext(AuthContext)
}

export { AuthProvider, useAuth }
