import { useState, useEffect } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, Box, useToast } from '@chakra-ui/react'

import { Select, DateTimeInput } from 'components'

import { useAuth, useDatas } from 'contexts'

import moment from 'moment'

const MyExport = ({ open, onClose }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const { datas: { companies: companiesOptions, chantiers: chantierOptions, employees: employeesOptions } } = useDatas()

  const [chantier, setChantier] = useState()

  const [durationOptions] = useState([
    { value: 'week', label: 'semaine' },
    { value: 'month', label: 'mois' }
  ])
  const [duration, setDuration] = useState(durationOptions[0])

  const [startDate, setStartDate] = useState(moment().startOf('isoWeek'))
  const [endDate, setEndDate] = useState(null)

  useEffect(() => {
    if (startDate) {
      setEndDate(moment(startDate).endOf(duration.value === 'week' ? 'isoWeek' : 'month'))
    }
  }, [startDate])

  useEffect(() => {
    if (startDate) {
      setStartDate(moment(startDate).startOf(duration.value === 'week' ? 'isoWeek' : 'month'))
      setEndDate(moment(startDate).endOf(duration.value === 'week' ? 'isoWeek' : 'month'))
    }
  }, [duration])

  const [employees, setEmployees] = useState([])

  const [companies, setCompanies] = useState([])

  const [submitting, setSubmitting] = useState(false)

  const submit = (asyncParameter) => {
    if (!startDate) {
      [!startDate ? 'Le champ date à exporter est obligatoire' : ''].filter(e => e?.length).forEach(error => toast({
        position: 'bottom-right',
        description: error,
        status: 'error',
        duration: 5000,
        isClosable: false
      }))
      return
    }
    setSubmitting(true)
    callApi({
      method: 'get',
      // url: 'admin/clocking/export?start_date=2022-08-29&end_date=2022-09-04&construction_site_id=11&employees[]=2,&employees[]=1&companies[]=1'
      // http://localhost:3000/admin/clocking/export?start_date=2022-08-29&end_date=2022-09-04&construction_site_id=11&employees[]=2,&employees[]=1&companies[]=1
      url: `admin/clocking/export?async=${asyncParameter ? 1 : 0}&start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&construction_site_id=${chantier?.value ?? ''}${employees?.length ? employees.map(e => `&employees[]=${e.value}`).join('') : ''}${companies?.length ? companies.map(c => `&companies[]=${c.value}`).join('') : ''}`,
      catchCallback: err => {
        if (err.response.status === 504) {
          toast({
            position: 'bottom-right',
            description: "L'export par téléchargement est trop lourd, veuillez utiliser l'envoie par email",
            status: 'error',
            duration: 5000,
            isClosable: false
          })
        } else {
          toast({
            position: 'bottom-right',
            description: err.response.data.message,
            status: 'error',
            duration: 5000,
            isClosable: false
          })
        }
        setSubmitting(false)
      },
      preventDisplayApiError: true
    })
      .then(result => {
        if (!result) {
          return
        }

        const toCsv = (arr) => {
          return arr.reduce(function (csvString, row) {
            csvString += row.map(r => (r ?? '').toString().replace('.', ',')).join(';')
            csvString += '\r\n' // ";";//"\n";
            return csvString
          }, '')
        }

        if (result.data.data) {
          const csv = 'data:text/csv;charset=utf-16,\uFEFF' + encodeURIComponent(`${result.data.data.headings.join(';')}\r\n` + toCsv(result.data.data.datas))
          const link = document.createElement('a')
          link.setAttribute('href', csv)
          link.setAttribute('download', `${result.data.data.title}.csv`)
          link.click()
        } else {
          toast({
            position: 'bottom-right',
            description: result.data.message,
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        }

        onClose()
      })
      .finally(() => setSubmitting(false))
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>{"Exporter les feuilles d'heures"}</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>{"Exportez toutes les feuilles d'heures pour ce chantier, ou sélectionnez un ouvrier ci-dessous"}</Text>
        <Stack spacing={5} my='5'>
          <Select name='Chantier concerné' value={chantier} setValue={setChantier} options={chantierOptions} />
          <Select name={'Exporter par'} value={duration} setValue={setDuration} options={durationOptions} />
          {duration.value === 'week' && (
            <Box style={duration.value === 'week' ? { display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-around' } : { display: 'none' }}>
              <DateTimeInput name='Du' value={startDate} setValue={setStartDate} shouldDisableDate={(date) => date.day() !== 1} />
              <DateTimeInput name='Au' value={endDate} setValue={setEndDate} minDate={startDate} shouldDisableDate={(date) => date.day() !== 0} />
            </Box>
          )}
          {duration.value === 'month' && (
            <Box style={{ display: duration.value === 'month' ? 'initial' : 'none' }}>
              <DateTimeInput name='Mois à exporter' format='MMMM' value={startDate} setValue={setStartDate} views={['year', 'month']} openTo="month" />
            </Box>
          )}
          <Select name='Ouvriers' value={employees} setValue={setEmployees} options={employeesOptions} isMulti />
          <Select name='Société concernée' value={companies} setValue={setCompanies} options={companiesOptions} isMulti />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button isLoading={submitting} data-variant='solid' onClick={() => submit(false)}>Télécharger</Button>
        <Button ml={4} isLoading={submitting} data-variant='outline' onClick={() => submit(true)}>Envoyer par mail</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default MyExport
