import { useRef, useState } from 'react'

import { Box, Text, Icon, Button, useToast, Avatar, Checkbox } from '@chakra-ui/react'
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi'

import { useAuth, useDatas } from 'contexts'

import { Table } from 'components'
import AddEmployee from './components/modals/AddEmployee'
import ConfirmDelete from '../../../contexts/modals/components/confirmDelete'

const columns = [
  {
    key: 'id',
    name: 'id',
    display: (value, _data) => value,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'first_name',
    name: 'nom',
    display: (_value, data) => <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar name={data?.fullName} src={data?.picture?.uri} size='sm' mr='4' style={{
        height: '35px !important',
        minWidth: '35px !important',
        width: '35px !important'
      }}/>
      {data?.fullName ?? ' - '}
    </Box>,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'current_construction_site',
    name: 'chantier actuel',
    display: (_value, data) => data?.currentConstructionSite?.name ?? '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'company.name',
    name: 'société',
    display: (_value, data) => data?.company?.name ?? ' - ',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'employeePosition.name',
    name: 'poste',
    display: (_value, data) => data?.employeePosition?.name ?? ' - ',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'currentConstructionSite',
    name: 'disponibilité',
    display: (value) => <Text color="muted" className='container-text'>
      <span style={{ minWidth: '10px', minHeight: '10px', background: !value ? '#1bc181' : '#de4433', borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
      {!value ? 'Disponible' : 'Non disponible'}
    </Text>,
    displayByDefault: true
  }
]

const Employees = () => {
  const { callApi } = useAuth()
  const { reload: { employees: reloadEmployees } } = useDatas()
  const toast = useToast()

  const [employees, setEmployees] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalEmployee, setModalEmployee] = useState()
  const [modalCallback, setModalCallback] = useState()
  const [modalViewOnly, setModalViewOnly] = useState(false)

  const [deleteClocking, setDeleteClocking] = useState(false)
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false)
  const [employeesSelected, setEmployeesSelected] = useState([])
  const tableRef = useRef()

  const openModal = (employee) => {
    setDeleteClocking(false)
    setEmployeesSelected([{ id: employee.id, label: employee.fullName }])
    setModalDeleteOpen(true)
  }

  const getEmployees = ({ currentPage, rowsPerPage, sortBy, sortDir, searchInput }, callback) => {
    callApi({
      method: 'get',
      url: `admin/employee?page=${currentPage}&perPage=${rowsPerPage}&sort[${sortBy}]=${sortDir}&search=${encodeURIComponent(searchInput)}`
    })
      .then(res => {
        if (!res) return
        callback(res)
        setEmployees(res?.data?.data)
        setModalEmployee(res?.data?.data)
      })
  }

  const addEmployee = (callback) => {
    setModalOpen(true)
    setModalViewOnly(false)
    setModalCallback(() => callback)
    setModalEmployee(null)
  }

  const editEmployee = (employee, callback) => {
    setModalOpen(true)
    setModalViewOnly(false)
    setModalEmployee(employee)
    setModalCallback(() => callback)
  }

  const viewEmployee = (employee, callback) => {
    setModalOpen(true)
    setModalViewOnly(true)
    setModalEmployee(employee)
    setModalCallback(() => callback)
  }

  const deleteEmployees = async () => {
    if (!employeesSelected.length) return

    callApi({
      method: 'delete',
      url: 'admin/employee/delete/multiple?removeClocking=' + (deleteClocking ? '1' : '0'),
      data: {
        ids: employeesSelected.map(temp => temp.id)
      }
    })
      .then(res => {
        if (!res) return

        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Employés supprimés avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })

        reloadEmployees()
        tableRef.current?.forceReload()
      })
  }

  const actions = (employee, callback) => {
    return <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      <Button variant="ghost" aria-label="Show member" className='icon-btn' onClick={() => viewEmployee(employee, callback)} m='0' p='0'>
        <Icon as={FiEye} fontSize="1.25rem" color="#8186aa" />
      </Button>
      <Button variant="ghost" aria-label="Edit member" className='icon-btn' onClick={() => editEmployee(employee, callback)} m='0' p='0'>
        <Icon as={FiEdit2} fontSize="1.25rem" color="#8186aa" />
      </Button>
      <Button variant="ghost" aria-label="Delete member" className='icon-btn' onClick={() => openModal(employee, callback)} m='0' p='0'>
        <Icon as={FiTrash2} fontSize="1.25rem" color="#8186aa" />
      </Button>
    </Box>
  }

  return <Box>
    <Table
      ref={tableRef}
      getDatas={getEmployees}
      canBeSelected={true}
      canSearch={true}
      actions={actions}
      columns={columns}
      addOnClick={addEmployee}
      deleteSelectedRowsOnClick={(selectedEmployees, callback) => {
        setEmployeesSelected(employees.filter(i => selectedEmployees.includes(i.id)).map(i => ({ id: i.id, label: i.fullName })))
        setModalDeleteOpen(true)
      }}
      errorRow={(data) => !data.isEmployeeValidated}
      defaultSortDir='desc'
    />
    {modalOpen ? <AddEmployee open={true} onClose={() => setModalOpen(false)} employee={modalEmployee} callback={modalCallback} viewOnly={modalViewOnly} /> : null}
    {modalDeleteOpen && (
      <ConfirmDelete
        open={modalDeleteOpen}
        onClose={() => setModalDeleteOpen(false)}
        confirm={deleteEmployees}
        cancel={() => setModalDeleteOpen(false)}
        items={employeesSelected}
        label={'Employés'}
        customElement={<div style={{ display: 'flex', flexDirection: 'row' }}>
          <Checkbox
            onChange={() => {
              setDeleteClocking(!deleteClocking)
            }}
            isChecked={deleteClocking}
            style={{ marginRight: '10px' }}
          >
            <Text>Supprimer les pointages de cet employé</Text>
          </Checkbox>
        </div>}
      />
    )}
  </Box>
}

export default Employees
