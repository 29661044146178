import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, Grid, GridItem, useToast } from '@chakra-ui/react'

import { Input, Select, DateTimeInput } from 'components'

import { getBlobFromUrl } from 'helpers/utils'

import { useAuth, useDatas } from 'contexts'

import AddLabel from './AddLabel'

import moment from 'moment'

const AddInvoice = ({ open, onClose, invoice, callback = () => {}, viewOnly }) => {
  const { callApi } = useAuth()
  const {
    datas: {
      invoiceLabels,
      invoiceStates,
      chantiers: constructionSiteOptions
    },
    reload: {
      invoiceLabels: reloadInvoiceLabels
    }
  } = useDatas()

  const toast = useToast()

  const [file, setFile] = useState(undefined)
  const [type, setType] = useState(undefined)
  const [designation, setDesignation] = useState(undefined)
  const [priceAllTax, setPriceAllTax] = useState(undefined)
  const [priceWithoutTax, setPriceWithoutTax] = useState(undefined)
  const [dueDate, setDueDate] = useState(null)
  const [constructionSite, setConstructionSite] = useState(undefined)
  const [statut, setStatut] = useState(undefined)
  const [openAddLabel, setOpenAddLabel] = useState(false)

  const [fileDefault, setFileDefault] = useState([])

  useEffect(() => {
    if (invoice?.file) {
      getBlobFromUrl(invoice.file.uri).then(res => setFile(res))
      setFileDefault([{ name: 'Facture', size: 0, url: invoice.file.uri }])
    } else {
      setFile('')
      setFileDefault([])
    }

    setType(invoice?.invoiceLabel ? { value: invoice.invoiceLabel.id, label: invoice.invoiceLabel.name } : '')
    setDesignation(invoice?.designation ?? '')
    setPriceAllTax(invoice?.priceAllTax ?? '')
    setPriceWithoutTax(invoice?.priceWithoutTax ?? '')
    setDueDate(invoice?.dueDate ? moment(invoice.dueDate).format('YYYY-MM-DD') : null)
    setConstructionSite(invoice?.constructionSite ? { value: invoice.constructionSite.id, label: invoice.constructionSite.name } : '')
    setStatut(invoice?.state ? { value: invoice.state, label: invoice.formattedState } : '')
  }, [invoice])

  const updateStateSubmit = (state) => {
    const stateToState = {
      validate: 'validated',
      rejected: 'rejected',
      invalidated: null
    }

    const stateToActionMessage = {
      validate: 'validée',
      rejected: 'rejetée',
      invalidated: 'invalidée'
    }

    if (!invoice || !state) return
    callApi({
      method: 'post',
      url: state === 'reject' ? `admin/invoices/${invoice.id}/reject` : `admin/invoices/${invoice.id}/validate`,
      data: {
        ...invoice,
        state: stateToState[state]
      }
    })
      .then(res => {
        if (!res) return
        setStatut({ value: res?.data?.data?.state, label: res?.data?.data?.formattedState })
        callback(res)
        toast({
          position: 'bottom-right',
          description: `Facture ${stateToActionMessage[state]} avec succès`,
          status: 'success',
          duration: 5000,
          isClosable: false
        })
      })
  }

  const submit = () => {
    if (invoice &&
      (
        file ||
        (!file && invoice.file)
      )
    ) {
      callApi({
        method: 'post',
        url: `admin/invoices/${invoice.id}/edit-file`,
        formData: true,
        data: Object.assign(
          file ? { file: file } : {}
        )
      })
    }
    callApi({
      method: invoice ? 'patch' : 'post',
      url: invoice ? `admin/invoices/${invoice.id}` : 'admin/invoices',
      formData: !invoice,
      data: Object.assign(
        {
          designation,
          due_date: dueDate ? moment(dueDate).format('YYYY-MM-DD') : '',
          invoice_label_id: type?.value ?? null,
          price_all_tax: priceAllTax ?? null,
          price_without_tax: priceWithoutTax ?? null
        },
        !invoice && file ? { file: file } : {},
        constructionSite ? { construction_site_id: constructionSite.value } : {},
        statut ? { state: statut.value } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || invoice ? 'Facture modifiée avec succès' : 'Facture ajoutée avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  useEffect(() => {
    if (type?.value === 'add') {
      setOpenAddLabel(true)
    }
  }, [type])

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {invoice ? viewOnly ? 'Détails de la facture' : invoice.designation : 'Nouvelle facture'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>
          {invoice ? viewOnly ? '' : "Modifiez les informations d'une facture en éditant le champ ci-dessous :" : 'Pour ajouter une nouvelle facture, entrez les informations ci-dessous :'}
        </Text>
        <Stack spacing={5} my='5'>
          <Input name='Déposez ou télécharger un fichier' value={file} setValue={setFile} defaultFiles={fileDefault} type='file' disabled={viewOnly} />
          <Select name='Libellé *' value={type} setValue={setType} options={invoiceLabels} isDisabled={viewOnly} addOption />
          <AddLabel open={openAddLabel} onClose={() => { setOpenAddLabel(false); setType(null) } } callback={(newType) => { reloadInvoiceLabels(); setType(newType); setOpenAddLabel(false) }} />
          <Input name='Désignation *' value={designation} setValue={setDesignation} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name="Prix TTC" value={priceAllTax} setValue={setPriceAllTax} type="number" disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <Input name='Prix HT' value={priceWithoutTax} setValue={setPriceWithoutTax} type='number' disabled={viewOnly} />
            </GridItem>
          </Grid>
          <DateTimeInput name='Date *' value={dueDate} setValue={setDueDate} disabled={viewOnly} />
          <Select name='Chantier' value={constructionSite} setValue={setConstructionSite} options={constructionSiteOptions} isDisabled={viewOnly} />
          <Select name='Statut' value={statut} setValue={setStatut} options={invoiceStates} isDisabled={viewOnly} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        {invoice && [null, 'late', 'rejected', 'processing'].includes(statut?.value)
          ? <Button data-variant='outline' onClick={() => updateStateSubmit('validate')}>
              Valider la facture
            </Button>
          : ''
        }
        {invoice && [null, 'late', 'processing'].includes(statut?.value)
          ? <Button data-variant='outline' onClick={() => updateStateSubmit('reject')}>
              Rejeter la facture
            </Button>
          : ''
        }
        {invoice && ['validated'].includes(statut?.value)
          ? <Button data-variant='outline' onClick={() => updateStateSubmit('invalidate')}>
              Invalider cette facture
            </Button>
          : ''
        }
        <Button data-variant='solid' onClick={invoice && viewOnly ? onClose : submit }>
          {invoice ? viewOnly ? 'Fermer' : 'Mettre à jour' : 'Ajouter cette facture'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddInvoice
