import { useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, useToast, Stack } from '@chakra-ui/react'

import { Input } from 'components'

import { useAuth } from 'contexts'

const AddLabel = ({ open, onClose, callback = () => {} }) => {
  const { callApi } = useAuth()

  const [label, setLabel] = useState()

  const toast = useToast()

  const submit = () => {
    if (!label.length) {
      toast({
        position: 'bottom-right',
        description: 'Le label est requis',
        status: 'error',
        duration: 5000,
        isClosable: false
      })
      return
    }
    callApi({
      method: 'post',
      url: 'admin/setting/label',
      data: {
        context: 'invoice',
        name: label
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.data?.message || 'Label ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        const newLabel = res?.data?.data?.id ? { value: res.data.data.id, label: res.data.data.label } : null
        callback(newLabel)
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>Nouvelle Label</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>Pour ajouter une nouveau label, entrez simplement son nom ci-dessous :</Text>
        <Stack spacing={5} my='5'>
          <Input name='Label *' value={label} setValue={setLabel} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>Ajouter le label</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddLabel
