import { InputGroup, FormLabel } from '@chakra-ui/react'

import Select from 'react-select'
import AsyncSelect from 'react-select/async'

const selectStyles = (styles) => ({
  container: (provided) => ({
    ...provided,
    width: '100%',
    textAlign: 'left',
    ...styles?.container
  }),
  control: (provided, state) => ({
    ...provided,
    borderColor: '#E2E8F0',
    ...styles?.control,
    backgroundColor: '#fff',
    margin: 0,
    overflow: 'hidden'
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#ff5464' : state.isFocused ? '#ff546477' : '#fff',
    color: state.isSelected ? '#fff' : state.isFocused ? '#fff' : '#000',
    cursor: 'pointer',
    ':active': {
      ...provided[':active'],
      backgroundColor: '#ff546477',
      ...styles?.option
    }
  }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 })
})

const MySelect = ({ async, setValue, value, options, addOption, name, placeholder, styles, width, isMulti, ...props }) => {
  return <InputGroup width={width}>
    {async
      ? <AsyncSelect {...props} menuPortalTarget={document.body} onChange={setValue} value={value} placeholder={name || placeholder} styles={selectStyles(styles)} isMulti={isMulti} />
      : <Select {...props} menuPortalTarget={document.body} onChange={setValue} value={value} placeholder={name || placeholder} styles={selectStyles(styles)} options={addOption ? [...options, { value: 'add', label: <div style={{ width: '100%', textAlign: 'center', background: '#f5f5f5', color: '#000', fontWeight: 'bold' }}>Ajouter +</div> }] : options} isMulti={isMulti} />}
    {((isMulti && value?.length) || (!isMulti && value)) ? <FormLabel className='label-up'>{name}</FormLabel> : ''}
  </InputGroup>
}

export default MySelect
