import { useState, useEffect, useRef } from 'react'

import { Button, Box, Text, Avatar, HStack, useToast } from '@chakra-ui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { MdDragIndicator } from 'react-icons/md'
import { AiOutlinePlus } from 'react-icons/ai'

import FullCalendar from '@fullcalendar/react'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import frLocale from '@fullcalendar/core/locales/fr'
import interaction from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'

import moment from 'moment'
import 'moment/locale/fr'

import Header from './components/Header'

import './engins.scss'

import { Card } from 'components'

import { useAuth, useDatas, useModals } from 'contexts'

import { getTimesheetScheduleColor } from 'helpers/utils'

import AddModal from './components/modals/Add'
import AddEngin from 'pages/admin/Engins/components/modals/AddEngin'

moment.locale('fr')

const Planning = () => {
  const calendarRef = useRef()
  const { callApi } = useAuth()
  const { datas: { hideWeekEnd } } = useDatas()
  const { openConfirmToDeleteModal } = useModals()
  const toast = useToast()
  const [loading, setLoading] = useState(true)
  const [sheduled, setSheduled] = useState([])
  const [engins, setEngins] = useState([])

  const [search, setSearch] = useState('')
  const [chantier, setChantier] = useState({ id: 'all', label: 'Tous les chantiers' })

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().endOf('isoWeek').format('YYYY-MM-DD'))

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [addModalDefaultValues, setAddModalDefaultValues] = useState()

  const [enginToDisplay, setEnginToDisplay] = useState()

  const updateDate = (direction) => {
    const newStartDate = moment(startDate).add(direction, 'week').startOf('isoWeek').format('YYYY-MM-DD')
    const newEndDate = moment(startDate).endOf('isoWeek').format('YYYY-MM-DD')
    setStartDate(newStartDate)
    setEndDate(newEndDate)
    calendarRef.current.getApi().changeView('resourceTimeline', newStartDate)
  }

  const getEngins = () => {
    setLoading(true)
    callApi({
      method: 'get',
      url: `admin/timesheet/machine?search=${search ?? ''}&start_date=${startDate}&end_date=${endDate}&filter=${chantier?.value === 'all' ? '' : chantier?.value ?? ''}`,
      catchCallback: () => setLoading(false)
    })
      .then(res => {
        if (!res) return
        const newScheduled = []
        const newEngins = []
        res?.data?.data?.forEach(engin => {
          newEngins.unshift({
            id: engin.id,
            groupId: engin.id,
            title: engin.brand,
            extendedProps: {
              engin
            }
          })
          engin.timesheetSchedules
            ? newScheduled.unshift(...engin.timesheetSchedules.map(timesheetSchedule => {
              return {
                start: timesheetSchedule.startFull,
                end: timesheetSchedule.endFull,
                resourceId: engin.id,
                id: timesheetSchedule.id,
                title: timesheetSchedule.constructionSite?.name,
                backgroundColor: getTimesheetScheduleColor(timesheetSchedule.endDate).event,
                extendedProps: {
                  accentColor: getTimesheetScheduleColor(timesheetSchedule.endDate).accent,
                  engin,
                  constructionSite: timesheetSchedule.constructionSite,
                  timesheetSchedule,
                  hasNextDay: timesheetSchedule.hasNextDay
                },
                editable: true
              }
            }).reverse())
            : newScheduled.unshift({
              start: moment(startDate).startOf('isoWeek').format('YYYY-MM-DD'),
              end: moment(startDate).endOf('isoWeek').add(1, 'days').format('YYYY-MM-DD'),
              resourceId: engin.id,
              id: engin.id,
              title: 'Ajouter au planning',
              backgroundColor: 'transparent',
              extendedProps: {
                engin
              },
              display: 'background'
            })
          setLoading(false)
        })
        setSheduled(newScheduled)
        setEngins(newEngins)
        setLoading(false)
      })
  }

  const deleteSchedule = (scheduleId) => {
    callApi({
      method: 'delete',
      url: `admin/timesheet/${scheduleId}`
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || "Plannification de l'engin supprimée avec succès",
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        getEngins()
      })
  }

  const scheduleUpdate = (scheduleId, scheduleDatas, failedCallback) => {
    if (!scheduleId || !scheduleDatas) return
    callApi({
      method: 'patch',
      url: `admin/timesheet/${scheduleId}`,
      data: scheduleDatas,
      catchCallback: () => failedCallback()
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || "Plannification de l'engin modifié avec succès",
          status: 'success',
          duration: 5000,
          isClosable: false
        })
      })
  }

  const eventResize = (event) => {
    const data = {
      start_date: moment(event.event.startStr).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(event.event.endStr).format('YYYY-MM-DD HH:mm:ss'),
      machine_id: event.event.extendedProps.engin.id,
      construction_site_id: event.event.extendedProps.timesheetSchedule.constructionSite.id
    }
    return scheduleUpdate(event.event.id, data, () => event.revert())
  }

  const eventDrop = (event) => {
    const data = {
      start_date: moment(event.event.startStr).format('YYYY-MM-DD HH:mm:ss'),
      end_date: moment(event.event.endStr).format('YYYY-MM-DD HH:mm:ss'),
      machine_id: event.newResource ? event.newResource.id : event.event.extendedProps.engin.id,
      construction_site_id: event.event.extendedProps.timesheetSchedule.constructionSite.id
    }
    return scheduleUpdate(event.event.id, data, () => event.revert())
  }

  const timeoutRef = useRef()
  useEffect(() => {
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(getEngins, 1000)
  }, [chantier, search, startDate])

  return <Card padding='0' className='engins'>
		<Header
            chantier={chantier}
            setChantier={setChantier}
            search={search}
            setSearch={setSearch}
            loading={loading}
            onOpenAddModal={() => setAddModalOpen(true)}
            startDate={startDate}
            endDate={endDate}
        />
    <AddModal open={addModalOpen} onClose={() => setAddModalOpen(false)} addModalDefaultValues={addModalDefaultValues} callback={getEngins} />
		<AddEngin open={Boolean(enginToDisplay)} onClose={() => setEnginToDisplay(null)} engin={enginToDisplay} viewOnly />
		<FullCalendar
			ref={calendarRef}
			schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
			plugins={[resourceTimelinePlugin, momentPlugin, interaction]}
			locale={frLocale}
			height='800px'
			headerToolbar={false}
            hiddenDays={hideWeekEnd ? [6, 0] : []}
			slotMinWidth={108}
			editable={true}
			selectMirror={true}
			initialView='resourceTimeline'
			events={sheduled}
			resources={engins}
			views={{
			  resourceTimeline: {
			    type: 'timeline',
			    duration: { weeks: 1 },
			    slotDuration: { days: 1 },
			    titleFormat: '[Semaine] W - YYYY'
			  }
			}}
			eventResize={eventResize}
			eventDrop={eventDrop}
			eventBorderColor='transparent'
			resourceAreaWidth='301px'
			/* CURRENT WEEK (RIGHT) - FORMAT DD ddd. */
			slotLabelContent={(arg) => (<HStack height='70px' justify='center' align='center' flexDirection='column'>
				<Text fontSize='15px'>{ moment(arg.date).format('DD') }</Text>
				<Text ml='0!' fontSize='15px' fontWeight="light">{ moment(arg.date).format('ddd') }</Text>
			</HStack>)}
			/* CURRENT WEEK (LEFT) - FORMAT [Semaine] num - YYYY */
			resourceAreaHeaderContent={(arg) => (<Box style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', paddingTop: '10px' }} px='2'>
				<Button data-variant='outline' onClick={() => updateDate(-1)} style={{ padding: 0, borderRadius: '4px' }}>
					<ChevronLeftIcon size="22" />
				</Button>
				<Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '150px' }} color='#8186aa'>{ arg.view.title.slice(0, 17) }</Box>
				<Button data-variant='outline' onClick={() => updateDate(1)} style={{ padding: 0, borderRadius: '4px' }}>
					<ChevronRightIcon size="22" />
				</Button>
			</Box>)}
			/* LISTE ENGINS */
			resourceLabelContent={(arg) => (<HStack width='100%' p='1' onClick={() => setEnginToDisplay(arg.resource.extendedProps.engin)} style={{ cursor: 'pointer' }}>
				<Avatar boxSize='8' size='xs' name={arg.resource.extendedProps.engin.brand} src={arg.resource.extendedProps.engin?.picture ? arg.resource.extendedProps.engin.picture.uri : null} />
				<Text mt='0.5' fontSize='13px'>{arg.fieldValue}</Text>{/* arg.fieldValue will take the value set for "resources.title" */}
			</HStack>)}
			eventContent={(arg) => <Box>
				{arg.event.display === 'background'
				  ? <HStack position='relative' justify='center' align='center'>
							<Box className='eventContent'>
								<Box style={{
								  borderBottom: '1px solid rgba(0,0,0,.12)',
								  width: '100%',
								  position: 'absolute',
								  top: '2em'
								}} />
								<Button style={{
								  transform: 'translateY(15px)',
								  backgroundColor: '#fff',
								  border: '1px solid #000',
								  borderRadius: '7px',
    							height: '30px',
								  padding: '0px 30px'
								}} onClick={() => {
								  setAddModalDefaultValues({
								    startDate: arg.event.start,
								    enginId: arg.event.extendedProps.engin.id
								  })
								  setAddModalOpen(true)
								}}>{ arg.event.title }</Button>
							</Box>
						</HStack>
        	: <HStack style={{ borderRadius: '7px' }} position='relative' justify={moment(arg.view.currentStart).isSameOrBefore(moment(arg.event.start)) || moment(arg.view.currentEnd).isSameOrAfter(moment(arg.event.end)) ? 'space-between' : 'center'}>
              {moment(arg.view.currentStart).isSameOrBefore(moment(arg.event.start)) && <MdDragIndicator size="22" color={arg.event.extendedProps.accentColor} />}
							<Box className='eventContent'>
								<Box className='actions' height="23px">
									<Button bg='#EDF2F7' color='#000' px='4' mr='1' onClick={() => {
									  setAddModalDefaultValues({
									    id: arg.event.id,
									    startDate: arg.event.start,
									    endDate: arg.event.end,
									    enginId: arg.event.extendedProps.engin.id,
									    chantierId: arg.event.extendedProps.constructionSite.id
									  })
									  setAddModalOpen(true)
									}}>Modifier</Button>
									<Button bg='#EDF2F7' color='#000' px='4' ml='1' onClick={() => openConfirmToDeleteModal({
									  label: 'Plannification Engins',
									  items: [{ id: arg.event.id, label: arg.event.title }],
									  confirm: () => deleteSchedule(arg.event.id)
									})}>Supprimer</Button>
								</Box>
								<Text className='title' fontWeight='bold' fontSize='15px'>{ arg.event.title }</Text>
							</Box>
							{moment(arg.view.currentEnd).isSameOrAfter(moment(arg.event.end)) && <MdDragIndicator size="22" color={arg.event.extendedProps.accentColor} />}
							{moment(arg.event.end).isBefore(moment(endDate).format('YYYY-MM-DD')) && !arg.event.extendedProps.hasNextDay && <Button
								data-variant='outline'
								onClick={() => {
								  setAddModalDefaultValues({
								    startDate: arg.event.extendedProps.end,
								    enginId: arg.event.extendedProps.engin.id
								  })
								  setAddModalOpen(true)
								}}
								style={{
								  position: 'absolute',
								  left: '100%',
								  padding: '0px',
								  borderRadius: '7px',
								  minWidth: '25px',
								  height: '25px'
								}}
							>
								<AiOutlinePlus size='14' />
							</Button>}
						</HStack>
				}
			</Box>}
		/>
	</Card>
}

export default Planning
