import { useState } from 'react'

import Header from './Header'

import './employee.scss'

import { Box } from '@chakra-ui/react'
import { Card, LoadingScreen } from 'components'
import AddChantier from 'pages/admin/Chantiers/components/modals/AddChantier'
import AddEmployee from 'pages/admin/Employees/components/modals/AddEmployee'

import { Ouvriers, Chantiers, Vehicules } from './plannings'

import { FiltersProvider, useFilters } from './contexts/filters'

const PlanningEmployeeContent = () => {
  const {
    filters: {
      fullSize,
      planningType,
      loading
    }
  } = useFilters()

  const [employeeModal, setEmployeeModal] = useState()
  const [chantierModal, setChantierModal] = useState()

  return <>
    <AddChantier open={Boolean(chantierModal)} onClose={() => setChantierModal(null)} chantier={chantierModal} viewOnly />
    <AddEmployee open={!!employeeModal} onClose={() => setEmployeeModal(null)} employee={employeeModal} viewOnly />
    <Card padding='0' fullSize={fullSize} className='planningEmployee'>
      <Header />
      <Box height='90%' pb={fullSize ? 10 : 0} overflow='hidden' position='relative'>
        <Box style={{ display: planningType === 'ouvrier' ? 'block' : 'none' }}><Ouvriers openEmployeeModal={setEmployeeModal} /></Box>
        {planningType === 'chantier' && (<Box style={{ display: planningType === 'chantier' ? 'block' : 'none' }}><Chantiers openEmployeeModal={setEmployeeModal} openConstructionSiteModal={setChantierModal} /></Box>)}
        {planningType === 'vehicule' && (<Box style={{ display: planningType === 'vehicule' ? 'block' : 'none' }}><Vehicules /></Box>)}
        {loading && <LoadingScreen style={{ position: 'absolute', backgroundColor: '#ffffffaa', top: '0', zIndex: '1000', pointerEvents: 'none' }} />}
      </Box>
    </Card>
  </>
}

const PlanningEmployee = () => {
  return <FiltersProvider>
    <PlanningEmployeeContent />
  </FiltersProvider>
}

export default PlanningEmployee
