import { useEffect, useState } from 'react'

import { useDrop } from 'react-dnd'

import { Button, Box, Text, HStack, Tabs, TabPanels, Tab, TabPanel, TabList } from '@chakra-ui/react'
import { Input, OutputSelect } from 'components'

import Ouvrier from './components/sidebar/ouvrier'
import Vehicule from './components/sidebar/vehicule'
import Team from '../components/team'

import { useModals, useDatas } from 'contexts'
import { useFilters } from '../../contexts/filters'

const arrayUniqueByKey = (array, key) => {
  const newArray = []
  const onlyKeys = []

  array.forEach(item => {
    const k = item[key]
    if (!onlyKeys.includes(k)) {
      newArray.push(item)
      onlyKeys.push(k)
    }
  })

  return newArray
}

const Sidebar = ({ datas, openTimesheetModal, openEmployeeModal, openVehiculeModal, callback }) => {
  const [ouvriersFiltered, setOuvriersFiltered] = useState([])

  useEffect(() => {
    const nComp = []

    datas.ouvriers.filter(o => Boolean(o.company)).forEach(o => {
      if (!nComp[o.company.id]) {
        nComp[o.company.id] = {
          ...o.company,
          employees: []
        }
      }
      nComp[o.company.id].employees.push(o)
    })
    setOuvriersFiltered(nComp.filter(v => v))
  }, [datas.ouvriers])

  const {
    options: {
      companies
    },
    actions: {
      deleteTimesheet,
      updateOuvrierVehicule
    }
  } = useFilters()
  const {
    datas: {
      teams,
      disponibilitiesAll,
      positions
    },
    reload: {
      teams: reloadTeams
    }
  } = useDatas()
  const { openConfirmToDeleteModal, openTeamModal } = useModals()

  const [ouvriersSearch, setOuvriersSearch] = useState('')
  const [ouvriersPositions, setOuvriersPositions] = useState([])
  const [ouvriersCompanies, setOuvriersCompanies] = useState([])
  const [ouvriersDispo, setOuvriersDispo] = useState({ value: 'all', label: 'Toutes les disponibilités' })
  const [vehiculeSearch, setVehiculeSearch] = useState('')
  const [vehiculePlace, setVehiculePlace] = useState('')
  const [vehiculeDispo, setVehiculeDispo] = useState({ value: 'all', label: 'Toutes les disponibilités' })

  const [searchTeam, setSearchTeam] = useState('')

  const [{ isOver: isOverOuvrier, canDrop: canDropOuvrier, active: activeOuvrier }, dropOuvrier] = useDrop({
    accept: ['ouvrier'],
    drop: (item, _monitor) => {
      updateOuvrierVehicule({ ouvrierId: item.employee.id, from: item.timesheetId, to: 'other', callback })
    },
    canDrop: (item, _monitor) => {
      return Boolean(item.timesheetId)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      active: monitor.isOver() && monitor.canDrop()
    })
  })

  const [{ isOver: isOverVehicule, canDrop: canDropVehicule, active: activeVehicule }, dropVehicule] = useDrop({
    accept: ['timesheet'],
    drop: (item, _monitor) => {
      openConfirmToDeleteModal({
        label: 'Plannification véhicule',
        items: [{ id: item.timesheetId, label: item.vehicule.brand }],
        confirm: () => deleteTimesheet({ id: item.timesheetId, callback, isVehicule: true })
      })
    },
    canDrop: (_item, _monitor) => {
      return true
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      active: monitor.isOver() && monitor.canDrop()
    })
  })

  const filterTeam = (team) => {
    if (!searchTeam.length) return true
    const ss = searchTeam.toLowerCase()
    if (team.name.toLowerCase().includes(ss)) return true
    const ename = team.employees.map(ouvrier => (`${ouvrier.first_name} ${ouvrier.last_name}`).toLowerCase()).filter(ouvrierName => ouvrierName.includes(ss)).length > 0
    if (ename) return true
    return false
  }

  return <>
    <Box style={{ height: '82px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', border: '1px solid #E2E8F0', borderLeft: 0 }} pr='4'>
      <Button data-variant='solid' onClick={() => openTimesheetModal(null)}>Ajouter</Button>
    </Box>
    <Box style={{
      height: 'calc(100% - 82px)',
      overflow: 'auto'
    }}>
      <Tabs align="center" height='calc(100% - 82px)'>
        <Box width='100%' style={{ padding: '5px', overflow: 'auto' }}>
          <TabList width='fit-content'>
            <Tab className='sidebartab'>Vehicule</Tab>
            <Tab className='sidebartab'>Ouvrier</Tab>
            <Tab className='sidebartab'>Equipe</Tab>
          </TabList>
        </Box>
        {/* <Divider borderColor='#A0AEC0' width='90%' /> */}
        <TabPanels height='100%'>
          <TabPanel height='100%'>
            <HStack flexDirection='column' textAlign='left' gap='10px' height='100%' align='flex-start'>
              <Box style={{ marginInlineStart: '0.5rem' }}>
                <OutputSelect name='Disponibilités' value={vehiculeDispo} setValue={setVehiculeDispo} options={disponibilitiesAll.filter(d => d.value !== '#FCC35B')} />
                <OutputSelect name='Nombre de place' value={vehiculePlace} setValue={setVehiculePlace} options={arrayUniqueByKey((datas?.vehicules ?? []).map(v => ({ value: v.passengerCount, label: `${v.passengerCount} places` })), 'value')} />
              </Box>
              <Input name='Rechercher...' value={vehiculeSearch} setValue={setVehiculeSearch} mb='3' />
              <Box align='center' width='100%' className={`dropZone ${isOverVehicule && canDropVehicule ? 'action' : ''}`} ref={dropVehicule} style={{ marginInlineStart: 0 }}>
                {datas?.vehicules
                  ?.filter(vehicule => vehiculeDispo.value === 'all' || vehiculeDispo.value === '#DE4433')
                  ?.filter(vehicule => !vehiculePlace?.value || parseInt(vehiculePlace?.value) === vehicule.passengerCount)
                  ?.filter(vehicule => !vehiculeSearch?.length || vehicule.brand.toLowerCase().includes(vehiculeSearch.toLowerCase()))
                  ?.map(vehicule => <Vehicule key={vehicule.id} vehicule={vehicule} canHaveMorePassenger={false} openVehiculeModal={openVehiculeModal} />)}
                  {activeVehicule
                    ? <HStack height='fit-content' mb='3' style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box border='1px dashed #D2D8E0' borderRadius='8px' p='3' cursor='pointer' bg='#ff5464' color='#fff' width='100%'>
                          {'Lacher pour retirer le véhicule'}
                        </Box>
                      </HStack>
                    : ''
                  }
              </Box>
            </HStack>
          </TabPanel>
          <TabPanel height='100%'>
            <HStack flexDirection='column' textAlign='left' gap='10px' height='100%'>
              <Box style={{ marginInlineStart: '0.5rem' }}>
                <OutputSelect name='Sociétés' value={ouvriersCompanies} setValue={setOuvriersCompanies} options={companies.filter(c => c.label.length > 0)} multiple />
                <OutputSelect name='Postes' value={ouvriersPositions} setValue={setOuvriersPositions} options={positions} multiple />
                <OutputSelect name='Disponibilités' value={ouvriersDispo} setValue={setOuvriersDispo} options={disponibilitiesAll} />
              </Box>
              <Input name='Rechercher...' value={ouvriersSearch} setValue={setOuvriersSearch} mb='3' />
              <Box align='center' width='100%' className={`dropZone ${isOverOuvrier && canDropOuvrier ? 'action' : ''}`} ref={dropOuvrier} style={{ marginInlineStart: 0 }}>
                {activeOuvrier
                  ? <HStack height='fit-content' mb='3' style={{ display: 'flex', flexDirection: 'column' }}>
                      <Box border='1px dashed #D2D8E0' borderRadius='8px' p='3' cursor='pointer' bg='#ff5464' color='#fff' width='100%'>
                        {"Lacher pour retirer l'ouvrier"}
                      </Box>
                    </HStack>
                  : ''
                }
                {ouvriersFiltered
                  ?.filter(company => !ouvriersCompanies.length || ouvriersCompanies.map(p => p.value).includes(company?.id))
                  ?.filter(company => ouvriersDispo.value === 'all' || ouvriersDispo.value === '#DE4433')
                  ?.filter(({ employees }) => !ouvriersPositions.length || employees.map(e => e.employeePosition?.id).filter(e => ouvriersPositions.map(p => p.value).includes(e)).length)
                  ?.map(company => <HStack key={company.id} height='fit-content' mb='3' style={{ display: 'flex', flexDirection: 'column' }}>
                  <Text fontWeight='bold' fontSize='14px' mb='4'>{company.name}</Text>
                  <Box align='center' width='100%' height='100%' m='0' style={{ marginInlineStart: 0 }}>
                    {company.employees
                      ?.filter(employee => !ouvriersPositions.length || ouvriersPositions.map(p => p.value).includes(employee.employeePosition?.id))
                      ?.filter(employee => !ouvriersSearch?.length || employee.fullName.toLowerCase().includes(ouvriersSearch.toLowerCase()))
                      ?.map(employee => <Ouvrier key={employee.id} ouvrier={employee} openEmployeeModal={openEmployeeModal} />)}
                  </Box>
                </HStack>)}
              </Box>
            </HStack>
          </TabPanel>
          <TabPanel>
            <Input name='Rechercher...' value={searchTeam} setValue={setSearchTeam} />
            <Box my='4'>
              {teams.filter(filterTeam).length
                ? teams.filter(filterTeam).map(team => <Team key={team.id} team={team} />)
                : <Text>Aucune équipe</Text>
              }
            </Box>
            <Button data-variant='solid' width='100%' onClick={() => openTeamModal({
              callback: reloadTeams,
              callbackDelete: reloadTeams
            })}><Text fontSize='14'>Ajouter une équipe</Text></Button>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  </>
}

export default Sidebar
