import { useState, useEffect } from 'react'

import Select from '../select/select'

import { useAuth } from 'contexts'

// const defaultValuesIfOffline = {
//   address: 'address test',
//   city: 'city test',
//   postal_code: 'postal_code test',
//   country: 'country test',
//   latitude: 42.42,
//   longitude: 42.42
// }

const AddressInput = ({ name, defaultValue, setValues, ...props }) => {
  const { callApi } = useAuth()

  // const [searchAddress, setSearchAddress] = useState()
  const [selectedAddress, setSelectedAddress] = useState()
  // const [defaultAddressOptions, setDefaultAddressOptions] = useState([])

  const loadAddressOptions = (inputValue, callback) => {
    if (inputValue.length === 0) return
    callApi({
      method: 'get',
      url: `admin/search/address/place?input=${encodeURIComponent(inputValue)}`
    })
      .then(res => {
        if (!res) return
        const options = /* [defaultValuesIfOffline] || */res?.data?.data?.map(data => ({ value: data.placeId, label: data.address })) || []
        callback(options)
      })
  }

  useEffect(() => {
    if (!selectedAddress) {
      setValues(null)
      return
    }
    callApi({
      method: 'get',
      url: `admin/search/address/detail?place_id=${selectedAddress.value}`
    }).then(res => {
      if (!res) return
      setValues({
        address: selectedAddress.label,
        city: res?.data?.data?.city,
        postal_code: res?.data?.data?.postal_code ?? '',
        country: res?.data?.data?.country,
        latitude: res?.data?.data?.geometry?.location?.lat,
        longitude: res?.data?.data?.geometry?.location?.lng
      })
    })
  }, [selectedAddress])

  useEffect(() => {
    if (defaultValue) {
      loadAddressOptions(defaultValue, (options) => {
        if (options.length) setSelectedAddress(options[0])
        // setDefaultAddressOptions(options)
      })
    }
    // setValues(defaultValuesIfOffline)
  }, [])

  return <Select {...props} async loadOptions={loadAddressOptions} value={selectedAddress} setValue={setSelectedAddress} placeholder={name} /* defaultOptions={defaultAddressOptions} */ />
}

export default AddressInput
