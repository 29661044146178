import { Link } from 'react-router-dom'

import { Avatar, Box, Flex, Menu, MenuItem, MenuList, Text, useColorModeValue as mode, Icon, useMenuButton } from '@chakra-ui/react'
import { MdKeyboardArrowDown, MdNotifications } from 'react-icons/md'

import { useAuth, useNotifications } from 'contexts'

const ProfileMenuButton = (props) => {
  const { user } = useAuth()
  const { notificationsWhoNeedToBeRead } = useNotifications()
  const buttonProps = useMenuButton(props)

  return (
    <Flex>
      <Box position={'relative'}>
        {notificationsWhoNeedToBeRead && <span style={{ width: '12px', height: '12px', position: 'absolute', top: '0', right: '0', zIndex: '5', backgroundColor: '#ff5464', border: '1.5px solid white', borderRadius: '50%', transform: 'scale(1.1)' }}></span>}
        <Avatar
          boxSize='10'
          name={user ? `${user.first_name} ${user.last_name}` : ''}
          bg={'brand.primary'}
          className='topbar-avatar'
        />
      </Box>
      <Flex
        {...buttonProps}
        as="button"
        align="center"
        flexShrink={0}
        outline="0"
        ml='1em'
        _hover={{ color: 'brand.primary' }}
      >
        <Text fontWeight="600" fontSize="16">
          {user ? `${user.first_name ?? ''} ${user.last_name ?? ''}` : ''}
        </Text>
        <Icon
          as={MdKeyboardArrowDown}
          _hover={{ transition: 'all .5s', transform: 'rotate(15deg)' }}
          fontSize="1.5rem"
        />
      </Flex>
    </Flex>
  )
}

const ProfileDropdown = () => {
  const { logout, role, user } = useAuth()
  const { notificationsWhoNeedToBeRead } = useNotifications()

  return (
    <Menu pl='10'>
      <ProfileMenuButton />
      <MenuList
        rounded="md"
        shadow="lg"
        py="1"
        color={mode('gray.600', 'inherit')}
        fontSize="sm"
        minWidth={'12rem'}
      >
        <Link to="/admin/profile">
          <MenuItem
            fontWeight="bold"
            _hover={{ color: 'brand.primary' }}
            mt="6px"
            mb="6px"
          >
            Mon profil
          </MenuItem>
        </Link>
        {(user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0) && <Link to="/admin/setting">
          <MenuItem
            fontWeight="bold"
            _hover={{ color: 'brand.primary' }}
            mt="6px"
            mb="6px"
          >
            Paramètres
          </MenuItem>
        </Link>}
        {(user.roles.filter(role => role.name === 'admin').length > 0 || user.roles.filter(role => role.name === 'cdt').length > 0) && <Link to="/admin/administration">
          <MenuItem
            fontWeight="bold"
            _hover={{ color: 'brand.primary' }}
            mt="6px"
            mb="6px"
          >
            Administration
          </MenuItem>
        </Link>}
        {role === 'admin' && <Link to="/admin/notification">
          <MenuItem
            fontWeight="bold"
            _hover={{ color: 'brand.primary' }}
            mt="6px"
            mb="6px"
          >
            Notifications {notificationsWhoNeedToBeRead && <Icon as={MdNotifications} color='brand.primary' ml='1' />}
          </MenuItem>
        </Link>}
        <MenuItem
          fontWeight="bold"
          _hover={{ color: 'brand.primary' }}
          mt="6px"
          mb="6px"
          onClick={logout}
        >
          Se déconnecter
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default ProfileDropdown
