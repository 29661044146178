import { useMemo } from 'react'

import { TextField } from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const DateTimeInput = ({ name, value, setValue, time, date = true, width, format, ...props }) => {
  const ToUseInput = useMemo(() => time ? date ? DateTimePicker : TimePicker : DatePicker, [time])
  const ToUseFormat = useMemo(() => format || (time ? date ? 'DD/MM/YYYY HH:mm:ss' : 'HH:mm' : 'DD/MM/YYYY'), [time])

  return <LocalizationProvider dateAdapter={AdapterMoment}>
    <ToUseInput
      label={name}
      value={value}
      onChange={setValue}
      inputFormat={ToUseFormat}
      componentsProps={{
        actionBar: {
          actions: ['cancel']
        }
      }}
      {...props}
      renderInput={(params) => <TextField fullWidth={!width} {...params} width={width} sx={{ '& .MuiOutlinedInput-root': { '& > fieldset': { borderColor: '#E2E8F0' } } }} />}
    />
  </LocalizationProvider>
}

export default DateTimeInput
