import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, Grid, GridItem, useToast } from '@chakra-ui/react'

import { PictureInput, Input, Select, Textarea, DateTimeInput } from 'components'

import { /* getBlobFromUrl, */ fileToBePost } from 'helpers/utils'

import { useAuth, useDatas } from 'contexts'

import moment from 'moment'

const AddVehicule = ({ open, onClose, vehicule, callback = () => {}, viewOnly }) => {
  const { callApi } = useAuth()
  const { datas: { licenses: licenseOptions }, reload: { vehicules: reloadVehicules } } = useDatas()

  const toast = useToast()

  const [picture, setPicture] = useState(undefined)
  const [brand, setBrand] = useState(undefined)
  const [year, setYear] = useState(undefined)
  const [immatriculation, setImmatriculation] = useState(undefined)
  const [insuranceDate, setInsuranceDate] = useState(undefined)
  const [technicalControlFile, setTechnicalControlFile] = useState(undefined)
  const [technicalControlDate, setTechnicalControlDate] = useState(undefined)
  const [registrationFile, setRegistrationFile] = useState(undefined)
  const [registrationDate, setRegistrationDate] = useState(undefined)
  const [passengerCount, setPassengerCount] = useState(undefined)
  const [license, setLicense] = useState(undefined)
  const [description, setDescription] = useState(undefined)

  const [technicalControlFileDefault, setTechnicalControlFileDefault] = useState([])
  const [registrationFileDefault, setRegistrationFileDefault] = useState([])

  useEffect(() => {
    setPicture(vehicule?.picture?.uri ? { data_url: vehicule.picture.uri } : '')

    setBrand(vehicule?.brand ?? '')
    setYear(vehicule?.year ?? '')
    setImmatriculation(vehicule?.immatriculation ?? '')
    setInsuranceDate(vehicule?.insuranceDate ?? null)

    setRegistrationDate(vehicule?.registrationDate ?? null)

    if (vehicule?.registration) {
      setRegistrationFile(vehicule.registration.uri)
      // getBlobFromUrl(vehicule.registration.uri).then(res => setRegistrationFile(res))
      setRegistrationFileDefault([{ name: 'Carte grise', size: 0, url: vehicule.registration.uri }])
    } else {
      setRegistrationFile('')
      setRegistrationFileDefault([])
    }

    setTechnicalControlDate(vehicule?.technicalControlDate ?? null)

    if (vehicule?.technicalControl) {
      setTechnicalControlFile(vehicule.technicalControl.uri)
      // getBlobFromUrl(vehicule.technicalControl.uri).then(res => setTechnicalControlFile(res))
      setTechnicalControlFileDefault([{ name: 'Contrôle technique', size: 0, url: vehicule.technicalControl.uri }])
    } else {
      setTechnicalControlFile('')
      setTechnicalControlFileDefault([])
    }

    setPassengerCount(vehicule?.passengerCount ?? '')
    setLicense(vehicule?.license ? { value: vehicule.license.id, label: vehicule.license.name } : '')
    setDescription(vehicule?.description ?? '')
  }, [vehicule])

  const submit = async () => {
    if (vehicule &&
      (
        picture ||
        registrationFile ||
        technicalControlFile ||
        (!picture && vehicule.picture) ||
        (!registrationFile && vehicule.registration) ||
        (!technicalControlFile && vehicule.technicalControl)
      )
    ) {
      callApi({
        method: 'post',
        url: `admin/vehicles/${vehicule.id}/edit-file`,
        formData: true,
        data: Object.assign(
          picture && picture?.data_url !== vehicule?.picture?.uri ? { picture: fileToBePost(picture.data_url) } : {},
          registrationFile && registrationFile?.blob ? { registration: registrationFile } : {},
          // registrationFile ? { registration: registrationFile } : {},
          technicalControlFile && technicalControlFile?.blob ? { technical_control: technicalControlFile } : {}
          // technicalControlFile ? { technical_control: technicalControlFile } : {}
        )
      })
    }
    callApi({
      method: vehicule ? 'patch' : 'post',
      url: vehicule ? `admin/vehicles/${vehicule.id}` : 'admin/vehicles',
      formData: !vehicule,
      data: Object.assign(
        {
          brand,
          description,
          immatriculation,
          year,
          insurance_date: insuranceDate ? moment(insuranceDate).format('YYYY-MM-DD') : '',
          registration_date: registrationDate ? moment(registrationDate).format('YYYY-MM-DD') : '',
          technical_control_date: technicalControlDate ? moment(technicalControlDate).format('YYYY-MM-DD') : ''
        },
        // insuranceDate ? { insurance_date: moment(insuranceDate).format('YYYY-MM-DD') } : {},
        license ? { license_id: license.value } : {},
        passengerCount ? { passenger_count: passengerCount } : {},
        !vehicule && picture ? { picture: fileToBePost(picture.data_url) } : {},
        !vehicule && registrationFile ? { registration: registrationFile } : {},
        !vehicule && technicalControlFile ? { technical_control: technicalControlFile } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || vehicule ? 'Vehicule modifié avec succès' : 'Vehicule ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        reloadVehicules()
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {vehicule ? viewOnly ? 'Informations' : 'Modifier ce véhicule' : 'Ajouter un vehicule'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        {!viewOnly && <Text fontSize={14} mb='1.5rem'>
          {vehicule ? "Modifiez les informations d'un véhicule en éditant le champ ci-dessous :" : 'Pour ajouter un nouveau vehicule, entrez les informations ci-dessous :'}
        </Text>}
        <PictureInput disabled={viewOnly} picture={picture} setValue={(imageList) => setPicture(imageList[0])} onRemove={() => setPicture(undefined)} />
        <Stack spacing={5} my='5'>
          <Input name='Marque véhicule *' value={brand} setValue={setBrand} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name='Année' value={year} setValue={setYear} disabled={viewOnly} type='number' />
            </GridItem>
            <GridItem w='100%'>
              <Input name='Immatriculation' value={immatriculation} setValue={setImmatriculation} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <DateTimeInput name='Date Assurance' value={insuranceDate} setValue={setInsuranceDate} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name="Carte grise" value={registrationFile} setValue={setRegistrationFile} type="file" defaultFiles={registrationFileDefault} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Date expiration' value={registrationDate} setValue={setRegistrationDate} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name="Contrôle technique" value={technicalControlFile} setValue={setTechnicalControlFile} type="file" defaultFiles={technicalControlFileDefault} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Date expiration' value={technicalControlDate} setValue={setTechnicalControlDate} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <Input name='Place (hors conducteur) *' value={passengerCount} setValue={setPassengerCount} disabled={viewOnly} type='number' />
          <Select name='Permis *' value={license} setValue={setLicense} options={licenseOptions} isDisabled={viewOnly} />
          <Textarea name='Description' value={description} setValue={setDescription} type="textarea" disabled={viewOnly} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={vehicule && viewOnly ? onClose : submit }>
          {vehicule ? viewOnly ? 'Fermer' : 'Mettre à jour' : 'Ajouter'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default AddVehicule
