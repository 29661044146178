
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const MyPhoneInput = ({ value, setValue, disabled }) => {
  return <PhoneInput
		international
		defaultCountry='FR'
		withCountryCallingCode
		placeholder="Téléphone"
		value={value}
		onChange={setValue}
		disabled={disabled}
	/>
}

export default MyPhoneInput
