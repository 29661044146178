import { useEffect, useState } from 'react'

import { Modal, ModalOverlay, ModalContent, HStack, Avatar, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast, Icon, Box, IconButton } from '@chakra-ui/react'
import { /* FiMinus, */ FiTrash2 } from 'react-icons/fi'

import { Input, Select } from 'components'

import { useAuth, useDatas } from 'contexts'

const Team = ({ open, onClose, team, callback = () => {} }) => {
  const { callApi } = useAuth()
  const { datas: { positions } } = useDatas()

  const toast = useToast()

  const [name, setName] = useState('')
  const [ouvriers, setOuvriers] = useState([])

  useEffect(() => {
    setName(team?.name ?? '')
    setOuvriers((team?.employees ?? []).map(e => ({
      value: e.id,
      label: e.name,
      extendedProps: {
        ...e,
        name: e.employeePositionId ? positions.filter(p => p.value === e.employeePositionId)[0].label : '-'
      }
    })))
  }, [team])

  const loadOuvriers = (value, callback) => {
    callApi({
      method: 'get',
      url: `admin/employee?sort[id]=asc&search=${value}`
    })
      .then(res => {
        if (!res) return
        const r = (res?.data?.data ?? []).map(r => ({ value: r.id, label: r.fullName, extendedProps: r }))
        callback(r)
      })
  }

  const deleteTeam = () => {
    if (!team) return
    callApi({
      method: 'delete',
      url: `admin/teams/${team.id}`
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Equipe supprimé avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  const submit = () => {
    callApi({
      method: team ? 'put' : 'post',
      url: team ? `admin/teams/${team.id}` : 'admin/teams',
      data: {
        name,
        employees: ouvriers.map(o => o.value)
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Equipe créé avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  const removeOuvrierFromList = (ouvrierId) => {
    setOuvriers(prevs => prevs.filter(prev => prev.value !== ouvrierId))
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {team ? "Modifier l'équipe" : 'Ajouter une équipe'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>
          {"Ajouter des ouvriers à associer à l'équipe ci-dessous :"}
        </Text>
        <Stack spacing={5} my='5'>
          <Input name="Nom de l'équipe *" value={name} setValue={setName} />
          <Box border='1px solid #D2D8E0' borderRadius='8px' p='3'>
            <Text fontSize={14} style={{
              marginTop: '-22px',
              background: 'white',
              width: 'fit-content',
              padding: '0px 5px',
              color: '#999'
            }} mb='4'>{"Ouvriers de l'équipe"}</Text>
            <Box mb='6'>
              {ouvriers?.length
                ? ouvriers.map(ouvrier => <Box key={ouvrier.id} mb='3' style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around'
                }}>
                  <HStack position='relative' p='3' width='85%' border='1px solid #D2D8E0' borderRadius='8px'>
                    <Avatar boxSize='8' size='xs' name={ouvrier.label} src={ouvrier?.extendedProps?.picture?.uri ?? null} />
                    <Box align='left'>
                      <Text mt='0.5' fontSize='13px'>{ouvrier.label}</Text>
                      <Text mt='-1' fontSize='11px'>
                        {ouvrier?.extendedProps?.employeePosition?.name ?? ouvrier?.extendedProps?.name ?? '-'}
                        <span style={{ width: '8px', height: '8px', background: ouvrier?.extendedProps?.isAvailable, borderRadius: '50%', position: 'absolute', marginTop: '5px', marginLeft: '5px' }} />
                      </Text>
                    </Box>
                  </HStack>
                  <IconButton variant='ghost' onClick={() => removeOuvrierFromList(ouvrier.value)}><Icon as={FiTrash2} /></IconButton>{/* FiMinus */}
                </Box>)
                : <Text align='center'>Aucun ouvrier</Text>
              }
            </Box>
            <Select async loadOptions={loadOuvriers} value={ouvriers} setValue={setOuvriers} name='Rechercher...' placeholder='Rechercher...' isMulti />
          </Box>
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>
          {team ? 'Mettre à jour' : 'Ajouter'}
        </Button>
        {team && <IconButton onClick={deleteTeam} position='absolute' right='20px'><Icon as={FiTrash2} fontSize="1.25rem" /></IconButton>}
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default Team
