import { useState } from 'react'

import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast, Grid, GridItem } from '@chakra-ui/react'

import { Select, DateTimeInput } from 'components'

import { useAuth, useDatas } from 'contexts'

import moment from 'moment'

const Validate = ({ open, onClose, callback }) => {
  const { callApi } = useAuth()
  const { datas: { companies: companiesOptions, chantiers: chantierOptions, employees: employeesOptions } } = useDatas()
  const toast = useToast()

  const [chantier, setChantier] = useState()

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [employees, setEmployees] = useState([])

  const [companies, setCompanies] = useState([])

  const submit = () => {
    callApi({
      method: 'patch',
      url: `admin/clocking/validate/multiple?start_date=${moment(startDate).format('YYYY-MM-DD')}&end_date=${moment(endDate).format('YYYY-MM-DD')}&construction_site_id=${chantier?.value ?? ''}${employees?.length ? employees.map(e => `&employees[]=${e.value}`).join('') : ''}${companies?.length ? companies.map(c => `&companies[]=${c.value}`).join('') : ''}`,
      data: {
        companies: companies?.length ? companies.map(c => c.value) : null,
        employees: employees?.length ? employees.map(c => c.value) : null,
        construction_site_id: chantier?.value ?? null,
        start_date: moment(startDate).format('YYYY-MM-DD') ?? null,
        end_date: moment(endDate).format('YYYY-MM-DD') ?? null
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || "Feuilles d'heures validées avec succès",
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback()
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>{"Valider les feuilles d'heures"}</Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>{"Validez les feuilles d'heures pour ce chantier en sélectionnant les options ci-dessous :"}</Text>
        <Stack spacing={5} my='5'>
          <Select name='Chantier concerné' value={chantier} setValue={setChantier} options={chantierOptions} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <DateTimeInput name='Début période' value={startDate} setValue={setStartDate} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Fin période' value={endDate} setValue={setEndDate} />
            </GridItem>
          </Grid>
          <Select name='Ouvriers' value={employees} setValue={setEmployees} options={employeesOptions} isMulti />
          <Select name='Société concernée' value={companies} setValue={setCompanies} options={companiesOptions} isMulti />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>{"Valider les feuilles d'heures"}</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default Validate
