import { useEffect, useState, useRef } from 'react'

import { Input, InputGroup, FormControl, FormLabel, InputRightElement, Box, useToast } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'

const MyInput = ({ name, value: mv, setValue: msv, type = 'text', variant = 'solid', step, icon, multiple, defaultFiles, disabled, iconClick, onClick, width, m, min, max, fileListName, style, styleLabel }) => {
  const [filesInfos, setFilesInfos] = useState([])
  const toast = useToast()

  const [value, setValue] = useState(mv)
  useEffect(() => {
    if (mv !== value) setValue(mv)
  }, [mv])

  const timeoutRef = useRef()
  useEffect(() => {
    if (type === 'file') {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => msv(value), 100)
    } else {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = setTimeout(() => msv(value), 500)
    }
  }, [value])

  useEffect(() => {
    setFilesInfos(defaultFiles ?? [])
  }, [defaultFiles])

  const uploadFile = (e) => {
    const fileReader = new FileReader()
    const files = Array.from(e.target.files)

    const readFile = (index) => {
      fileReader.onload = (event) => {
        const MB = 2097152 * 25 // 50Mo
        const blob = new Blob([event.target.result], { type: files[index].type })
        const dToAdd = {
          type: 'blob',
          blob,
          name: files[index].name
        }
        if (blob.size > MB) {
          toast({
            position: 'bottom-right',
            description: 'Le fichier est trop volumineux et ne pourra pas être uploadé',
            status: 'error',
            duration: 5000,
            isClosable: false
          })
        }

        setValue((prevValue = []) => {
          if (!multiple) return dToAdd
          // if (multiple && files.length <= 1) return [dToAdd]
          console.log('prevValue', prevValue)
          console.log('dToAdd', dToAdd)
          return [...(prevValue || []), dToAdd]
        })
        if (files.length > index + 1) readFile(index + 1)
      }

      fileReader.readAsArrayBuffer(files[index])
      setFilesInfos(prevFilesInfos => [...(prevFilesInfos || []), { name: files[index].name, size: files[index].size }])
    }

    readFile(0)
  }

  const removeFile = (index) => {
    setValue(prevValue => {
      if (!multiple || !Array.isArray(prevValue)) return null
      const newValue = [...prevValue]
      newValue.splice(index, 1)
      return newValue.length > 0 ? newValue : null
    })
    setFilesInfos(prevValue => {
      if (!multiple) return []
      const newValue = [...prevValue]
      newValue.splice(index, 1)
      return newValue
    })
  }

  return <>
    {type === 'file' && value && filesInfos
      ? <InputGroup className={multiple ? 'file-result multiple' : 'file-result'} mb={type === 'file' && !multiple && value ? '0' : '4'} width={width} style={style}>
          <FormLabel className='label-form' style={styleLabel}>
            {fileListName ?? name}
            {filesInfos && filesInfos.map((info, index) => (
              <Box key={info.url} display={'flex'} flexDirection={'row'} alignItems="center" justifyContent={'center'}>
                <a href={info.url} target="_blank" rel="noreferrer">
                  <FormLabel style={info.url ? { cursor: 'pointer' } : {}} fontWeight={'normal'} fontSize={'12px'} textAlign='center' bg={'#e0e0e0'} borderRadius="15px" p="2px 10px" m="0">
                    {info.name.substr(0, 5) + '...' + info.name.substr(-5)} {info.size !== 0 ? `(${(info.size / 1024).toFixed(2)} kB) ` : ''}
                  </FormLabel>
                </a>
                {disabled ? '' : <CloseIcon ml='2' color={'#545866'} cursor={'pointer'} boxSize="12px" onClick={() => removeFile(index)} />}
              </Box>
            ))}
          </FormLabel>
        </InputGroup>
      : <></>
    }
    {type === 'file' && !multiple && value
      ? ''
      : <FormControl onClick={onClick} width={width} m={m}>
          <InputGroup m={m} style={style} width={width}>
            <Input
              multiple={multiple}
              data-variant={variant}
              name={name}
              placeholder=" "
              type={type}
              onChange={(e) => type === 'file' ? uploadFile(e) : setValue(e.target.value)}
              value={type === 'file' && filesInfos ? [] : value}
              disabled={disabled}
              min={min}
              max={max}
              step={step}
              padding={type === 'color' ? '7px !important' : ''}
            />
            <FormLabel htmlFor={name} className='label-form' style={styleLabel}>{name}</FormLabel>
            {icon && <InputRightElement height='100%' onClick={iconClick || (() => {})} style={iconClick ? { cursor: 'pointer' } : {}}>
              {icon}
            </InputRightElement>}
          </InputGroup>
        </FormControl>
    }
  </>
}

export default MyInput
