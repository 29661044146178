import { ReactComponent as Chantier } from 'assets/images/icons/chantier.svg'
import { ReactComponent as Chiffre } from 'assets/images/icons/chiffre.svg'
import { ReactComponent as Clocking } from 'assets/images/icons/clocking.svg'
import { ReactComponent as Corbeille } from 'assets/images/icons/corbeille.svg'
import { ReactComponent as Employee } from 'assets/images/icons/employee.svg'
import { ReactComponent as Engins } from 'assets/images/icons/engins.svg'
import { ReactComponent as Facture } from 'assets/images/icons/facture.svg'
import { ReactComponent as PlanningEmployee } from 'assets/images/icons/planning-employee.svg'
import { ReactComponent as PlanningMachine } from 'assets/images/icons/planning-machine.svg'
import { ReactComponent as Setting } from 'assets/images/icons/setting.svg'
import { ReactComponent as Vehicule } from 'assets/images/icons/vehicule.svg'

const Icons = {
  Chantier,
  Chiffre,
  Clocking,
  Corbeille,
  Employee,
  Engins,
  Facture,
  PlanningEmployee,
  PlanningMachine,
  Setting,
  Vehicule
}

export default Icons
