import { Routes, Route, Navigate } from 'react-router-dom'

import { Container } from '@chakra-ui/react'

import ForgotPassword from './ForgotPassword/ForgotPassword'
import Login from './Login/Login'
import ResetPassword from './ResetPassword/ResetPassword'

const GuestRoutes = () => {
  return <Container
    maxW="md"
    height='100%'
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    <Routes>
      <Route path='/guest' element={<Login />} />
      <Route path='/guest/password/request' element={<ForgotPassword />} />
      <Route path='/guest/password/reset/:token' element={<ResetPassword />} />
      <Route path="*" element={<Navigate to='/guest' replace />} />
    </Routes>
  </Container>
}

export default GuestRoutes
