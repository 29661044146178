import { Modal, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, ModalOverlay, useToast, Stack, HStack } from '@chakra-ui/react'
import { Input, Select } from 'components'
import { useEffect, useState } from 'react'
import { useAuth } from 'contexts'

const EditTask = ({ open, onClose, taskSelected, categorySelected, categoriesList, tasksList, chantier, reloadData, tasks }) => {
  const { callApi } = useAuth()
  const toast = useToast()
  const [category, setCategory] = useState()
  const [measure, setMeasure] = useState()
  const [label, setLabel] = useState()
  const [hoursPlanned, setHoursPlanned] = useState()
  const [quantityPlanned, setQuantityPlanned] = useState()

  useEffect(() => {
    if (categorySelected) {
      setCategory({ value: categorySelected.id, label: categorySelected.title })
    }
    if (taskSelected?.id) {
      const taskSetByDefault = tasksList.filter((taskList) => taskList.label === taskSelected.label)
      setLabel(taskSetByDefault[0])
    }
    setHoursPlanned(taskSelected?.hours_planned ?? '')
    setQuantityPlanned(taskSelected?.quantity_planned ?? '')
  }, [taskSelected, categorySelected])

  useEffect(() => {
    if (label && tasks) {
      const taskSet = tasks?.filter((task) => task.label === label.label)[0]
      setMeasure(taskSet?.measure.unit)
    }
  }, [label])

  const submit = () => {
    callApi({
      method: taskSelected?.id ? 'put' : 'post',
      url: taskSelected?.id ? `admin/construction-sites/${chantier}/tasks/${taskSelected.id}` : `admin/construction-sites/${chantier}/tasks`,
      data: {
        task: label.value,
        chapter: category.value,
        quantity_planned: Number(quantityPlanned),
        hours_planned: Number(hoursPlanned)
      }
    }).then(res => {
      toast({
        position: 'bottom-right',
        description: res?.data?.message || taskSelected?.id ? `${taskSelected.label} modifié avec succès` : 'Tâche ajoutée avec succès',
        status: 'success',
        duration: 5000
      })
      reloadData()
      onClose()
    })
  }

  return (
      <Modal
      blockScrollOnMount={true}
      scrollBehavior="inside"
      size="3xl"
      isOpen={open}
      onClose={onClose}>
        <ModalOverlay />
      <ModalContent
        w={{
          base: '95%',
          md: '50%',
          lg: '45%'
        }}
      >
          <ModalHeader>
              <Text>
                  {taskSelected?.id ? 'Modifier la tâche' : 'Créer une tâche'}
              </Text>
              <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
              <Text fontSize={14} mb='1.5rem'>
              {taskSelected?.id ? ' Pour modifier une tâche, modifez les informations correspondantes ci-dessous :' : ' Pour créer une nouvelle tâche, entrez les informations correspondantes ci-dessous :'}
              </Text>
              <Stack spacing={6} my='5'>
                  <Select name='Dossier Parent' value={category} setValue={setCategory} options={categoriesList}></Select>
                  <Select name='Tâche concernée' value={label} setValue={setLabel} options={tasksList}>

                    </Select>
                  <HStack>
                  <Input name='Heures prévues' value={hoursPlanned} setValue={setHoursPlanned} type='number'></Input>
                  <div style={{
                    width: '20%',
                    textAlign: 'left',
                    border: 'solid var(--chakra-colors-chakra-border-color)',
                    borderRadius: '5px',
                    padding: '10px'
                  }}><Text>H</Text></div>
                  </HStack>
                  <HStack>
                  <Input name='Quantités prévues' value={quantityPlanned} setValue={setQuantityPlanned} type='number'></Input>
                  <div style={{
                    width: '20%',
                    textAlign: 'left',
                    border: 'solid var(--chakra-colors-chakra-border-color)',
                    borderRadius: '5px',
                    padding: '10px',
                    alignSelf: 'stretch'
                  }}><Text>{measure}</Text></div>
                  </HStack>
              </Stack>
          </ModalBody>
          <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
          <Button data-variant='solid' mr='8' onClick={submit} disabled={!quantityPlanned || !hoursPlanned}>
            Valider
          </Button>
          <Button data-variant='outline' ml='8' onClick={onClose}>
            Annuler
          </Button>
        </ModalFooter>
      </ModalContent>

  </Modal>
  )
}

export default EditTask
