
import { Flex, useBreakpointValue, Container } from '@chakra-ui/react'

import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Topbar from './components/TopBar'

import './Admin.css'

const Admin = ({ children }) => {
  const isDesktop = useBreakpointValue({
    base: false,
    md: false,
    lg: true
  })

  return <Flex as="main"direction={{ base: 'column', lg: 'row' }} bg="bg-canvas" overflowY="auto">
		{isDesktop ? <Sidebar /> : <Navbar />}
		{isDesktop ? <Topbar /> : ''}
		<Container maxWidth='100%' width='100%' height='100%' overflow="hidden" mt='80px' p={isDesktop ? '24px 24px 24px calc(15rem + 24px)' : '24px'}>
			{children}
		</Container>
	</Flex>
}

export default Admin
