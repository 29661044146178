import { Box, Icon, Text } from '@chakra-ui/react'

import { FaCamera } from 'react-icons/fa'
import { IoIosClose } from 'react-icons/io'

import ImageUploading from 'react-images-uploading'

const PictureInput = ({ picture, setValue, onRemove, disabled }) => {
  if (!picture && disabled) { return '' }

  return <ImageUploading
  value={picture ? [picture] : []}
  onChange={setValue}
  maxNumber={10}
  dataURLKey="data_url"
>
  {({
    imageList,
    onImageUpload,
    onImageUpdate,
    isDragging,
    dragProps
  }) => (
    <Box
      _hover={{ backgroundColor: '#eee', cursor: 'pointer', transition: 'all .5s', borderRadius: '8px' }}
      onClick={onImageUpload}
      style={disabled ? { pointerEvents: 'none', display: 'flex' } : isDragging ? { backgroundColor: '#eee', display: 'flex' } : { display: 'flex' }}
      {...dragProps}
    >
      <div style={{
        position: 'relative',
        width: '100px',
        height: '100px',
        borderRadius: '50%'
      }}>
        <Icon as={FaCamera} color="#8d8989" fontSize="1.5rem" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '24px'
        }} />
        {imageList.length > 0
          ? imageList.map((image, index) => <span key={index}>
              {!disabled && <Icon onClick={(e) => {
                onRemove()
                e.stopPropagation()
              }} as={IoIosClose} background="#f1f1f4" borderRadius={'50%'} color="#8d8989" fontSize="1.5rem" position="absolute" style={{ zIndex: 1 }} />}
              <div onClick={() => onImageUpdate(index)}>
                <img key={index} src={image.data_url} alt="" style={{
                  position: 'relative',
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%'
                }} />
              </div>
            </span>)
          : <div style={{ backgroundColor: '#eee', width: '100px', height: '100px', borderRadius: '50%' }}/>
        }
      </div>

      <Box marginInlineStart={'1.5rem !important'} style={{ display: disabled ? 'none' : 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'center' }}>
        <Text fontWeight='500'>Ajouter une photo</Text>
        <Text>Max 5Mo</Text>
      </Box>
    </Box>
  )}
</ImageUploading>
}

export default PictureInput
