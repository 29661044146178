import { useEffect, useState } from 'react'
import { useAuth, useDatas } from 'contexts'
import { Modal, ModalContent, ModalCloseButton, ModalBody, ModalHeader, Text, ModalOverlay, Avatar, HStack, Box } from '@chakra-ui/react'
import { Card } from 'components'
import moment from 'moment'
import { convertMinToHour } from '../../../../../helpers/utils'

const HistoricalModal = ({ open, onClose, chantierId, taskSelected }) => {
  const { callApi } = useAuth()
  const { datas: { decimalVentilation } } = useDatas()
  const [clockingTask, setClockingTask] = useState()

  useEffect(() => {
    callApi({
      method: 'get',
      url: `admin/construction-sites/${chantierId}/tasks/${taskSelected.id}/clockings`
    }).then(res => {
      if (!res) return
      setClockingTask(res.data.data)
    })
  }, [])

  const translateDurationToHuman = (value) => {
    if (decimalVentilation) {
      return parseFloat(Math.round(value / 60 * 100) / 100).toFixed(2).replace('.', ',') + 'h'
    }

    return convertMinToHour(value, 'h:mm')
  }

  return (
    <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="3xl"
    isOpen={open}
    onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        w={{
          base: '95%',
          md: '50%',
          lg: '45%'
        }}
      >
        <ModalHeader>
          <Text> Historique</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody mb='3'>
            <Card p='5'>
              {clockingTask && clockingTask.map((clockTask) => {
                return (
                  <HStack mb='5' key={clockTask.id} width='100%'>
                    <Avatar boxSize='14' mr='1' size='md' name={clockTask?.clockingEntry?.createdBy ?? '-'} src={clockTask?.clockingEntry.picture?.uri ?? null} />
                    <Box style={{ marginRight: '50px' }} align='left'>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Text fontSize={11}>🙍‍♂️ Créé par : <b> {clockTask?.clockingEntry?.createdBy ?? '-'} </b></Text>
                      </Box>
                      <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Text fontSize={11}>🗓 Créé à : <b>{clockTask?.clockingEntry?.createdAt ? moment.utc(clockTask?.clockingEntry?.createdAt).local().format('DD/MM/YYYY HH:mm:ss') : '-'}</b></Text>
                      </Box>
                    </Box>
                    <Box style={{ marginRight: '50px' }}>
                      <Text fontSize={15}>{taskSelected?.label}</Text>
                    </Box>
                    <Box py='3' px='7' style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f5f5f5', borderRadius: '3px' }}>
                      <Box mr='3'>
                      <Text style={{ display: 'flex', alignItems: 'center' }}>{clockTask?.quantity} {taskSelected.unit}</Text>
                      </Box>
                      <Box>
                      <Text>{translateDurationToHuman(clockTask?.duration ?? 0)}</Text>
                      </Box>
                    </Box>
                  </HStack>
                )
              })}
            </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default HistoricalModal
