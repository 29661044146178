import { Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, ModalHeader, Button, Text, Stack, useToast } from '@chakra-ui/react'

import { Input } from 'components'

import { useAuth } from 'contexts'

const ShowCorbeille = ({ open, onClose, corbeille, callback = () => {}, viewOnly }) => {
  const { callApi } = useAuth()
  const toast = useToast()

  const submit = () => {
    callApi({
      method: 'post',
      url: 'admin/trash/restore',
      data: {
        id: corbeille.id,
        type: corbeille.type
      }
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || "L'élément a été restauré avec succès",
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          Restaurer cet élément
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      <ModalBody>
        <Text fontSize={14} mb='1.5rem'>
          Voulez-vous vraiment restaurer cet élément ?
        </Text>
        <Stack spacing={5} my='5'>
          <Input name='Nom' value={corbeille?.label} disabled={viewOnly} />
          <Input name='Type' value={corbeille?.type_label} disabled={viewOnly} />
        </Stack>
      </ModalBody>
      <ModalFooter zIndex={12} justifyContent="space-around" borderTopWidth={'1px'}>
        <Button data-variant='solid' onClick={submit}>
          Restaurer
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default ShowCorbeille
