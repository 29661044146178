import { useEffect, useState } from 'react'

import { Icon, Grid, GridItem } from '@chakra-ui/react'
import { FiMinus } from 'react-icons/fi'

import { Select } from 'components'

import AddReferent from './modals/AddReferent'

import { useAuth } from 'contexts'

const ReferentSelect = ({ actorTypeOptions, defaultActor, setActor, onDelete, cleanActor, viewOnly }) => {
  const { callApi } = useAuth()
  const [type, setType] = useState(defaultActor ? actorTypeOptions.filter(actorTypeOption => actorTypeOption.value === defaultActor.type)[0] : null)
  const [referent, setReferent] = useState()
  const [referentOptions, setReferentOptions] = useState([])
  const [openAddReferent, setOpenAddReferent] = useState(false)

  useEffect(() => {
    setActor(type?.value, referent?.value)
  }, [type, referent])

  const getReferents = () => {
    callApi({
      method: 'get',
      url: `admin/search/models/construction-actor-referents-admin?search=&filter=${type.value}`
    })
      .then(res => {
        if (!res) return
        if (referentOptions.length === 0 && res?.data?.data.length && defaultActor?.referent && res.data.data.filter(referent => referent.id === defaultActor.referent).length) setReferent(res.data.data.filter(referent => referent.id === defaultActor.referent)[0])
        setReferentOptions(res?.data?.data?.map(data => ({ value: data.id, label: data.label })) || [])
      })
  }

  useEffect(() => {
    if (!type) setReferentOptions([])
    else {
      getReferents()
    }
  }, [type])

  useEffect(() => {
    if (!referentOptions.length) return
    setReferent(referentOptions.filter(referent => referent.id === defaultActor.referent)[0])
  }, [defaultActor])

  useEffect(() => {
    if (referent?.value === 'add') {
      setOpenAddReferent(true)
    }
  }, [referent])

  return <Grid templateColumns='repeat(10, 1fr)' gap={6}>
    <GridItem colSpan={5}>
      <Select value={type} options={actorTypeOptions} setValue={setType} placeholder='Type' isDisabled={viewOnly || defaultActor?.disabledEditType} />
    </GridItem>
    <GridItem colSpan={/* defaultActor?.disabledEditType ? 5 : */ 4} >
      <Select value={referent} options={referentOptions} setValue={setReferent} placeholder='Referent' addOption={Boolean(type)} isDisabled={viewOnly} />
      {!viewOnly && <AddReferent open={openAddReferent} onClose={() => { setOpenAddReferent(false); setReferent(null) }} callback={(newReferent) => { getReferents(); setReferent(newReferent); setOpenAddReferent(false) }} />}
    </GridItem>
    {!viewOnly && <GridItem colSpan={1} style={{
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
    }}>
      {!defaultActor?.disabledEditType
        ? <Icon as={FiMinus} fontSize="1rem" zIndex={999} cursor="pointer" onClick={onDelete} />
        : <Icon as={FiMinus} fontSize="1rem" zIndex={999} cursor="pointer" onClick={() => { setReferent(null); cleanActor() }} />
      }
    </GridItem>}
  </Grid>
}

export default ReferentSelect
