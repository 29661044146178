import { useState, useMemo, memo } from 'react'

import { Button, Box, Text, Avatar, HStack, Tabs, TabPanels, Tab, TabPanel, TabList, Progress } from '@chakra-ui/react'

import { Input, OutputSelect } from 'components'

import { useFilters } from '../../contexts/filters'
import { useModals, useDatas } from 'contexts'

import Team from '../components/team'

const TeamsTabPanel = () => {
  const {
    datas: {
      teams
    },
    reload: {
      teams: reloadTeams
    }
  } = useDatas()
  const { openTeamModal } = useModals()

  const [searchTeam, setSearchTeam] = useState('')
  const filteredTeams = useMemo(() => {
    const iLow = searchTeam.toLowerCase()
    return !iLow.length
      ? teams
      : teams.filter(team => {
        return team.name.toLowerCase().includes(iLow) ||
        team.employees.map(ouvrier => (`${ouvrier.first_name} ${ouvrier.last_name} ${ouvrier.first_name}`).toLowerCase()).filter(ouvrierName => ouvrierName.includes(iLow)).length > 0
      })
  }, [searchTeam])

  return <TabPanel>
    <Input name='Rechercher...' value={searchTeam} setValue={setSearchTeam} />
    <Box id='fullCalendarExternalItems4' my='4'>
      {filteredTeams.length
        ? filteredTeams.map(team => <Box
          key={team.id}
          className='fc-event'
          data-team-id={team.id}
          data-id={team.employees.map(e => e.id).join(',')}
          data-label={team.name}
          data-color='#ff5464'
          data-extendedprops={JSON.stringify(team)}
        >
          <Team team={team} dragAllow={false} />
        </Box>)
        : <Text>Aucune équipe</Text>
      }
    </Box>
    <Button data-variant='solid' width='100%' onClick={() => openTeamModal({
      callback: reloadTeams,
      callbackDelete: reloadTeams
    })}><Text fontSize='14'>Ajouter une équipe</Text></Button>
  </TabPanel>
}

const ChantiersSidebar = ({ openEmployeeModal, addButton }) => {
  const {
    options: {
      companies: companiesOptions
    },
    datas: {
      companies
    }
  } = useFilters()
  const {
    datas: {
      disponibilitiesAll,
      positions
    }
  } = useDatas()

  const [loading, setLoading] = useState(false)

  const [ouvriersSearch, setOuvriersSearch] = useState('')
  const [ouvriersPositions, setOuvriersPositions] = useState([])
  const [ouvriersCompanies, setOuvriersCompanies] = useState([])
  const [ouvriersDispo, setOuvriersDispo] = useState({ value: 'all', label: 'Toutes les disponibilités' })

  const filteredOuvriers = useMemo(() => {
    const ouvriersPositionsValues = ouvriersPositions.map(p => p.value)
    const ouvriersCompaniesValues = ouvriersCompanies.map(p => p.value)
    const iLow = ouvriersSearch.toLowerCase()

    setLoading(true)
    const newCompanies = companies
      .filter(company => !ouvriersCompaniesValues.length || ouvriersCompaniesValues.includes(company?.id))
      .map(company => {
        return {
          ...company,
          employees: company.employees
            .filter(employee => (ouvriersDispo.value === 'all' || employee.isAvailable === ouvriersDispo.value) &&
              (!ouvriersPositionsValues.length || ouvriersPositionsValues.includes(employee.employeePosition?.id)) &&
              (!iLow.length || (`${employee.firstName} ${employee.lastName} ${employee.firstName}`).toLowerCase().includes(iLow))
            )
        }
      })
      .filter(company => company.employees.length)
    setLoading(false)
    return newCompanies
  }, [companies, ouvriersPositions, ouvriersCompanies, ouvriersDispo, ouvriersSearch])

  return <>
      <Box style={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end', border: '1px solid #E2E8F0', borderLeft: 0 }} pr='4'>
        <Button data-variant='solid' onClick={addButton}>Ajouter</Button>
      </Box>
      <Box style={{ height: 'calc(100% - 80px)', overflow: 'auto' }} position='relative'>
        {loading && <Progress className='progressLoader' size='xs' isIndeterminate />}
        <Tabs align="center">
          <Box width='100%' style={{ padding: '5px 0 5px 0' }}>
            <TabList width='fit-content'>
              <Tab className='sidebartab'>Ouvrier</Tab>
              <Tab className='sidebartab'>Equipe</Tab>
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel>
              <HStack flexDirection='column' textAlign='left' gap='10px' align='flex-start'>
                <Box style={{ marginInlineStart: '0.5rem' }}>
                  <OutputSelect name='Sociétés' value={ouvriersCompanies} setValue={setOuvriersCompanies} options={companiesOptions} multiple />
                  <OutputSelect name='Postes' value={ouvriersPositions} setValue={setOuvriersPositions} options={positions} multiple />
                  <OutputSelect name='Disponibilités' value={ouvriersDispo} setValue={setOuvriersDispo} options={disponibilitiesAll} />
                </Box>
                <Input name='Rechercher...' value={ouvriersSearch} setValue={setOuvriersSearch} mb='3' />
                <Box id='fullCalendarExternalItems3' width='100%' style={{ marginInlineStart: 0 }}>
                  {filteredOuvriers
                    .map(company => <HStack key={company.id} mb='3' width='100%' flexDirection='column'>
                      <Text fontWeight='bold' fontSize='14px' mb='4'>{company.name}</Text>
                      <Box align='center' width='100%' style={{ marginInlineStart: 0 }}>
                        {company.employees
                          .map(employee => <HStack key={employee.id} position='relative' mb='3' p='3' width='100%' onClick={() => openEmployeeModal(employee)} style={{
                            cursor: 'pointer',
                            border: '1px solid #d1d2e1',
                            boxShadow: '0 2px 4px 0 rgb(0 0 0 / 8%)',
                            borderRadius: '8px',
                            background: '#fff'
                          }} className='fc-event' data-id={employee.id} data-label={employee.fullName} data-color='#ff5464' data-extendedprops={JSON.stringify(employee)}>
                            <Avatar boxSize='8' size='xs' name={employee.fullName} src={employee?.picture?.uri ?? null} />
                            <Box align='left'>
                              <Text mt='0.5' fontSize='13px'>{employee.fullName}</Text>
                              <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Text mt='-1' fontSize='11px'>{employee.employeePosition?.name ?? '-'}</Text>
                                <span style={{ width: '8px', height: '8px', background: employee?.isAvailable, borderRadius: '50%', marginLeft: '5px' }} />
                              </Box>
                            </Box>
                          </HStack>)}
                      </Box>
                    </HStack>)}
                </Box>
              </HStack>
            </TabPanel>
            <TeamsTabPanel />
          </TabPanels>
        </Tabs>
      </Box>
  </>
}

export default memo(ChantiersSidebar)
