import { useState } from 'react'

import Moment from 'moment'

import { Box, Text, Icon, Button, useToast, Avatar } from '@chakra-ui/react'
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi'

import { useAuth, useModals } from 'contexts'

import { Table } from 'components'
import AddVehicule from './components/modals/AddVehicule'

const columns = [
  {
    key: 'id',
    name: 'id',
    display: (value, _data) => value,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'brand',
    name: 'nom',
    display: (value, data) => <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Avatar name={value} src={data?.picture?.uri} size='sm' mr='4' style={{
        height: '35px !important',
        minWidth: '35px !important',
        width: '35px !important'
      }}/>
      {value ?? ' - '}
    </Box>,
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'immatriculation',
    name: 'immatriculation',
    display: (value) => value ?? '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'passenger_count',
    name: 'places',
    display: (_value, data) => data?.passengerCount ?? ' - ',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'current_construction_site',
    name: 'chantier actuel',
    display: (_value, data) => data?.currentConstructionSite?.name ?? '-',
    canSort: true,
    displayByDefault: true
  },
  {
    key: 'insurance_date',
    name: 'assurance',
    display: (_value, { insuranceDate }) => {
      if (!insuranceDate) return '-'
      const date = Moment(insuranceDate)
      const now = Moment()
      return <Text color="muted" className='container-text'>
        <span style={{ minWidth: '10px', minHeight: '10px', background: date > now ? '#1bc181' : '#de4433', borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
        {date > now ? 'À jour' : 'Expiré'}
      </Text>
    },
    displayByDefault: true
  },
  {
    key: 'technical_control_date',
    name: 'ct',
    display: (_value, { technicalControlDate }) => {
      if (!technicalControlDate) return '-'
      const date = Moment(technicalControlDate)
      const now = Moment()
      return <Text color="muted" className='container-text'>
        <span style={{ minWidth: '10px', minHeight: '10px', background: date > now ? '#1bc181' : '#de4433', borderRadius: '50%', marginRight: '5px', display: 'inline-block', cursor: 'default' }} />
        {date > now ? 'À jour' : 'Expiré'}
      </Text>
    },
    displayByDefault: true
  }
]

const Vehicules = () => {
  const { callApi } = useAuth()
  const { openConfirmToDeleteModal } = useModals()
  const toast = useToast()

  const [vehicules, setVehicules] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [modalVehicule, setModalVehicule] = useState()
  const [modalCallback, setModalCallback] = useState()
  const [modalViewOnly, setModalViewOnly] = useState(false)

  const getVehicules = ({ currentPage, rowsPerPage, sortBy, sortDir, searchInput }, callback) => {
    callApi({
      method: 'get',
      url: `admin/vehicles?page=${currentPage}&perPage=${rowsPerPage}&sort[${sortBy}]=${sortDir}&search=${encodeURIComponent(searchInput)}`
    })
      .then(res => {
        if (!res) return
        callback(res)
        setVehicules(res?.data?.data)
        setModalVehicule(res?.data?.data)
      })
  }

  const addVehicule = (callback) => {
    setModalOpen(true)
    setModalViewOnly(false)
    setModalCallback(() => callback)
    setModalVehicule(null)
  }

  const editVehicule = (vehicule, callback) => {
    setModalOpen(true)
    setModalViewOnly(false)
    setModalVehicule(vehicule)
    setModalCallback(() => callback)
  }

  const viewVehicule = (vehicule, callback) => {
    setModalOpen(true)
    setModalViewOnly(true)
    setModalVehicule(vehicule)
    setModalCallback(() => callback)
  }

  const deleteVehicule = (vehicule, callback) => {
    callApi({
      method: 'delete',
      url: `admin/vehicles/${vehicule.id}`
    })
      .then((res) => {
        toast({
          position: 'bottom-right',
          description: 'Ce véhicule a été bien supprimé.',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
      })
  }

  const deleteVehicules = async (selectedVehicules, callback) => {
    if (!selectedVehicules.length) return
    openConfirmToDeleteModal({
      label: 'Véhicules',
      items: vehicules.filter(i => selectedVehicules.includes(i.id)).map(i => ({ id: i.id, label: i.brand })),
      confirm: () => callApi({
        method: 'delete',
        url: 'admin/vehicles/delete/multiple',
        data: {
          ids: selectedVehicules
        }
      })
        .then(res => {
          if (!res) return
          toast({
            position: 'bottom-right',
            description: res?.data?.message || 'Véhicules supprimés avec succès',
            status: 'success',
            duration: 5000,
            isClosable: false
          })
          callback(res)
        })
    })
  }

  const actions = (vehicule, callback) => {
    return <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      <Button variant="ghost" aria-label="Show member" className='icon-btn' onClick={() => viewVehicule(vehicule, callback)} m='0' p='0'>
        <Icon as={FiEye} fontSize="1.25rem" color="#8186aa" />
      </Button>
      <Button variant="ghost" aria-label="Edit member" className='icon-btn' onClick={() => editVehicule(vehicule, callback)} m='0' p='0'>
        <Icon as={FiEdit2} fontSize="1.25rem" color="#8186aa" />
      </Button>
      <Button variant="ghost" aria-label="Delete member" className='icon-btn' onClick={() => openConfirmToDeleteModal({
        label: 'Véhicules',
        items: [{ id: vehicule.id, label: vehicule.brand }],
        confirm: () => deleteVehicule(vehicule, callback)
      })} m='0' p='0'>
        <Icon as={FiTrash2} fontSize="1.25rem" color="#8186aa" />
      </Button>
    </Box>
  }

  return <Box>
    <Table getDatas={getVehicules} canBeSelected={true} canSearch={true} actions={actions} columns={columns} addOnClick={addVehicule} deleteSelectedRowsOnClick={deleteVehicules} defaultSortDir='desc' />
    <AddVehicule open={modalOpen} onClose={() => setModalOpen(false)} vehicule={modalVehicule} callback={modalCallback} viewOnly={modalViewOnly} />
  </Box>
}

export default Vehicules
