import { useMemo } from 'react'
import { Text } from '@chakra-ui/react'
import { useAuth } from '../contexts'

export const useDisponibilities = () => {
  const { user } = useAuth()

  return useMemo(() => (
    user && user.roles.filter(role => role.name === 'admin').length >= 0
      ? [
          {
            value: '#1BC181',
            label: <Text pr='14px'>
                      Non disponible
                      <span style={{ width: '8px', height: '8px', background: '#1BC181', borderRadius: '50%', position: 'absolute', marginTop: '8px', marginLeft: '6px' }} />
                  </Text>
          },
          // {
          //   value: '#FCC35B',
          //   label: <Text pr='14px'>
          //     Bientôt disponible
          //     <span style={{ width: '8px', height: '8px', background: '#FCC35B', borderRadius: '50%', position: 'absolute', marginTop: '8px', marginLeft: '6px' }} />
          //   </Text>
          // },
          {
            value: '#DE4433',
            label: <Text pr='14px'>
                      Disponible
                      <span style={{ width: '8px', height: '8px', background: '#DE4433', borderRadius: '50%', position: 'absolute', marginTop: '8px', marginLeft: '6px' }} />
                  </Text>
          }
        ]
      : [
          {
            value: '#1BC181',
            label: <Text pr='14px'>
            Non disponible
            <span style={{ width: '8px', height: '8px', background: '#1BC181', borderRadius: '50%', position: 'absolute', marginTop: '8px', marginLeft: '6px' }} />
          </Text>
          }
        ]
  ), [user])
}
