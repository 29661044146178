import { useEffect, useState, memo } from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
  Stack,
  Grid,
  GridItem,
  useToast,
  Checkbox, CheckboxGroup
} from '@chakra-ui/react'

import { isValidPhoneNumber } from 'react-phone-number-input'

import { PictureInput, Input, Select, AddressInput, PhoneInput, Textarea, DateTimeInput } from 'components'

import { /* getBlobFromUrl, */ fileToBePost } from 'helpers/utils'

import { useAuth, useDatas } from 'contexts'

import moment from 'moment'

const AddEmployee = ({ open, onClose, employee, callback = () => {}, viewOnly }) => {
  const { callApi } = useAuth()
  const [submitting, setSubmitting] = useState(false)
  const { datas: { companies: companyOptions, licenses: licensesOptions, positions: positionOptions, genders: genderOptions, contracts: contractOptions, countries: nationalityOptions }, reload: { employees: reloadEmployees } } = useDatas()

  const toast = useToast()
  const [picture, setPicture] = useState(undefined)
  const [matricule, setMatricule] = useState(undefined)
  const [firstName, setFirstName] = useState(undefined)
  const [lastName, setLastName] = useState(undefined)
  const [phone, setPhone] = useState(undefined)
  const [email, setEmail] = useState(undefined)
  const [addressValues, setAddressValues] = useState(undefined)
  const [position, setPosition] = useState(undefined)
  const [gender, setGender] = useState(undefined)
  const [nationality, setNationality] = useState(undefined)
  const [birthDate, setBirthDate] = useState(null)
  const [contractType, setContractType] = useState(undefined)
  const [contractEndDate, setContractEndDate] = useState(null)
  const [company, setCompany] = useState(undefined)
  const [idCardFile, setIdCardFile] = useState(undefined)
  const [idCardExpiredDate, setIdCardExpiredDate] = useState(null)
  const [constructionCardFile, setConstructionCardFile] = useState(undefined)
  const [constructionCardExpiredDate, setConstructionCardExpiredDate] = useState(null)
  const [hourlyRate, setHourlyRate] = useState(undefined)
  const [transportAmount, setTransportAmount] = useState(undefined)
  const [coefficient, setCoefficient] = useState(undefined)
  const [contract, setContract] = useState(undefined)
  const [others, setOthers] = useState(undefined)
  const [licenses, setLicenses] = useState([])
  const [otherLicenses, setOtherLicenses] = useState(undefined)
  const [observation, setObservation] = useState(undefined)
  const [zoneByPersonalAddress, setZoneByPersonalAddress] = useState(undefined)

  const [idCardFileDefault, setIdCardFileDefault] = useState([])
  const [constructionCardFileDefault, setConstructionCardFileDefault] = useState([])
  const [contractDefault, setContractDefault] = useState([])
  const [othersDefault, setOthersDefault] = useState([])
  const [otherLicensesDefault, setOtherLicensesDefault] = useState([])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    setPicture(employee?.picture?.uri ? { data_url: employee.picture.uri } : '')
    setMatricule(employee?.registrationNumber ?? '')
    setFirstName(employee?.firstName ?? '')
    setLastName(employee?.lastName ?? '')
    setPhone(employee?.phone ?? '')
    setEmail(employee?.email ?? '')
    setPosition(employee?.employeePosition ? { value: employee.employeePosition.id, label: employee.employeePosition.name } : '')
    setGender(employee?.gender && genderOptions.map(gender => gender.value).includes(employee.gender) ? { value: employee.gender, label: employee.gender } : '')
    setNationality(employee?.nationality ? { value: employee.nationality.id, label: employee.nationality.name } : '')
    setBirthDate(employee?.birthDate ? moment(employee?.birthDate).format('YYYY-MM-DD') : null)
    setContractType(employee?.contract?.type ? { value: employee.contract.type, label: employee.contract.type } : '')
    setContractEndDate(employee?.contract?.endDate ? moment(employee?.contract?.endDate).format('YYYY-MM-DD') : null)
    setCompany(employee?.company ? { value: employee.company.id, label: employee.company.name } : '')
    setZoneByPersonalAddress(employee?.zoneByPersonalAddress)

    if (employee?.ci) {
      // setIdCardFile(employee.ci)
      setIdCardFile(true)
      // getBlobFromUrl(employee.ci).then(res => setIdCardFile(res))
      setIdCardFileDefault([{ name: "Carte d'identité", size: 0, url: employee.ci }])
    } else {
      setIdCardFile('')
      setIdCardFileDefault([])
    }
    setIdCardExpiredDate(employee?.idCardExpiredDate ? moment(employee?.idCardExpiredDate).format('YYYY-MM-DD') : null)
    if (employee?.btp) {
      // setConstructionCardFile(employee.btp)
      setConstructionCardFile(true)
      // getBlobFromUrl(employee.btp).then(res => setConstructionCardFile(res))
      setConstructionCardFileDefault([{ name: 'Carte BTP', size: 0, url: employee.btp }])
    } else {
      setConstructionCardFile('')
      setConstructionCardFileDefault([])
    }
    setConstructionCardExpiredDate(employee?.constructionCardExpiredDate ? moment(employee?.constructionCardExpiredDate).format('YYYY-MM-DD') : null)
    setHourlyRate(employee?.hourlyRate ?? '')
    setTransportAmount(employee?.transportAmount ?? '')
    setCoefficient(employee?.coefficient ?? '')
    if (employee?.contractUrl && !Array.isArray(employee?.contractUrl)) {
      // setContract(employee.contractUrl.full_url)
      setContract(true)
      // getBlobFromUrl(employee.contractUrl.full_url).then(res => setContract(res))
      setContractDefault([{ name: `${employee.contractUrl.name}.${employee.contractUrl.extension}`, size: 0, url: employee.contractUrl.full_url }])
    } else {
      setContract('')
      setContractDefault([])
    }
    if (employee?.others?.length) {
      // setOthers(employee.others.map(e => e.full_url))
      // employee.others.forEach(other => getBlobFromUrl(other.full_url).then(res => setOthers(prevOther => [...(prevOther || []), { ...other, ...res }])))
      setOthers(employee.others.map(e => ({ path: e.path })))
      setOthersDefault(employee.others.map(file => ({ name: `${file.name}.${file.extension}`, size: parseFloat(file.size) * 1024, url: file.full_url })))
    } else {
      setOthers('')
      setOthersDefault([])
    }
    setLicenses((employee?.licenses ?? [])?.map(license => ({ value: license.id, label: license.name })))
    if (employee?.otherLicenses?.length) {
      // setOtherLicenses(employee.otherLicenses.map(e => e.full_url))
      // employee.otherLicenses.forEach(other => getBlobFromUrl(other.full_url).then(res => setOtherLicenses(prevOther => [...(prevOther || []), { ...other, ...res }])))
      setOtherLicenses(employee.otherLicenses.map(e => ({ path: e.path })))
      setOtherLicensesDefault(employee.otherLicenses.map(file => ({ name: `${file.name}.${file.extension}`, size: parseFloat(file.size) * 1024, url: file.full_url })))
    } else {
      setOtherLicenses('')
      setOtherLicensesDefault([])
    }
    setObservation(employee?.observation ?? '')
    setLoading(false)
  }, [employee, open])

  const submitValidate = () => {
    callApi({
      method: 'patch',
      url: `admin/employee/validate/${employee.id}`
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || 'Le nouvel employé a bien été validé',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        callback(res)
        onClose()
      })
  }

  const submit = () => {
    const othersPath = (others || [])
      .filter(other => other.path)
      .map(other => other.path) ?? []
    const othersToDelete = (employee?.others || [])
      .filter(other => !othersPath.includes(other.path))
      .map(other => other.path.split('/')[other.path.split('/').length - 1])
    const otherLicensesPath = (otherLicenses || [])
      .filter(otherLicense => otherLicense.path)
      .map(otherLicense => otherLicense.path) ?? []
    const otherLicensesToDelete = (employee?.otherLicenses || [])
      .filter(otherLicense => !otherLicensesPath.includes(otherLicense.path))
      .map(otherLicense => otherLicense.path.split('/')[otherLicense.path.split('/').length - 1])
    setSubmitting(true)
    callApi({
      method: 'post',
      url: employee ? `admin/employee/${employee.id}` : 'admin/employee',
      formData: true,
      data: Object.assign(
        {
          first_name: firstName,
          last_name: lastName,
          email: email,
          zone_by_personal_address: zoneByPersonalAddress ? 1 : 0,
          ...addressValues
        },
        employee ? { _method: 'put' } : {},
        isValidPhoneNumber(phone) ? { phone_number: phone } : {},

        // CI
        idCardFile && (!employee?.ci || idCardFile?.blob) ? { ci: idCardFile, deleteCi: 1 } : {},
        (!idCardFile || idCardFile?.blob) && employee?.ci ? { deleteCi: 1 } : {},
        // Avatar
        (picture && (!employee || picture?.data_url !== employee?.picture?.uri)) ? { avatar: fileToBePost(picture.data_url), deleteAvatar: 1 } : {},
        (!picture && (employee?.picture?.uri)) ? { deleteAvatar: 1 } : {},
        // BTP
        constructionCardFile && (!employee?.btp || constructionCardFile?.blob) ? { btp: constructionCardFile, deleteBtp: 1 } : {},
        (!constructionCardFile || constructionCardFile?.blob) && employee?.btp ? { deleteBtp: 1 } : {},
        // Contract
        contract && (!employee?.contractUrl || Array.isArray(employee?.contractUrl) || contract?.blob) ? { contract: contract, deleteContract: 1 } : {},
        !contract && employee?.contractUrl && !Array.isArray(employee?.contractUrl) ? { deleteContract: 1 } : {},

        // Others multiple
        others && others.filter(other => other.blob).length ? { other: others.filter(other => other.blob) } : {},
        othersToDelete.length ? { deleteOther: othersToDelete } : {},
        // employee.others.length && employee.others.filter(other => !others.map(o => o.path).includes(other.path)).length ? { deleteOther: employee.others.filter(other => !others.map(o => o.path).includes(other.path)).map(other => other.path.split('/')[other.path.split('/').length - 1]) } : {},
        //! others && employee?.others?.length ? { deleteOther: 1 } : {},

        // Others licenses multiple
        otherLicenses && otherLicenses.filter(other => other.blob).length ? { other_licenses: otherLicenses.filter(otherLicense => otherLicense.blob) } : {},
        otherLicensesToDelete.length ? { deleteOtherLicenses: otherLicensesToDelete } : {},
        //! otherLicenses && employee?.otherLicenses?.length ? { deleteOtherLicenses: 1 } : {},

        idCardExpiredDate ? { id_card_expired_date: moment(idCardExpiredDate).format('YYYY-MM-DD') } : {},
        constructionCardExpiredDate ? { construction_card_expired_date: moment(constructionCardExpiredDate).format('YYYY-MM-DD') } : {},
        gender ? { gender: gender.value } : {},
        matricule ? { registration_number: matricule } : {},
        hourlyRate ? { hourly_rate: hourlyRate } : {},
        coefficient ? { coefficient: coefficient } : {},
        transportAmount ? { transport_amount: transportAmount } : {},
        observation ? { observation: observation } : {},
        birthDate ? { birth_date: moment(birthDate).format('YYYY-MM-DD') } : {},
        company ? { company_id: company.value } : {},
        nationality ? { nationality_id: nationality.value } : {},
        contractType ? { contract_type: contractType.value } : {},
        position ? { employee_position_id: position.value } : {},
        contractEndDate && contractType?.value !== 'CDI' ? { contract_end_date: moment(contractEndDate).format('YYYY-MM-DD') } : {},
        licenses?.length ? { licenses: licenses.map(license => license.value) } : {}
      )
    })
      .then(res => {
        if (!res) return
        toast({
          position: 'bottom-right',
          description: res?.data?.message || employee ? 'Employé modifié avec succès' : 'Employé ajouté avec succès',
          status: 'success',
          duration: 5000,
          isClosable: false
        })
        reloadEmployees()
        callback(res)
        onClose()
      })
      .finally(() => setSubmitting(false))
  }

  return <Modal
    blockScrollOnMount={true}
    scrollBehavior="inside"
    size="2xl"
    isOpen={open}
    onClose={onClose}
  >
    <ModalOverlay />
    <ModalContent
      w={{
        base: '95%',
        md: '50%',
        lg: '35%'
      }}
    >
      <ModalHeader>
        <Text fontSize={24}>
          {employee ? viewOnly ? `Détail de ${employee.fullName}` : `Modifier ${employee.fullName}` : 'Ajouter un employé'}
        </Text>
        <ModalCloseButton />
      </ModalHeader>
      {!loading
        ? <ModalBody>
        {!viewOnly && <Text fontSize={14} mb='1.5rem'>
          {employee ? 'Pour mettre à jour cet employé, renseigner les informations à modifier ci-dessous :' : 'Pour ajouter un nouvel employé, entrez les informations de contact ci-dessous :'}
        </Text>}
        {employee && !viewOnly && !employee.isEmployeeValidated &&
          <Button data-variant='solid' onClick={submitValidate} width="100%" margin="30px 0">{"Valider l'employé"}</Button>
        }
        <PictureInput disabled={viewOnly} picture={picture} setValue={(imageList) => setPicture(imageList[0])} onRemove={() => setPicture(undefined)} />
        <Stack spacing={5} my='5'>
          <Input name='Matricule' value={matricule} setValue={setMatricule} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name='Prénom *' value={firstName} setValue={setFirstName} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <Input name='Nom *' value={lastName} setValue={setLastName} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <PhoneInput value={phone} setValue={setPhone} disabled={viewOnly} />
          <Input name='Email' value={email} setValue={setEmail} type='email' disabled={viewOnly} />
          <AddressInput name='Adresse' defaultValue={employee?.address} setValues={setAddressValues} isDisabled={viewOnly} />
          <CheckboxGroup>
            <Checkbox
              padding='5px 10px'
              _hover={{ color: '#fe5464' }}
              isChecked={zoneByPersonalAddress}
              onChange={() => setZoneByPersonalAddress(!zoneByPersonalAddress)}
              disabled={viewOnly}
            >
              <Text>{"Zone par rapport à l'adresse personnelle du salarié"}</Text>
            </Checkbox>
          </CheckboxGroup>
          <Select name='Poste' value={position} setValue={setPosition} options={positionOptions} isDisabled={viewOnly} />
          <Select name='Genre' value={gender} setValue={setGender} options={genderOptions} isDisabled={viewOnly} />
          <Select name='Nationalité' value={nationality} setValue={setNationality} options={nationalityOptions} isDisabled={viewOnly} />
          <DateTimeInput name='Date de naissance' value={birthDate} setValue={setBirthDate} disabled={viewOnly} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Select name='Type de contrat' value={contractType} setValue={setContractType} options={contractOptions} isDisabled={viewOnly} />
            </GridItem>
            {contractType?.value !== 'CDI'
              ? <GridItem w='100%'>
                <DateTimeInput name='Date de fin' value={contractEndDate} setValue={setContractEndDate} disabled={viewOnly} />
              </GridItem>
              : ''
            }
          </Grid>
          <Select name='Société' value={company} setValue={setCompany} options={companyOptions} />
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name="Carte d'identité" value={idCardFile} setValue={setIdCardFile} type="file" defaultFiles={idCardFileDefault} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Date expiration' value={idCardExpiredDate} setValue={setIdCardExpiredDate} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <Grid templateColumns='repeat(2, 1fr)' gap={6}>
            <GridItem w='100%'>
              <Input name="Carte BTP" value={constructionCardFile} setValue={setConstructionCardFile} type="file" defaultFiles={constructionCardFileDefault} disabled={viewOnly} />
            </GridItem>
            <GridItem w='100%'>
              <DateTimeInput name='Date expiration' value={constructionCardExpiredDate} setValue={setConstructionCardExpiredDate} disabled={viewOnly} />
            </GridItem>
          </Grid>
          <Input name='Taux horaire' value={hourlyRate} setValue={setHourlyRate} type='number' disabled={viewOnly} />
          <Input name='Montant transport' value={transportAmount} setValue={setTransportAmount} type='number' disabled={viewOnly} />
          <Input name='Coefficient agence' value={coefficient} setValue={setCoefficient} type='number' disabled={viewOnly} />
          <Input name="Contrat de mise à disposition (interim)" value={contract} setValue={setContract} type="file" defaultFiles={contractDefault} disabled={viewOnly} />
          <Input name="Ajouter d'autres documents" value={others} setValue={setOthers} type="file" multiple fileListName='Autres documents' defaultFiles={othersDefault} disabled={viewOnly} />
          <Select name='Permis' value={licenses} setValue={setLicenses} options={licensesOptions} isMulti isDisabled={viewOnly} />
          <Input name="Ajouter un autre permis" value={otherLicenses} setValue={setOtherLicenses} type="file" multiple fileListName='Autres permis' defaultFiles={otherLicensesDefault} disabled={viewOnly} />
          <Textarea name='Note' value={observation} setValue={setObservation} type="textarea" disabled={viewOnly} />
        </Stack>
      </ModalBody>
        : <ModalBody>
        Loading
      </ModalBody>}
      <ModalFooter zIndex={12} justifyContent="center" borderTopWidth={'1px'}>
        <Button isLoading={submitting} data-variant='solid' onClick={employee && viewOnly ? onClose : submit }>
          {employee ? viewOnly ? 'Fermer' : 'Modifier' : 'Ajouter cet employé'}
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

export default memo(AddEmployee)
