import { useState, useLayoutEffect, useMemo, useCallback } from 'react'

import { HStack, Box } from '@chakra-ui/react'

import { Draggable } from '@fullcalendar/interaction'

import Content from './Content'
import Sidebar from './Sidebar'

import PlanOuvrier from '../components/modals/PlanOuvrier'
import ProgrammedHours from '../components/programmedHours'

import { useFilters } from '../../contexts/filters'
import { useAuth, useDatas } from 'contexts'

const Chantiers = ({ openEmployeeModal, openConstructionSiteModal }) => {
  const {
    filters: {
      planningType,
      fullSize,
      startDate,
      endDate,
      selectedCompanies,
      search
    },
    setters: {
      setLoading
    },
    datas: {
      companies,
      employees: employeesDatas
    }
  } = useFilters()
  const { datas: { defaultDays } } = useDatas()
  const { callApi } = useAuth()

  useLayoutEffect(() => {
    const createDraggable = (idSelector) => {
      const draggableEl = document.getElementById(idSelector)
      // eslint-disable-next-line no-new
      new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData: function (eventEl) {
          const id = eventEl.getAttribute('data-id')
          const label = eventEl.getAttribute('data-label')
          const color = '#eee'// eventEl.getAttribute('data-color')
          const extendedProps = JSON.parse(eventEl.getAttribute('data-extendedprops'))

          return {
            id,
            title: label,
            color,
            duration: { days: defaultDays },
            extendedProps
          }
        }
      })
    }

    createDraggable('fullCalendarExternalItems3')
    createDraggable('fullCalendarExternalItems4')
  }, [])

  const generateEvent = (timesheetSchedule, employee) => {
    return {
      start: timesheetSchedule?.startFull,
      end: timesheetSchedule?.endFull,
      resourceId: timesheetSchedule.constructionSite?.id,
      id: timesheetSchedule?.id,
      title: employee.fullName,
      extendedProps: {
        employee,
        company: companies.filter(c => c.employees.map(e => e.id).includes(employee.id))[0],
        employeeId: employee?.id,
        employeeIsValidated: employee?.isEmployeeValidated,
        employeeFullName: employee?.fullName,
        employeePosition: (employeesDatas.filter(e => e.id === employee.id)[0] ?? null)?.employeePosition,
        picture: employee?.picture,
        constructionSite: timesheetSchedule?.constructionSite,
        team: timesheetSchedule.team
      },
      resourceEditable: false,
      backgroundColor: timesheetSchedule?.constructionSite?.color ?? '#ff5464',
      borderColor: 'transparent',
      classNames: ['timesheet'],
      editable: true
    }
  }

  const [events, setEvents] = useState([])
  const getEvents = () => {
    if (!companies.length) return
    setLoading(true)
    callApi({
      method: 'get',
      url: `admin/timesheet/companies/schedules?search=&start_date=${startDate}&end_date=${endDate}&filter=${companies?.length ? companies.map(e => `&companies[]=${e.id}`).join('') : ''}`
    })
      .then(res => {
        if (!res) return
        const employees = res?.data?.data ?? []

        const newChantiersClockings = []
        employees.forEach(employee => {
          employee?.timesheetSchedules?.length &&
          newChantiersClockings.unshift(...employee.timesheetSchedules.map(timesheetSchedule => {
            return generateEvent(timesheetSchedule, employee)
          }).reverse())
        })
        setEvents(newChantiersClockings)
      })
      .finally(() => setLoading(false))
  }

  useLayoutEffect(() => {
    if (planningType === 'chantier') { getEvents() }
  }, [companies, /* endDate, */ planningType])

  const filteredEvents = useMemo(() => {
    const iLow = search.toLowerCase()
    return events
      .filter(event => {
        const searchName = `${event?.extendedProps?.employee?.firstName} ${event?.extendedProps?.employee?.lastName} ${event?.extendedProps?.employee?.firstName}`.toLowerCase()
        return (!selectedCompanies.length || selectedCompanies.map(c => c.value).includes(event.extendedProps.company?.id)) &&
          (!iLow.length || searchName.includes(iLow))
      })
  }, [events, selectedCompanies, search])

  const deleteEvent = (res, deletedId, newEndDate) => {
    getEvents()
    // const newEvents = newEndDate
    //   ? events.map(prev => deletedId !== `${prev.id}` ? prev : { ...prev, end: new Date(newEndDate.split('T')[0]) })
    //   : events.filter(prev => deletedId !== `${prev.id}`)
    // setEvents(newEvents)
  }

  const addEvent = (res) => {
    const newEvents = [...events]
    res?.data?.data.forEach(timesheet => newEvents.push(generateEvent(timesheet, timesheet?.employee)))
    // const newEvents = [...events, generateEvent(timesheet, timesheet?.employee)]
    setEvents(newEvents)
  }

  const updateEvent = (res, updatedEvent) => {
    if (!updatedEvent) {
      addEvent(res)
      return
    }
    let newEvents = events.filter(prev => updatedEvent !== `${prev.id}`)
    newEvents = [...newEvents, generateEvent(res?.data?.data, res?.data?.data?.employee)]
    setEvents(newEvents)
  }

  const [planOuvrierOpen, setPlanOuvrierOpen] = useState(false)
  const [planOuvrierValues, setPlanOuvrierValues] = useState()
  const openTimesheetModal = useCallback((data) => {
    setPlanOuvrierValues(data)
    setPlanOuvrierOpen(true)
  }, [setPlanOuvrierValues, setPlanOuvrierOpen])
  const closeTimesheetModal = useCallback(() => {
    setPlanOuvrierValues(null)
    setPlanOuvrierOpen(false)
  }, [setPlanOuvrierValues, setPlanOuvrierOpen])

  return <HStack height='100%' className='planningEmployeeChantier' align='flex-start'>
    <PlanOuvrier open={planOuvrierOpen} onClose={closeTimesheetModal} defaultValues={planOuvrierValues} callback={updateEvent} />
    <Box width='80%' height={fullSize ? '100%' : '830px'}>
      <Content events={events} filteredEvents={filteredEvents} openTimesheetModal={openTimesheetModal} openConstructionSiteModal={openConstructionSiteModal} deleteEvent={deleteEvent} updateEvent={updateEvent} addEvent={addEvent} />
      <ProgrammedHours datas={filteredEvents} />
    </Box>
    <Box width='20%' height={fullSize ? 'calc(100% + 30px)' : '830px'} style={{ marginInlineStart: 0 }} pl='6px' overflow='hidden'>
      <Box height='100%' boxShadow='-6px 80px 7px 0px #e2e8f0'>
        <Sidebar openEmployeeModal={openEmployeeModal} addButton={() => openTimesheetModal(null)} />
      </Box>
    </Box>
  </HStack>
}

export default Chantiers
